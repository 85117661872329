import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import { fetchDeviceComponents, fetchDeviceInfo, fetchDeviceListByOrgId, fetchDevicesAtHierarchy, fetchDevicesHealthByOrgId, fetchDeviceStates, fetchDeviceTypeList, updateDeviceInfo, addDevice, deleteDevice, fetchDeviceFilterByOrgId, fetchPartNameList, fetchDeviceConfigViews, fetchConfigNodes, fetchNodeContent, getConfigState, lockDeviceState, unLockDeviceState, getConfiguration, getConfigDevices, storeConfiguration, getDevicesStatus, fetchDeviceAssets } from "../../actions/device/deviceAction";
import { DeviceState } from "../../states/device/deviceState";
import { RootState } from "../../store/rootReducer";

const initialState: DeviceState = {
  deviceList:[],
  deviceFormState : resetState(),
  formState: resetState(),
  deviceCount: 0,
  devicesHealth: null,
  devicesHealthFormstate: resetState(),
  deviceTypeList: [],
  deviceStates: [],
  deviceComponents: null,
  deviceComponentsFormstate: resetState(),
  deviceFilter: {},
  deviceHealthParams: {},
  deviceComponentParams: {},
  deviceInfo: {},
  deviceInfoFormstate: resetState(),
  updateDeviceFormstate: resetState(),
  addDeviceFormstate: resetState(),
  deleteDeviceFormstate: resetState(),
  cancelToken:null,
  cancel:false,
  totalDevice:0,
  deviceListFilter:[],
  deviceListFilterFormState: resetState(),
  devicesHealthCount: 0,
  addDeviceErrorState: {},
  partNameList: [],
  partNameFormState: resetState(),
  deviceConfigViews : [],
  deviceConfigViewsFormState : resetState(),
  deviceConfigNodes : [],
  deviceConfigNodesFormState : resetState(),
  deviceConfigNodeContent: [],
  deviceConfigNodeContentFormState : resetState(),
  configState: {},
  previousConfigState: {},
  isLocked: false,
  lockDeviceFormState : resetState(),
  changedItems : {},
  copiedItems : {},
  nodeStack : [],
  storedSettings : {},
  getConfigDevicesFormState : resetState(),
  configDevices: [],
  storeConfigurationFormState : resetState(),
  invalidFields : [],
  copiedGroups : {},
  changedGroups: {},
  removedGroups: [],
  newlyAddedGroups: [],
  newlyAddedGroupIds: {},
  reorderedGroups: {},
  deviceStatusMap : {},
  devicesStatusFormState : resetState(),
  deviceAssets : {},
  deviceAssetsFormState : resetState(),
  newDeviceAssets: {},
  existingDeviceAssets: {},
  restartDevice: false
};

export const DeviceSlice = createSlice({
  name: "device",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearDeviceList: (state) => {
      state.deviceList = [];
      state.totalDevice = 0;
      state.deviceListFilter = [];
      state.deviceListFilterFormState = resetState();
    },
    clearDeviceCount: (state) => {
      state.deviceCount = 0;
    },
    clearDevicesHealth: (state) => {
      state.devicesHealth = null
      state.devicesHealthCount = 0
    },
    clearDevicesHealthFormState:(state) => { 
      state.devicesHealthFormstate = resetState()
    },
    clearDeviceTypeList: (state) => {
      state.deviceTypeList = []
      state.deviceStates = []
    },
    clearDeviceComponents: (state) => {
      state.deviceComponents = null
      state.deviceComponentsFormstate = resetState()
    },
    clearDeviceInfo: (state) => {
      state.deviceInfoFormstate = resetState()
      state.deviceInfo = {}
    },
    clearDeviceFormStates: (state) => {
      state.addDeviceFormstate = resetState()
      state.deleteDeviceFormstate = resetState()
      state.updateDeviceFormstate = resetState()
    },
    setDeviceFilter: (state,{ payload}) => {
      state.deviceFilter = payload;
      return state;
    },
    setDeviceHealthParams: (state, {payload}) => {
      state.deviceHealthParams = payload
    },
    setDeviceComponentParams: (state, {payload}) => {
      state.deviceComponentParams = payload
    },
    setCancelToken: (state,{payload}) => {
      state.cancelToken = payload.cancelToken;
      state.cancel = payload.cancel
    },
    clearAddDeviceErrorState : (state) => {
      state.addDeviceErrorState = {}
    },
    clearPartNameList : (state) => {
      state.partNameList = [];
      state.partNameFormState = resetState()
    },
    clearDeviceConfigStates : (state) => {
      state.deviceConfigViews = [];
      state.deviceConfigViewsFormState = resetState();
      state.deviceConfigNodes = [];
      state.deviceConfigNodesFormState = resetState();
      state.deviceConfigNodeContent= [];
      state.deviceConfigNodeContentFormState = resetState();
      state.configState = {};
      state.lockDeviceFormState = resetState();
      state.changedItems = {};
      state.copiedItems = {};
      state.nodeStack = [];
      state.storedSettings = {};
      state.getConfigDevicesFormState = resetState();
      state.configDevices= [];
      state.invalidFields= [];
      state.copiedGroups = {};
      state.changedGroups = {};
      state.removedGroups = [];
      state.newlyAddedGroups = [];
      state.newlyAddedGroupIds = {};
      state.reorderedGroups = {};
      state.deviceStatusMap = {};
      state.devicesStatusFormState = resetState();
      state.deviceAssets = {};
      state.deviceAssetsFormState = resetState();
      state.newDeviceAssets = {};
      state.existingDeviceAssets = {};
      return state;
    },
    clearNodeContent : (state) => {
      state.deviceConfigNodeContent= [];
      state.deviceConfigNodeContentFormState = resetState();
      return state;
    },
    setChangedItems: (state, { payload }) => {
      state.changedItems = {
        ...state.changedItems,
        ...payload
      };
    },
    removeChangedItems: (state, {payload}) => {
      if (state?.changedItems?.hasOwnProperty(payload)) {
        delete state.changedItems[payload];
      }
    },
    setCopiedItems: (state, { payload }) => {
      state.copiedItems = {
        ...state.copiedItems,
        ...payload
      };
    },
    removeCopiedItems: (state, {payload}) => {
      if (state?.copiedItems?.hasOwnProperty(payload)) {
        delete state.copiedItems[payload];
      }
    },
    clearChangedItems : (state) => {
      state.changedItems= {};
    },
    clearCopiedItems : (state) => {
      state.copiedItems = {};
    },
    setNodeStack: (state, {payload}) => {      
      const {action, data} = payload;
      switch (action) {
        case "push":
          state.nodeStack.push(data);
          break;
        case "pop":
          state.nodeStack.pop();
          break;
        case "initialize":
          state.nodeStack = [data];
          break;
        case "empty":
          state.nodeStack = [];
          break;
        default:
          state.nodeStack = data
          break;
      }
    },
    setStoredSettings: (state, {payload}) => {
      state.storedSettings = payload;
    },
    addToInvalidFields: (state, { payload }) => {
      if (state.invalidFields && Array.isArray(state.invalidFields)) {
        state.invalidFields.push(payload);
      } else {
        state.invalidFields = [payload];
      }
    },
    removeFromInvalidFields: (state, { payload }) => {
      if (state.invalidFields && Array.isArray(state.invalidFields)) {
        state.invalidFields = state.invalidFields.filter(field => field !== payload);
      }
    },
    clearInvalidFields : (state) => {
      state.invalidFields = [];
    },
    setCopiedGroups: (state, { payload }) => {
      state.copiedGroups = {
        ...state.copiedGroups,
        ...payload
      };
    },
    removeCopiedGroups: (state, {payload}) => {
      if (state?.copiedGroups?.hasOwnProperty(payload)) {
        delete state.copiedGroups[payload];
      }
    },
    clearCopiedGroups : (state) => {
      state.copiedGroups = {};
    },
    setChangedGroups: (state, { payload }) => {
      state.changedGroups = {
        ...state.changedGroups,
        ...payload
      };
    },
    removeChangedGroups: (state, {payload}) => {
      if (state?.changedGroups?.hasOwnProperty(payload)) {
        delete state.changedGroups[payload];
      }
    },
    clearChangedGroups : (state) => {
      state.changedGroups = {};
    },
    setRemovedGroups: (state, { payload }) => {
      state.removedGroups = payload;
    },
    setIsLocked: (state, { payload }) => {
      state.isLocked = payload
    },
    setNewlyAddedGroup: (state, { payload }) => {
      state.newlyAddedGroups = payload
    },
    clearNewlyAddedGroup : (state) => {
      state.newlyAddedGroups = [];
    },
    setNewlyAddedGroupIds: (state, { payload }) => {
      state.newlyAddedGroupIds = { ...state.newlyAddedGroupIds, [payload]: payload };
    },
    removeNewlyAddedGroupIds: (state, { payload }) => {
      if (state?.newlyAddedGroupIds?.hasOwnProperty(payload)) {
        delete state.newlyAddedGroupIds[payload];
      }
    },
    clearNewlyAddedGroupIds : (state) => {
      state.newlyAddedGroupIds = {};
    },
    setReorderedGroups: (state, {payload}) => {
      state.reorderedGroups = {
        ...state.reorderedGroups,
        ...payload
      };
    },
    removeReorderedGroups: (state, {payload}) => {
      if (state?.reorderedGroups?.hasOwnProperty(payload)) {
        delete state.reorderedGroups[payload];
      }
    },
    clearReorderedGroups : (state) => {
      state.reorderedGroups = {};
    },
    clearDevicesStatus : (state) => {
      state.deviceStatusMap = {};
    },
    clearStoreConfigFormState : (state) => {
      state.storeConfigurationFormState = resetState();
    },
    setNewDeviceAssets: (state, { payload }) => {
      state.newDeviceAssets = {
        ...state.newDeviceAssets,
        ...payload
      };
    },
    removeNewDeviceAssets: (state, { payload }) => {
      if (state?.newDeviceAssets?.hasOwnProperty(payload)) {
        delete state.newDeviceAssets[payload];
      }
    },
    clearNewDeviceAssets : (state) => {
      state.newDeviceAssets = {};
    },
    setExistingDeviceAssets: (state, { payload }) => {
      state.existingDeviceAssets = {
        ...state.existingDeviceAssets,
        ...payload
      };
    },
    removeExistingDeviceAssets: (state, { payload }) => {
      if (state?.existingDeviceAssets?.hasOwnProperty(payload)) {
        delete state.existingDeviceAssets[payload];
      }
    },
    clearExistingDeviceAssets: (state) => {
      state.existingDeviceAssets = {};
    },
    setRestartDevice: (state, { payload }) => {
      state.restartDevice = payload
    },
  },
  extraReducers: {
    // Action - fetchDeviceByOrgId
    [fetchDeviceListByOrgId.fulfilled.toString()]: (state, { payload }) => {
      state.totalDevice = payload.count;
      state.deviceList = payload.rows;
      state.deviceFormState = fulfilledState();
      return state;
    },
    [fetchDeviceListByOrgId.rejected.toString()]: (state, { payload }) => {
      state.deviceFormState = rejectedState(payload?.message);
    },
    [fetchDeviceListByOrgId.pending.toString()]: (state) => {
      state.deviceFormState = pendingState();
    },
    [fetchDeviceFilterByOrgId.fulfilled.toString()]: (state, { payload }) => {
      state.deviceListFilter = payload;
      state.deviceListFilterFormState = fulfilledState();
      return state;
    },
    [fetchDeviceFilterByOrgId.rejected.toString()]: (state, { payload }) => {
      state.deviceListFilterFormState = rejectedState(payload?.message);
    },
    [fetchDeviceFilterByOrgId.pending.toString()]: (state) => {
      state.deviceListFilterFormState = pendingState();
    },
    [fetchDevicesAtHierarchy.fulfilled.toString()]: (state, { payload }) => {
      state.deviceCount = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchDevicesAtHierarchy.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
      return state;
    },
    [fetchDevicesAtHierarchy.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchDevicesHealthByOrgId.fulfilled.toString()]: (state, { payload }) => {
      state.devicesHealth = payload.rows;
      state.devicesHealthCount = payload.count
      state.devicesHealthFormstate = fulfilledState();
      return state;
    },
    [fetchDevicesHealthByOrgId.rejected.toString()]: (state, { payload }) => {
      state.devicesHealthFormstate = rejectedState(payload?.message);
      return state;
    },
    [fetchDevicesHealthByOrgId.pending.toString()]: (state) => {
      state.devicesHealthFormstate = pendingState();
      return state;
    },
    [fetchDeviceTypeList.fulfilled.toString()]: (state, { payload }) => {
      state.deviceTypeList = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchDeviceTypeList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
      return state;
    },
    [fetchDeviceTypeList.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchDeviceStates.fulfilled.toString()]: (state, { payload }) => {
      state.deviceStates = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchDeviceStates.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
      return state;
    },
    [fetchDeviceStates.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchDeviceComponents.fulfilled.toString()]: (state, { payload }) => {
      state.deviceComponents = payload;
      state.deviceComponentsFormstate = fulfilledState();
      return state;
    },
    [fetchDeviceComponents.rejected.toString()]: (state, { payload }) => {
      state.deviceComponentsFormstate = rejectedState(payload?.message);
      return state;
    },
    [fetchDeviceComponents.pending.toString()]: (state) => {
      state.deviceComponentsFormstate = pendingState();
      return state;
    },
    [fetchDeviceInfo.fulfilled.toString()]: (state, { payload }) => {
      state.deviceInfo = payload;
      state.deviceInfoFormstate = fulfilledState();
      return state;
    },
    [fetchDeviceInfo.rejected.toString()]: (state, { payload }) => {
      state.deviceInfoFormstate = rejectedState(payload?.message);
      return state;
    },
    [fetchDeviceInfo.pending.toString()]: (state) => {
      state.deviceInfoFormstate = pendingState();
      return state;
    },
    [updateDeviceInfo.fulfilled.toString()]: (state, { payload }) => {
      state.updateDeviceFormstate = fulfilledState();
      return state;
    },
    [updateDeviceInfo.rejected.toString()]: (state, { payload }) => {
      state.updateDeviceFormstate = rejectedState(payload?.message);
      return state;
    },
    [updateDeviceInfo.pending.toString()]: (state) => {
      state.updateDeviceFormstate = pendingState();
      return state;
    },
    [addDevice.fulfilled.toString()]: (state, { payload }) => {
      state.addDeviceErrorState = payload?.error
      state.addDeviceFormstate = fulfilledState();
      return state;
    },
    [addDevice.rejected.toString()]: (state, { payload }) => {
      state.addDeviceFormstate = rejectedState(payload?.message);
      return state;
    },
    [addDevice.pending.toString()]: (state) => {
      state.addDeviceFormstate = pendingState();
      return state;
    },
    [deleteDevice.fulfilled.toString()]: (state, { payload }) => {
      state.deleteDeviceFormstate = fulfilledState();
      return state;
    },
    [deleteDevice.rejected.toString()]: (state, { payload }) => {
      state.deleteDeviceFormstate = rejectedState(payload?.message);
      return state;
    },
    [deleteDevice.pending.toString()]: (state) => {
      state.deleteDeviceFormstate = pendingState();
      return state;
    },
    [fetchPartNameList.fulfilled.toString()]: (state, { payload }) => {
      state.partNameList = payload;
      return state;
    },
    [fetchPartNameList.rejected.toString()]: (state, { payload }) => {
      state.partNameFormState = rejectedState(payload?.message);
    },
    [fetchPartNameList.pending.toString()]: (state) => {
      state.partNameFormState = pendingState();
    },
    [fetchDeviceConfigViews.fulfilled.toString()]: (state, { payload }) => {
      state.deviceConfigViews = payload;
      state.deviceConfigViewsFormState =fulfilledState()
      return state;
    },
    [fetchDeviceConfigViews.rejected.toString()]: (state, { payload }) => {
      state.deviceConfigViewsFormState = rejectedState(payload?.message);
    },
    [fetchDeviceConfigViews.pending.toString()]: (state) => {
      state.deviceConfigViewsFormState = pendingState();
    },
    [fetchConfigNodes.fulfilled.toString()]: (state, { payload }) => {
      state.deviceConfigNodes = payload;
      state.deviceConfigNodesFormState =fulfilledState()
      return state;
    },
    [fetchConfigNodes.rejected.toString()]: (state, { payload }) => {
      state.deviceConfigNodesFormState = rejectedState(payload?.message);
    },
    [fetchConfigNodes.pending.toString()]: (state) => {
      state.deviceConfigNodesFormState = pendingState();
    },
    [fetchNodeContent.fulfilled.toString()]: (state, { payload }) => {
      state.deviceConfigNodeContent = payload;
      state.deviceConfigNodeContentFormState =fulfilledState()
      return state;
    },
    [fetchNodeContent.rejected.toString()]: (state, { payload }) => {
      state.deviceConfigNodeContentFormState = rejectedState(payload?.message);
    },
    [fetchNodeContent.pending.toString()]: (state) => {
      state.deviceConfigNodeContentFormState = pendingState();
    },
    [getConfigState.fulfilled.toString()]: (state, { payload }) => {
      const {response, previousResponse} = payload;
      state.configState = response;
      state.previousConfigState = previousResponse;
      state.isLocked = response?.LockState?.Locked || response?.ProcessingWorkItem || response?.CanProcessConfiguration === false;
      return state;
    },
    [getDevicesStatus.pending.toString()]: (state) => {
      state.devicesStatusFormState = pendingState();
    },
    [getDevicesStatus.fulfilled.toString()]: (state, { payload }) => {
      state.devicesStatusFormState = fulfilledState()
      state.deviceStatusMap = payload;
      return state;
    },
    [lockDeviceState.rejected.toString()]: (state, { payload }) => {
      state.devicesStatusFormState = rejectedState(payload?.message);
    },
    [lockDeviceState.fulfilled.toString()]: (state) => {
      state.lockDeviceFormState =fulfilledState()
      return state;
    },
    [lockDeviceState.rejected.toString()]: (state, { payload }) => {
      state.lockDeviceFormState = rejectedState(payload?.message);
    },
    [lockDeviceState.pending.toString()]: (state) => {
      state.lockDeviceFormState = pendingState();
    },
    [unLockDeviceState.fulfilled.toString()]: (state) => {
      state.lockDeviceFormState =fulfilledState()
      return state;
    },
    [unLockDeviceState.rejected.toString()]: (state, { payload }) => {
      state.lockDeviceFormState = rejectedState(payload?.message);
    },
    [unLockDeviceState.pending.toString()]: (state) => {
      state.lockDeviceFormState = pendingState();
    },
    [getConfigDevices.fulfilled.toString()]: (state, { payload }) => {
      state.configDevices = payload.Devices;
      state.getConfigDevicesFormState =fulfilledState()
      return state;
    },
    [getConfigDevices.rejected.toString()]: (state, { payload }) => {
      state.getConfigDevicesFormState = rejectedState(payload?.message);
    },
    [getConfigDevices.pending.toString()]: (state) => {
      state.getConfigDevicesFormState = pendingState();
    },
    [storeConfiguration.fulfilled.toString()]: (state) => {
      state.storeConfigurationFormState =fulfilledState()
      return state;
    },
    [storeConfiguration.rejected.toString()]: (state, { payload }) => {
      state.storeConfigurationFormState = rejectedState(payload?.message);
    },
    [storeConfiguration.pending.toString()]: (state) => {
      state.storeConfigurationFormState = pendingState();
    },
    [fetchDeviceAssets.fulfilled.toString()]: (state, {payload}) => {
      state.deviceAssets = payload;
      state.deviceAssetsFormState = fulfilledState();
      return state;
    },
    [fetchDeviceAssets.rejected.toString()]: (state, { payload }) => {
      state.deviceAssetsFormState = rejectedState(payload?.message);
    },
    [fetchDeviceAssets.pending.toString()]: (state) => {
      state.deviceAssetsFormState = pendingState();
    },
  },
});

export const {
  clearState,
  clearDeviceList,
  clearDeviceCount,
  clearDevicesHealth,
  clearDevicesHealthFormState,
  clearDeviceTypeList,
  clearDeviceComponents,
  setDeviceFilter,
  setDeviceHealthParams,
  setDeviceComponentParams,
  clearDeviceInfo,
  clearDeviceFormStates,
  setCancelToken,
  clearAddDeviceErrorState,
  clearPartNameList,
  clearDeviceConfigStates,
  clearNodeContent,
  setChangedItems,
  removeChangedItems,
  clearChangedItems,
  setNodeStack,
  setStoredSettings,
  setCopiedItems,
  removeCopiedItems,
  clearCopiedItems,
  addToInvalidFields,
  removeFromInvalidFields,
  clearInvalidFields,
  setCopiedGroups,
  removeCopiedGroups,
  clearCopiedGroups,
  setChangedGroups,
  setRemovedGroups,
  setIsLocked,
  removeChangedGroups,
  clearChangedGroups,
  setNewlyAddedGroup,
  clearNewlyAddedGroup,
  setReorderedGroups,
  removeReorderedGroups,
  clearReorderedGroups,
  clearDevicesStatus,
  clearStoreConfigFormState,
  setNewlyAddedGroupIds,
  removeNewlyAddedGroupIds,
  clearNewlyAddedGroupIds,
  setNewDeviceAssets,
  removeNewDeviceAssets,
  clearNewDeviceAssets,
  setExistingDeviceAssets,
  removeExistingDeviceAssets,
  clearExistingDeviceAssets,
  setRestartDevice
} = DeviceSlice.actions;
export const DeviceSelector = (state: RootState) => state.device;
export default DeviceSlice.reducer;
