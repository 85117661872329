import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { EventLogsUrl } from "../../constants/apiConstants";
import qs from 'qs'
import { EventInfo } from "../../models/eventLogs/eventLog";
import { convert_DateWithTimezone_To_UTC, getOrganizationDateAndTime, getClientTimeZoneOffset } from "../../../utility/appUtil";
import secureLocalStorage from "react-secure-storage";

export default class EventLogService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.EVENT));
  }

  async fetchEventLogList(parameters: any | null): Promise<any> {

    let params: any = this.getEventLogParams(parameters)
    let endPoint = `${EventLogsUrl.EVENTLOGS}`

    const response = await this.get(endPoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    if (response && response.data) {
      const data = response.data;
      if (
        data.result &&
        data.result.message
      ) {
        return data.result.message
      }
    }
    return { totalPages: 0, page: 0, rows: [], count: 0 };
  }

  async fetchEventLogById(id: any | null): Promise<any> {
    let endPoint = `${EventLogsUrl.EVENTLOGS}`
    const response = await this.get(`${endPoint}/${id}`);
    if (response && response.data) {
      const data = response.data;
      if (
        data.result &&
        data.result.message
      ) {
        return this.getEventLog(data.result.message);
      }
    }
    return null;
  }

  async fetchEventsInfo(parameters: any | null): Promise<any> {
    let endPoint = `${EventLogsUrl.EVENTID}`
    let params : any = {}
    if(parameters?.organizationId !== undefined && parameters?.organizationId !== null){
      params.organizationId = parameters?.organizationId
    }
    if(parameters?.isEvent !== undefined ){
      params.isEvent = parameters?.isEvent
    }
    const response = await this.get(endPoint,{
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    if (response && response.data) {
      let result = this.getEventsInfo(response.data.result)
      return result
    }
    else
      return null
  }

  async fetchEventsInfoByOrgId(parameters: any | null): Promise<any> {
    let endPoint = `${EventLogsUrl.EVENTID}`
    let params : any = {}
    if(parameters?.organizationId !== null && parameters?.organizationId !== undefined){
      params.organizationId = parameters?.organizationId
    }
    if(parameters?.isDefault !== null && parameters?.isDefault !== undefined){
      params.isDefault = parameters?.isDefault
    }
    const response = await this.get(endPoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    if (response && response.data) {
      let result = this.getEventsInfo(response.data.result)
      return result
    }
    else
      return null
  }

  async exportEventLogList(parameters: any | null): Promise<any> {
    let params: any = this.getEventLogParams(parameters, true)
    let endPoint = `${EventLogsUrl.EVENTLOGS}`
    const response = await this.get(endPoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
      responseType: 'blob'
    });
    if (response && response.data) {
      return response.data
    }
    return null;
  }

  private getEventLogParams(parameters: any, exportData?: boolean) {
    let params: any = {}
    let localeInfo:any = secureLocalStorage.getItem('locale')

    if (exportData) {
      params.isExport = true
      params.organisationName = parameters?.orgName
      params.clientTimeZoneOffset = getClientTimeZoneOffset()
    }

    params.startDate = convert_DateWithTimezone_To_UTC(parameters?.startDate ,{time: 'start'})
    params.endDate = convert_DateWithTimezone_To_UTC(parameters?.endDate, {time: 'end'})

    params.limit = parameters?.limit
    params.removeAudit = parameters?.removeAudit
    params.page = parameters?.currentPage
    params.organisationId = parameters?.orgID
    params.locale = localeInfo.culture
    params.timeZone = localeInfo.timezone
    if(parameters?.totalCount){
      params.totalCount = parseInt(parameters?.totalCount)
    }
    if (parameters?.severity) {
      params.severity = parameters?.severity
    }
    if (parameters?.eventName) {
      params.eventName = parameters?.eventName
    }
    if (parameters?.hierarchyIdList) {
      params.locations = parameters?.hierarchyIdList
      if (exportData) {
        params.hierarchyNameList = parameters?.hierarchyNameList
      }
    }
    if (parameters?.deviceIdList) {
      params.deviceIdList = parameters?.deviceIdList
      if (exportData) {
        params.deviceNameList = parameters?.deviceNameList
      }
    }
    if (parameters?.category) {
      params.category = parameters?.category
    }
    if (parameters?.searchParam) {
      params.searchParam = parameters?.searchParam
    }
    if (parameters?.eventIdList) {
      params.eventIdList = parameters?.eventIdList
    }

    if (parameters?.partName) {
      params.partName = parameters?.partName
    }
    if (parameters?.activeAlertTimestamp) {
      params.activeAlertTimestamp = parameters?.activeAlertTimestamp
    }
    if (parameters?.clearingEventTimestamp) {
      params.clearingEventTimestamp = parameters?.clearingEventTimestamp
    }
    if (parameters?.eventOfAlertStatus) {
      params.eventOfAlertStatus = parameters?.eventOfAlertStatus
    }
    if (parameters?.eventOfWorkItem) {
      params.eventOfWorkItem = parameters?.eventOfWorkItem
    }
    if (parameters?.workItemTimestamp) {
      params.workItemTimestamp = parameters?.workItemTimestamp
    }
    if (parameters?.deviceWorkId) {
      params.deviceWorkId = parameters?.deviceWorkId
    }
    if (parameters?.workItemTypeId) {
      params.workItemTypeId = parameters?.workItemTypeId
    }

    return params

  }

  private getEventsInfo(data: any) {
    const result: EventInfo[] = data.map((row: any) => {
      return {
        eventId: row?.eventId,
        event: row?.name,
        severity: row?.severity,
        category: row?.category,
        clearingEvent: row?.clearingEvent
      }
    })
    return result
  }

  private getEventLogList(response: any) {
    const result = {
      totalPages: response?.totalPages,
      page: response?.page,
      rows: [],
      count: response?.count
    }
    const eventLogs = response?.rows.map((data: any) => {
      return this.getEventLog(data)
    })
    result.rows = eventLogs
    return result
  }

  private getEventLog(data: any){
    return {
      id: data?.id,
      severity: data?.severity.toString().toLowerCase(),
      eventTimestamp: getOrganizationDateAndTime(data?.eventTimestamp),
      hierarchyName: data?.hierarchyName,
      eventSrcName: data?.eventSrcName,
      eventId: data?.eventId,
      category: data?.data?.metadata?.category,
      event: data?.data?.metadata?.name,
      data: data?.data ?? ''
    }
  }
}
