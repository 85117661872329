export enum RolesUrl {
    ROLE='/role',
}

export enum AlertUrl {
    ALERT = '/alert',
    CLEAR_ALERT = '/clearAlert',
    ALERT_CONFIG = '/aggregateConfig',
    RESEND_ALERT = '/resendAlert'
}

export enum DeviceUrl {
    DEVICE = 'v1/device',
    DEVICE_HEALTH = 'api/v1/aggregateDeviceHealth',
    DEVICE_TYPE = 'v1/deviceType',
    DEVICE_STATES = '/api/v1/healthStatusType',
    DEVICE_FILTER = '/api/v1/deviceFilter',
    DEVICE_PARTNAMES = '/api/v1/getDeviceParts'
}

export enum InsightsUrl {
    REPORTDETAILS = '/reportDetail',
    REPORT = '/report',
    REPORTDATA = '/reportData',
    MYREPORT = '/myReport',
    SCHEDULE = '/reportSchedular'
}

export enum EventLogsUrl {
    EVENTLOGS = '/event',
    EVENTID = '/eventId'
}

export enum WorkItemsUrl {
    Work = '/workItems',
    ScheduleWorkItems= '/workItems/schedule'
}