/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Dropdown, Form, Menu, Space, Tooltip } from 'antd'
import { getHeight, getOrganisationId, getOrganizationDateAndTime, urlModification } from '../../../../../utility/appUtil'
import { AntdInfinityTable } from '../../../stateless/common/antdTable/antdInfininityTable'
import Loader from '../../../stateless/common/spinner'
import { HeaderLaytout, HeaderDiv, ContentLayout } from '../../../stateless/common/styledComponents/common.styles'
import { Title } from './index.styles'
import './style.less'
import { jsonData, MyWorkItemAction, MyWorkItemDeleteWarnModal, MyWorkItemRenameWarnModal, PickerFormat } from "../../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";
import { DeviceSelectionModal } from "../workHeader/workItemModals";
import { WorkSelector } from "../../../../../redux/reducers/workItems/workReducer";
import { MyWorkSelector } from "../../../../../redux/reducers/workItems/myWorkReducer";
import { createWorkItemLcd, fetchWorkItemDevices, storeWorkItem } from "../../../../../redux/actions/workItems/workAction";
import { fetchDevicesHealthByOrgId, getDevicesStatus } from "../../../../../redux/actions/device/deviceAction";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { DeviceSelector } from "../../../../../redux/reducers/device/deviceReducer";
import { SavedFormValue } from "../../../../models/common/savedFormValue";
import CustomInput from "../../../stateless/common/inputField/customInput";
import WarnModal from "../../../stateless/common/warnModal";
import { commonSelector } from "../../../../../redux/reducers/common/commonReducer";
import { saveDirtyFormValues, saveFormValues } from "../../../../../redux/actions/common/commonAction";
import { AppService } from "../../../../services/ui/appService";
import DeviceService from "../../../../services/device/deviceService";
import { Messages } from "../../../../constants/messages";
import { ScheduleModal } from "./scheduleModal";
import { deleteMyWorkItem, fetchMyWorkItemList, updateMyWorkItem } from "../../../../../redux/actions/workItems/myWorkAction";
import { Breadcrumbs } from "../../../stateless/common/breadcrumbs";
import { AppRoutes } from "../../../../constants/enums";
import { sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { DownCircleOutlined, RightCircleOutlined } from "@ant-design/icons";


export const MyWorkitems = (props: any) => {
    const { orgSupportedWorkItemList, workItemDeviceList, workItemDeviceListFormState, createdWorkItemLcdFromSate, storeWorkItemLinkFromSate } = useSelector(WorkSelector)
    const { myWorkItemList, updateMyWorkItemFormState, deleteMyWorkItemFormState, saveMyWorkItemFormState } = useSelector(MyWorkSelector)
    const { selectedOrganisation } = useSelector(appSelector)
    const { appUser } = useSelector(userStateSelector);
    const { deviceStatusMap, devicesHealth, devicesStatusFormState, devicesHealthFormstate, } = useSelector(DeviceSelector)
    const { savedFormValues, isDirty } = useSelector(commonSelector)
    const orgId = getOrganisationId(appUser, selectedOrganisation);
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [scrollHeight, setScrollHeight] = useState(200);
    const { t } = useTranslation()
    const dispatch = useDispatch()
    let localeInfo: any = secureLocalStorage.getItem('locale')
    moment.tz.setDefault(localeInfo.timezone)
    const [showWarn, setShowWarn] = useState(false);
    const [openDeviceModal, setOpenDeviceModal] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([])
    const [selectedWorkItem, setSelectedWorkItem] = useState([])
    const [form] = Form.useForm()
    const [action, setActon] = useState<string>("");
    const [warnCancelBtn, setWarnCancelBtn] = useState("");
    const [warnConfirmBtn, setWarnConfirmBtn] = useState("");
    const [warnTitle, setWarnTitle] = useState("");
    const [warnContent, setWarnContent] = useState<any>("");
    const [target, setTarget] = useState();
    const [myWorkItemName, setMyWorkItemName] = useState("");
    const [disable, setDisable] = useState<boolean>(false)
    const [workItemDeviceLoading, setWorkItemDeviceLoading] = useState<boolean>(false)
    const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isEditSave, setIsEditSave] = useState<boolean>(false)
    const [isRunNow, setIsRunNow] = useState<boolean>(false)
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [isRename, setIsRename] = useState<boolean>(false)
    const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false)
    const [screenHeight, setScreenHeight] = useState(document.body.clientHeight)
    const [breadCrumbClick, setBredCrumClick] = useState<Boolean>(false)
    const [openRow, setOpenRow] = useState<string | null>(null);

    useEffect(() => {
        const node = document.body
        const resizeObserver = new ResizeObserver((entries) => {
            setScreenHeight(node?.clientHeight)
        });
        resizeObserver.observe(node);
        dispatch(sidebarNavigate(SideBarItems.WORK_ITEMS))
        let parameter = {
          organisationId: orgId,
          userId: appUser?.id
        }
        dispatch(fetchMyWorkItemList(parameter))
    }, [])

    useEffect(() => {
        if (!showLoading) {
            const compContentHeight = getHeight('.ant-layout-content')
            const bottomGap = compContentHeight - (screenHeight * 0.8)
            const tableHeight = getHeight('.ant-table-body') - bottomGap
            const scrollH = parseInt(tableHeight.toFixed(0));
            scrollH && !isNaN(scrollH) && setScrollHeight(scrollH)
        }
    }, [myWorkItemList, showLoading, screenHeight])



    useEffect(() => {
        saveMyWorkItemFormState?.loading || deleteMyWorkItemFormState?.loading || updateMyWorkItemFormState?.loading ?
            setShowLoading(true)
            :
            setShowLoading(false)
    }, [myWorkItemList, updateMyWorkItemFormState, deleteMyWorkItemFormState, saveMyWorkItemFormState])


    const getScheduleDate = (data: any) => {

        let utcYearAndMonth, utcDay, ScheduledOrgDateAndTme

        if (data?.frequency === "0") {
            utcYearAndMonth = moment().utc().format('YYYY-MM')
            utcDay = parseInt(moment().format('DD'))
        } else if (data?.frequency === "1") {
            let date = moment().day(data?.deliveryDate - 1).toString()
            utcYearAndMonth = moment(date).format('YYYY-MM')
            utcDay = parseInt(moment(date).format('DD'))
        } else {
            utcYearAndMonth = moment().utc().format('YYYY-MM')
            utcDay = data?.deliveryDate
        }
        let orgDateAndTime
        if (data !== null) {
            orgDateAndTime = getOrganizationDateAndTime(moment.utc(data?.nextRun).format())
        } else {
            orgDateAndTime = getOrganizationDateAndTime(moment.utc(utcYearAndMonth + "-" + utcDay + " " + data?.time).format())
        }

        let orgTime = moment.utc(orgDateAndTime).format("hh:mm a")
        let orgDay = moment.utc(orgDateAndTime).format('dddd')
        let orgDate = moment.utc(orgDateAndTime).format("Do")

        if (data?.frequency === "0") {
            ScheduledOrgDateAndTme = "Daily at" + " " + orgTime
        } else if (data?.frequency === "1") {
            ScheduledOrgDateAndTme = "Weekly," + " " + orgDay + " at " + orgTime
        } else if (data?.frequency === "2") {
            ScheduledOrgDateAndTme = "Monthly on the " + orgDate + " at " + orgTime
        } else {
            ScheduledOrgDateAndTme = null
        }
        return ScheduledOrgDateAndTme
    }

    const checkForbiddenDevices = async (deviceIdList: string[]) => {
        try {
            const deviceService = new DeviceService();
            const deviceStatusMap = await deviceService.getDevicesStatus({
                deviceIdList,
                userName: appUser?.userName,
            });

            return Object.values(deviceStatusMap).some((currentStatus: any) =>
                currentStatus?.ProcessingWorkItem || currentStatus?.LockState?.Locked
            );
        } catch (error) {
            console.error("Error in fetching device status", error);
        }
    };

    const devices = useMemo(() => {
      const flatDevicesMap = new Map();
      workItemDeviceList.forEach((workItemDevice: any) => {
      workItemDevice?.devices.forEach((device: any) => {
        const matchingHealth = devicesHealth.find(
          (health: any) => health?.deviceId === device?.id
        );

        const deviceKey = device?.id;
        const applicationValue = `${workItemDevice?.applicationName}-${workItemDevice?.applicationVersion}`;

        if (!flatDevicesMap.has(deviceKey)) {
          flatDevicesMap.set(deviceKey, {
            id: deviceKey,
            lcdDeviceId: device?.lcDeviceId,
            status: matchingHealth?.statusType,
            location: matchingHealth?.hierarchyName,
            deviceName: matchingHealth?.deviceName,
            application: new Set([applicationValue]),
          });
        } else {
          flatDevicesMap.get(deviceKey).application.add(applicationValue);
        }
      });
    });

    const flatDevices = Array.from(flatDevicesMap.values()).map((device) => ({
      ...device,
      application: Array.from(device.application).map((app, index) => (
        <div key={index}>{app}</div>
      )),
    }));
    return flatDevices.sort((a, b) =>
      a.deviceName?.localeCompare(b.deviceName, undefined, {
        sensitivity: "base",
      })
    );
    }, [workItemDeviceList, devicesHealth, isEdit]);

    useEffect(() => {
      if (isEdit) {
        if (
          devicesStatusFormState?.loading ||
          devicesHealthFormstate?.loading ||
          workItemDeviceListFormState?.loading
        ) {
          setWorkItemDeviceLoading(true);
        } else {
          let savedDevices = devices.filter((device: any) =>
            selectedWorkItem[0]?.lcDeviceIds.includes(device?.lcdDeviceId)
          );
          if (savedDevices) {
            setSelectedDevices(savedDevices);
            setIsEdit(false);
            setWorkItemDeviceLoading(false);
          }
        }
      }
    }, [
      devicesHealthFormstate,
      devicesStatusFormState,
      workItemDeviceListFormState,
      isEdit,
    ]);

    const handleAdditionalFeatureClick = (scheduledWorkItem) => {
      let workItem: any = orgSupportedWorkItemList.find(
        (data: any) =>
          data?.lcdWorkItemTypeId === scheduledWorkItem?.lcWorkItemTypeId
      );

      let data = {
        ...scheduledWorkItem,
        applicationVersionIds: workItem?.applicationVersionIds,
        workItemDescription: workItem?.description,
      };

      const parameters = {
        organisationId: orgId,
        body: {
          applicationVersionIds: data.applicationVersionIds,
        },
      };

      dispatch(fetchWorkItemDevices(parameters));
      dispatch(fetchDevicesHealthByOrgId({ organizationId: orgId }));

      let lcDeivceIds = devices.map((device: any) => device?.lcdDeviceId);

      dispatch(
        getDevicesStatus({
          userName: appUser?.userName,
          deviceIdList: lcDeivceIds,
        })
      );
      setSelectedWorkItem([data]);
    };

    const editMyWorkItem = () => {
      setWorkItemDeviceLoading(true);
      setOpenDeviceModal(true);
      setIsEdit(true);
    };

    useEffect(() => {
      if (updateMyWorkItemFormState.isSuccess) {
        let parameter = {
          organisationId: orgId,
          userId: appUser?.id,
        };

        if (isEditSave) {
          setSaveButtonLoading(false);
          setOpenDeviceModal(false);
          setIsEditSave(false);
          dispatch(fetchMyWorkItemList(parameter));
          AppService.showToast(t(Messages.SCHEDULE_WORKITEM_UPDATED));
        } else if (isRunNow) {
          setIsRunNow(false);
          dispatch(fetchMyWorkItemList(parameter));
          AppService.showToast(t(Messages.SCHEDULE_WORKITEM_RUN_NOW));
        } else if (isRename) {
          setIsRename(false);
          dispatch(fetchMyWorkItemList(parameter));
        }
      }
    }, [updateMyWorkItemFormState]);

    const onSaveDevice = () => {
      setSaveButtonLoading(true);
      let lcDeivceIds = selectedDevices.map((data) => data?.lcdDeviceId);
      let param = {
        id: selectedWorkItem[0]?.id,
        body: {
          lcDeviceIds: lcDeivceIds,
          updatedBy: appUser?.id,
        },
      };
      dispatch(updateMyWorkItem(param));
      setIsEditSave(true);
    };

    const onCloseDevice = () => {
      setOpenDeviceModal(false);
      setSelectedDevices([]);
      setSelectedWorkItem([]);
    };

    useEffect(() => {
      if (isRunNow && storeWorkItemLinkFromSate.isSuccess) {
        let parameter: any = {
          id: selectedWorkItem[0]?.id,
          body: {
            lastScheduleStatus: true,
            updatedBy: appUser?.id,
          },
        };
        if (selectedWorkItem[0]?.deliveryDate) {
          parameter.body.deliveryDate = selectedWorkItem[0]?.deliveryDate;
        }
        if (selectedWorkItem[0]?.frequency) {
          parameter.body.frequency = selectedWorkItem[0]?.frequency
        }
        if (selectedWorkItem[0]?.time) {
          parameter.body.time = selectedWorkItem[0]?.time
        }
        dispatch(updateMyWorkItem(parameter));
      }
    }, [storeWorkItemLinkFromSate]);

    const runNowMyWorkItem = async (data) => {
      const lcdDeviceIds = data?.lcDeviceIds;
      const isAnyDeviceForbidden = await checkForbiddenDevices(lcdDeviceIds);

      if (isAnyDeviceForbidden) {
        AppService.showToast(t(jsonData.CanNotCreateWorkItem), true);
        return true;
      } else {
        const createWorkItemBody = {
          body: {
            DeviceIds: lcdDeviceIds,
            WorkItemTypeId: data?.lcWorkItemTypeId,
          },
          userName: appUser?.userName,
        };
        dispatch(createWorkItemLcd(createWorkItemBody));
        setIsRunNow(true);
      }
    };

    useEffect(() => {
        if (createdWorkItemLcdFromSate.isSuccess && isRunNow) {
            dispatch(storeWorkItem({
                userName: appUser?.userName,
                deviceIds: selectedWorkItem[0]?.lcDeviceIds,
                userId: appUser?.id
            }))
        }
    }, [createdWorkItemLcdFromSate]);

    const onWorkItemDelete = (data: any) => {
        setActon(MyWorkItemAction.DELETE);
        setShowWarn(true);
        setWarnCancelBtn(t(MyWorkItemDeleteWarnModal.CANCEL_BTN_TEXT));
        setWarnConfirmBtn(t(MyWorkItemDeleteWarnModal.CONFIRM_BTN_TEXT));
        setWarnTitle(`${t(MyWorkItemDeleteWarnModal.TITLE)} ${data?.name}`);
        setWarnContent(t(MyWorkItemDeleteWarnModal.CONTENT));
    };

    useEffect(() => {
      if (deleteMyWorkItemFormState.isSuccess && isDelete) {
        setIsDelete(true);
        let parameter = {
          organisationId: orgId,
          userId: appUser?.id,
        };
        dispatch(fetchMyWorkItemList(parameter));
      }
    }, [deleteMyWorkItemFormState]);


    const onWarnModalCallback = () => {
      setShowWarn(false);
      let parameter = {
        id: selectedWorkItem[0]?.id,
        body: {
          name: myWorkItemName,
          updatedBy: appUser?.id,
        },
      };

      switch (action) {
        case MyWorkItemAction.DELETE:
          dispatch(deleteMyWorkItem(parameter?.id));
          setIsDelete(true);
          break;
        case MyWorkItemAction.RENAME:
          dispatch(updateMyWorkItem(parameter));
          setIsRename(true);
          break;
      }
      setMyWorkItemName("");
    };


    const onWorkItemRename = (data: any) => {
      let initialValues: SavedFormValue[] = [
        {
          field: "name",
          value: data?.name,
        },
      ];
      dispatch(saveFormValues(initialValues));
      setActon(MyWorkItemAction.RENAME);
      setShowWarn(true);
      setWarnCancelBtn(t(MyWorkItemRenameWarnModal.CANCEL_BTN_TEXT));
      setWarnConfirmBtn(t(MyWorkItemRenameWarnModal.CONFIRM_BTN_TEXT));
      setWarnTitle(`${t(MyWorkItemRenameWarnModal.TITLE)} ${data?.name}`);
      setWarnContent(
        <Form form={form} initialValues={{ name: data?.name }}>
          <Form.Item name="name">
            <CustomInput
              customLabelClass="labelName formControl mt-20"
              name="name"
              labelName="name"
              placeholder="Name"
              rules={[
                {
                  required: true,
                  message: t(jsonData.PleaseMentionName),
                },
                {
                  max: 100,
                  message: t(jsonData.LimitExceeded100characters),
                },
              ]}
              onChange={onNameChange}
            />
          </Form.Item>
        </Form>
      );
      form.setFieldsValue({ name: data?.name });
    };

    const onNameChange = (e: any) => {
      setMyWorkItemName(e.target.value);
      setTarget(e.target);
    };


    useEffect(() => {
        if (!isDirty || myWorkItemName === "") {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [isDirty, myWorkItemName])


    useEffect(() => {
        if (savedFormValues) {
            if (target) {
                dispatch(
                    saveDirtyFormValues({
                        target: target,
                        savedFormValues: savedFormValues,
                    })
                );
            }
        }
    }, [target, myWorkItemName])



    const onScheduleClick = (data: any) => {
        setShowScheduleModal(true)
    };

    const handleMoreActionIcon = (open: boolean, rowId: string) => {
      setOpenRow(open ? rowId : null);
    }

    const menuFunction = (data: any) => {

        const menu = (
          <Menu
            tabIndex={0}
            className={`filter subMenuFix menuDropdown menuDropdownCustomList${data?.id}`}
          >
            <Menu.Item
              tabIndex={0}
              className="filterChild subMenuChild"
              key="1"
              onClick={() => {
                handleMoreActionIcon(false, null);
                editMyWorkItem();
              }}
            >
              {t(jsonData.Edit)}
            </Menu.Item>
            <Menu.Item
              tabIndex={0}
              className="filterChild subMenuChild"
              key="2"
              onClick={() => {
                handleMoreActionIcon(false, null);
                onScheduleClick(data);
              }}
            >
              {t(jsonData.Schedule)}
            </Menu.Item>
            <Menu.Item
              tabIndex={0}
              className="filterChild subMenuChild"
              key="3"
              onClick={() => {
                handleMoreActionIcon(false, null);
                runNowMyWorkItem(data);
              }}
            >
              {t(jsonData.RunNow)}
            </Menu.Item>
            <Menu.Item
              tabIndex={0}
              className="filterChild subMenuChild"
              key="5"
              onClick={() => {
                handleMoreActionIcon(false, null);
                onWorkItemRename(data);
              }}
            >
              {t(jsonData.Rename)}
            </Menu.Item>
            <Menu.Item
              tabIndex={0}
              className="filterChild subMenuChild"
              key="6"
              onClick={() => {
                handleMoreActionIcon(false, null);
                onWorkItemDelete(data);
              }}
            >
              {t(jsonData.Delete)}
            </Menu.Item>
          </Menu>
        )

        return menu;
    }

    const handelDropDown = (e: any, data: any) => {

        const dropdown: any = document.querySelector(`.customDropDownTable${data?.id}`)
        if (e?.keyCode === 13) {
            dropdown.click();
            setTimeout(() => {
                const menuList: any = document.querySelector(`.menuDropdownCustomList${data?.id}`)
                menuList?.focus()
            }, 100)
        }
    }

    const myWorkItemColumns = () => {
        let columns = [
            {
                title: <div className="myworkitem-table-col-name"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Name">{t(jsonData.Name)}</Tooltip></div>,
                dataIndex: 'name',
                sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                key: "name",
                ellipsis: true,
                render: (name: string, data: any) => {
                    return (
                        <span>
                            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={name}>
                                {name}
                            </Tooltip>
                        </span>
                    )
                }
            },
            {
                title: <div className="myworkitem-table-col-name"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Scheduled">{t(jsonData.Scheduled)}</Tooltip></div>,
                key: "Scheduled",
                ellipsis: true,
                render: (data: any) => {
                    let scheduleDate = getScheduleDate(data)
                    let schduledTitle = data?.frequency === "0" ? "Daily" : (data?.frequency === "1" ? "weekly" : (data?.frequency === "2" ? "monthly" : null))
                    return (
                        <span>
                            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={schduledTitle}>
                                {scheduleDate}
                            </Tooltip>
                        </span>)
                }
            },
            {
                title: <div className="myworkitem-table-col-name"> <Tooltip overlayClassName="dashBoardTooltip" placement="bottom" title="Last Run Success">{t(jsonData.LastRunSuccess)}</Tooltip></div>,
                key: "last_run",
                ellipsis: true,
                render: (data: any) => {
                    let lastRun = data?.lastScheduleStatus !== null ? (data?.lastScheduleStatus ? (jsonData.Success) : (jsonData.failed)) : null

                    return (
                        <span>
                            <Tooltip overlayClassName="dashBoardTooltip" placement="bottom" title={lastRun}>
                                {lastRun}
                            </Tooltip>
                        </span>
                    )

                }
            },
            {
                title: <div className="myworkitem-table-col-name"> <Tooltip overlayClassName="dashBoardTooltip" placement="bottom" title={t(jsonData.LastRunDate)}>{t(jsonData.LastRunDate)}</Tooltip></div>,
                key: "Date",
                ellipsis: true,
                render: (data: any) => {
                    let dateData = data?.lastRun ? getOrganizationDateAndTime(data?.lastRun, localeInfo.momentFormat + " " + PickerFormat.TIME) : ''
                    return (
                        <span>
                            <Tooltip overlayClassName="dashBoardTooltip" placement="bottom" title={dateData}>
                                {dateData}
                            </Tooltip>
                        </span>
                    )
                }

            },
            {
                title: <div className="myworkitem-table-col-name"><Tooltip overlayClassName="dashBoardTooltip" placement="bottom" title={t(jsonData.MoreActions)}>{t(jsonData.MoreActions)}</Tooltip></div>,
                key: "menu",
                ellipsis: true,
                render: (data: any) => {
                  const isOpen = openRow === data?.id;
                    return (
                      <Dropdown overlay={menuFunction(data)} trigger={['click']} className={`customDropDownTable${data?.id}`}
                       onVisibleChange={ (open) => {
                        handleMoreActionIcon(open, data?.id)
                       }}
                       >
                        <a tabIndex={-1} onClick={(e) => {
                          handleAdditionalFeatureClick(data)
                        }}>
                          <Space tabIndex={0} onKeyUp={(e: any) => handelDropDown(e, data)} className={`customDropDownSpace${data?.id}`}>
                            {isOpen ? (
                              <DownCircleOutlined className="more-actions-icon" />
                            ) : (
                              <RightCircleOutlined className="more-actions-icon" />
                            )}
                          </Space>
                        </a>
                      </Dropdown>
                    )
                }
            }
        ]

        return columns
    }

    const routes = [
      {
        href: urlModification(AppRoutes.WORK_ITEMS),
        text: (t(jsonData.WorkItems)),
      }
    ]

    const goToBreadcrumbClick  = () => {
      setBredCrumClick(true)
    }

    return (
        <>
            <WarnModal
                cancelButton={warnCancelBtn}
                confirmButton={warnConfirmBtn}
                primaryText={warnTitle}
                secondaryText={warnContent}
                isModalVisible={showWarn}
                disabled={action === MyWorkItemAction.DELETE ? false : disable}

                cancelCallback={() => {
                    setMyWorkItemName("")
                    setShowWarn(false);
                }}
                confirmCallback={() => onWarnModalCallback()}
            />
            <ScheduleModal
                isModalVisible={showScheduleModal}
                setModalVisible={setShowScheduleModal}
                editable={true}
                viewDataValues={selectedWorkItem[0]}
            />
            <HeaderLaytout>
                <span onKeyPress={goToBreadcrumbClick}>
                  <Breadcrumbs breadcrumbs={routes}  breadCrumbClick={breadCrumbClick} setBredCrumClick={setBredCrumClick}/>
                </span>
                <HeaderDiv>
                    <Title>{t(jsonData.MyWorkItems)}</Title>
                </HeaderDiv>
            </HeaderLaytout>
            <ContentLayout>
                <Loader loading={showLoading}>
                    <AntdInfinityTable
                        tableClassName='myworkitem-list'
                        dataSource={myWorkItemList}
                        scroll={
                            {
                                y: scrollHeight,
                                scrollToFirstRowOnChange: true
                            }}
                        locale={{ emptyText: t(jsonData.NoDataFound) }}
                        columns={myWorkItemColumns()}
                        pagination={false}
                    />
                </Loader>
            </ContentLayout>

            {openDeviceModal &&
                <DeviceSelectionModal
                    openModal={openDeviceModal}
                    onClose={() => onCloseDevice()}
                    onSave={() => onSaveDevice()}
                    availableDevices={devices}
                    selectedDevices={selectedDevices}
                    setSelectedDevices={setSelectedDevices}
                    deviceStatusMap={deviceStatusMap}
                    isWorkItemDeviceLoading={workItemDeviceLoading}
                    saveButtonLoading={saveButtonLoading}
                    scheduleButtonLoading={false}
                    setOpenSchedulePopUp={false}
                    showScheduleButton={false}
                    myWorkItem={selectedWorkItem}
                />
            }

        </>
    )
}