import { createAsyncThunk } from "@reduxjs/toolkit";
import { replaceAll } from "../../../utility/appUtil";
import { SavedFormValue } from "../../../web/models/common/savedFormValue";

export const saveFormValues = createAsyncThunk(
  "common/saveFormValues",
  async (data: SavedFormValue[], thunkAPI) => {
    try {
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const saveDirtyFormValues = createAsyncThunk(
  "common/saveDirtyFormValues",
  async (
    _args: { target: any; savedFormValues: SavedFormValue[], explicitValue?: string },
    thunkAPI
  ) => {
    try {
      if (_args && _args.savedFormValues) {
        //debugger;
        const target = _args.target;
        //e.target.id
        //e.target.value
        //e.target.type
        const savedFormValues = _args.savedFormValues;
        let initialValues = JSON.parse(
          JSON.stringify(savedFormValues)
        ) as SavedFormValue[];

        if (target) {
          const id = target.id;
          const type = target.type;
          let value;

          if (_args.explicitValue) {
            value = _args.explicitValue;
          } else if (type === "text" || type === "input") {
            value = target.value;
          }

          const fieldId = (_args.explicitValue)? target: id;
          const field = replaceAll(/profile|accountSettings|deviceForm|_/gi, fieldId, "");
          if (field) {
            const find = initialValues.find((x: any) => x.field === field);
            if (find) {
              if (find.value !== value) {
                find.newValue = value;
              } else {
                delete find.newValue;
              }
            }
          }
        }

        const findChanges = initialValues.filter(
          (x: any) => x.newValue !== undefined && x.value !== x.newValue
        );

        let isDirty = false;
        if (findChanges && findChanges.length) {
          isDirty = true;
        }
        //debugger;
        return ({
          savedFormValues: initialValues,
          isDirty: isDirty,
        });
      } else {
        return thunkAPI.rejectWithValue(_args.savedFormValues);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
