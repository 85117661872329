import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { SUCCESS_ID } from "../../../web/constants/constants";
import { authenticate, authenticateUser, checkEmail, sso } from "../../actions/user/authAction";
import {
  resetPwd,
  resetPwdUserDetail,
  sendForgotPwdLink,
} from "../../actions/user/forgotPwdAction";
import { blockUser, createUser, removeUser, reInviteUser, makeImgUrl } from "../../actions/user/userAction";
import { AuthState } from "../../states/user/authState";
import { RootState } from "../../store/rootReducer";
import { getAccountSettings, mutateAppUserLanguage, saveAccountSettings } from "../../actions/user/userProfileAction";
import { fetchUserPermissions } from "../../actions/user/userAction";
import { getRoutePathName } from "../../actions/app/appAction";

const initialState: AuthState = {
  appUser: null,
  authInput: null,
  routePathName:"",
  formState: resetState(),
  resetPwdFormState: resetState(),
  accountSettingsFormState: resetState(),
  resetPwdSuccess: false,
  isUserInvited: false,
  resetPwdResp:[],
  userDetail:[],
  createPwdState:'',
  uploadLogoUrl:'',
  errorMsg: null,
  ssoFormState: resetState()
};

export const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearUser: (state) => {
      state.user = null;
      return state;
    },
    clearUserDetails: (state) => {
      state.userDetail = [];
      state.resetPwdResp = null;
      state.resetPwdSuccess = false;
      state.createPwdState = '';
      return state;
    },
    clearAuthInput: (state) => {
      state.authInput = null;
      return state;
    },
    clearForgotPwdRequest: (state) => {
      state.forgotPwdRequest = null;
      return state;
    },
    clearFormStateError: (state) => {
      if (state.formState)
        state.formState.errorStack = null;
      return state;
    },
    clearUserActions: (state) => {
      state.isUserBlocked = false;
      state.isUserRemoved = false;
      state.isUserInvited = false;
      return state;
    },
    resetAccountSettingsFormState: (state) => {
      return {
        ...state,
        accountSettingsFormState: resetState(),
        errorMsg: null
      };
    },
    clearResetPwdFormState: (state) => {
      state.resetPwdFormState = resetState();
      state.resetPwdSuccess = false
      return state;
    },
    clearSsoFormState: (state) => {
      if (state.formState)
      state.ssoFormState = resetState();
      return state;
    },
    clearRoutePath: (state) => {
      state.routePathName = "";
      return state;
    },
  },
  extraReducers: {
    [checkEmail.fulfilled.toString()]: (state, { payload }) => {
      state.appUser = payload;
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
    },
    [checkEmail.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [checkEmail.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [authenticate.fulfilled.toString()]: (state, { payload }) => {
      return {...state,
        appUser:{...state.appUser, id: payload.id, title: payload.title, firstName: payload.firstName, middleName: payload.middleName,lastName: payload.lastName, organization: payload.organization, email: payload.email, phone: payload.phone, locations: payload.locations, isAuthenticated: true, profileUrl: payload.profileUrl, isActive: payload.isActive, token: payload.token, idToken: payload.idToken, refreshToken: payload.refreshToken, session: payload.session, info: payload.info}, formState: fulfilledState()
    }},
    [authenticate.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.errorMsg);
      return state;
    },
    [authenticate.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [sso.fulfilled.toString()]: (state, { payload }) => {
      console.log(payload?.session,">>>payload of sso")
      return {...state,
        appUser:{...state.appUser, id: payload.id, title: payload.title, firstName: payload.firstName, middleName: payload.middleName,lastName: payload.lastName, userName:payload.userName, organization: payload.organization, email: payload.email, phone: payload.phone, locations: payload.locations, isAuthenticated: true, profileUrl: payload.profileUrl, isActive: payload.isActive, token: payload.token, idToken: payload.idToken, refreshToken: payload.refreshToken, session: payload.session, info: payload.info}, ssoFormState: fulfilledState()
    }},
    [sso.rejected.toString()]: (state, { payload }) => {
      state.ssoFormState = rejectedState(payload?.errorMsg);
      return state;
    },
    [sso.pending.toString()]: (state) => {
      state.ssoFormState = pendingState();
      return state;
    },

    [authenticateUser.fulfilled.toString()]: (state, { payload }) => {
      state.userDetail = payload;
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
     },
    [authenticateUser.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.errorMsg);
      return state;
    },
    [authenticateUser.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },

    // [fetchUserBytoken.pending]: (state) => {
    //   state.isFetching = true;
    // },
    // [fetchUserBytoken.fulfilled]: (state, { payload }) => {
    //   state.isFetching = false;
    //   state.isSuccess = true;

    //   state.email = payload.email;
    //   state.username = payload.name;
    // },
    // [fetchUserBytoken.rejected]: (state) => {
    //   state.isFetching = false;
    //   state.isError = true;
    // },

    [makeImgUrl.fulfilled.toString()]: (state, { payload }) => {
      state.uploadLogoUrl = payload;
      state.formState = fulfilledState();
      return state;
    },
    [makeImgUrl.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [makeImgUrl.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [sendForgotPwdLink.fulfilled.toString()]: (state, { payload }) => {
      //debugger;
      state.user = payload
      state.forgotPwdRequest = payload;
      state.formState = fulfilledState();
      return state;
    },
    [sendForgotPwdLink.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [sendForgotPwdLink.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [resetPwd.fulfilled.toString()]: (state, { payload }) => {
      state.formState = fulfilledState();
      state.resetPwdSuccess= true;
      state.formState.isSuccess = !payload;
      if (payload) {
        state.formState.errorStack = {
          message: payload
        }
      }
      return state;
    },
    [resetPwd.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
      return state;
    },
    [resetPwd.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },

    [resetPwdUserDetail.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
        state.createPwdState = 'errorState';
      }else{
        state.formState = fulfilledState();
        state.resetPwdResp = payload;
        state.resetPwdSuccess= true;
        state.createPwdState = 'fulfilledState';
      }
      return state;
    },
    [resetPwdUserDetail.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
      state.createPwdState = 'rejected'
      return state;
    },
    [resetPwdUserDetail.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.createPwdState = 'pending'
      return state;
    },

    [createUser.fulfilled.toString()]: (state, { payload }) => {
      state.user = payload;
      state.formState = fulfilledState();
      //debugger;
      state.formState.isSuccess = (payload && payload.id && payload.id === SUCCESS_ID);
      state.isUserInvited = (payload && payload.id && payload.id === SUCCESS_ID);
      return state;
    },
    [createUser.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [createUser.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [blockUser.fulfilled.toString()]: (state, { payload }) => {
      //debugger;
      state.user = payload;
      state.formState = fulfilledState();
      //debugger;
      if (payload) state.isUserBlocked = true;
      return state;
    },
    [blockUser.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [blockUser.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [removeUser.fulfilled.toString()]: (state, { payload }) => {
      state.deletedUserEmail = payload && payload?.email;
      state.formState = fulfilledState();
      state.isUserRemoved = (payload && payload.id && payload.id === SUCCESS_ID);
      return state;
    },
    [removeUser.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [removeUser.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [saveAccountSettings.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.accountSettingsFormState= rejectedState(payload?.message)
        state.errorMsg = payload.errorMsg;
        return state;
      }
      return {
        ...state,
        appUser: { ...state.appUser, title: payload.title, firstName: payload.firstName, lastName: payload.lastName, middleName: payload.middleName, phone: payload.phone, info: { ...state?.appUser?.info, email: payload.info.email, language: payload.info.language, /*playSound: payload.info.playSound,*/ popUpBanner: payload.info.popUpBanner, sms: payload.info.sms, timezone: payload.info.timezone ,logo: payload.info.logo} },
        accountSettingsFormState: fulfilledState()
      };
    },
    [saveAccountSettings.rejected.toString()]: (state, { payload }) => {
      return {
        ...state,
        accountSettingsFormState: rejectedState(payload?.message)
      };
    },
    [saveAccountSettings.pending.toString()]: (state) => {
      return {
        ...state,
        accountSettingsFormState: pendingState()
      };
    },
    [mutateAppUserLanguage.fulfilled.toString()]: (state, {payload}) => {
      return {...state,
        appUser: { ...state.appUser,info: { ...state?.appUser?.info,language: payload} },
        formState: fulfilledState()
      }
    },
    [fetchUserPermissions.pending.toString()]: (state) => {
      return {...state, formState: pendingState()}
    },
    [fetchUserPermissions.fulfilled.toString()]: (state, {payload}) => {
      return {...state,
        appUser: { ...state.appUser, permissions: payload },
        formState: fulfilledState()
      }
    },
    [fetchUserPermissions.rejected.toString()]: (state, {payload}) => {
      return {...state, formState: rejectedState(payload?.message) }
    },
    [getAccountSettings.fulfilled.toString()]: (state, { payload }) => {
      return {...state,
        appUser: { ...state.appUser, title: payload.title, firstName: payload.firstName, lastName: payload.lastName, middleName: payload.middleName, phone: payload.phone, isActive: payload.isActive, info: { ...state?.appUser?.info, email: payload.info.email, language: payload.info.language, playSound: payload.info.playSound, popUpBanner: payload.info.popUpBanner, sms: payload.info.sms, timezone: payload.info.timezone } },
    }},
    [getAccountSettings.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.errorMsg);
      return state;
    },
    [getAccountSettings.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [reInviteUser.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
      state.formState = fulfilledState();
      state.user = payload;
      state.isUserInvited = true;
      }
      return state;
    },
    [reInviteUser.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [reInviteUser.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [getRoutePathName.fulfilled.toString()]: (state, { payload }) => {
      state.routePathName = payload
      return state;
    },
  },
});

export const { clearResetPwdFormState, clearState, clearAuthInput, clearForgotPwdRequest, clearFormStateError, clearUser, clearUserActions, resetAccountSettingsFormState,clearUserDetails, clearSsoFormState,clearRoutePath } = UserSlice.actions;
export const userStateSelector = (state: RootState) => state.user;
export default UserSlice.reducer;
