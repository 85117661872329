import translationsData from '../../languages/mapingMiddleware.json'

export const jsonData = translationsData;

export enum PortalType {
  NONE = 0,
  CLIENT_PORTAL = 1,
}

export enum PortalTypeKey {
  CLIENT_PORTAL = 'pt',
}

export enum HierarchyTypeCode {
  Organisation = 'ORG',
  Location = 'LOC',
}

export enum OperationType {
  Add = 'add',
  Remove = 'remove',
}

export const UnsavedChangesWarnModal ={
  TITLE : jsonData.UnsavedChanges,
  CONTENT :jsonData.ApplyChangesConfirmation,
  DISCARD_BTN_TEXT : jsonData.Discard,
  CONFIRM_BTN_TEXT : jsonData.ApplyChanges,
  CANCEL_BTN_TEXT : jsonData.Discard,
}

export const BlockUserWarnModal ={
  TITLE :jsonData.BlockUser,
  CONTENT :jsonData.BlockUserConfirmation,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.Confirm
}

// UnBlock user
export const UnBlockUserWarnModal ={
  TITLE :jsonData.UnblockUser,
  CONTENT : jsonData.UnblockUserConfirmation,
  CANCEL_BTN_TEXT :jsonData.Cancel,
  CONFIRM_BTN_TEXT :jsonData.Confirm
}

export const RistrictUnBlockUserWarnModal =  {
  CONTENT : jsonData.AssignLocationToUnblock,
  CANCEL_BTN_TEXT : jsonData.Cancel,
}

export const UserAlreadyExistWarnModal = {
  CONTENT : jsonData.AlreadyRegistered,
  CANCEL_BTN_TEXT :jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.RedirectToLogin,
}

export const ResetPwdWarnModal = {
  CONTENT : jsonData.ResetPasswordConfirmation,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.ResetPassword,
  ADMIN_CONTENT : jsonData.ProceedConfirmation,
  USER_CONTENT : jsonData.ProceedConfirmation,
  ADMIN_TITLE : jsonData.ResetLinkEmailMsg,
  USER_TITLE : jsonData.PasswordResetEmailMsg,
}

export const ResendInvite = {
  CONTENT :jsonData.ResendInviteConfirmation,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.ResendInvite,
  ADMIN_CONTENT :jsonData.ProceedConfirmation,
  USER_CONTENT : jsonData.ProceedConfirmation,
  ADMIN_TITLE : jsonData.ResendInviteEmail,
  USER_TITLE : jsonData.ResendInviteLinkEmail
}


export const RemoveUserWarnModal = {
  TITLE : jsonData.RemoveUser,
  CONTENT : jsonData.RemoveUserConfirmation,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT :jsonData.Confirm,
}

export enum UserActionType {
  REMOVE_USER = 0,
  BLOCK_USER = 1,
  CHANGE_PWD = 2,
  UNBLOCK_USER = 3,
  RESEND_INVITE = 4,
  ASSIGN_AND_UNBLOCK_USER = 5

}

export const SUCCESS_ID = '99999'

export const DeleteLocationWarnModal = {
  TITLE : jsonData.DeleteLocation,
  CONTENT :jsonData.ConfirmDeletion,
  CANCEL_BTN_TEXT :  jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.Confirm,
}

export const DeleteRoleWarnModal ={
  TITLE :jsonData.DeleteRole,
  CONTENT :jsonData.ConfirmRoleDeletion,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.Confirm,
}

export const DeleteDeviceWarnModal = {
  TITLE :jsonData.DeleteDevice,
  CONTENT : jsonData.ConfirmDeviceDeletion,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.Confirm,
}

export const SaveInsightWarnModal = {
  TITLE : jsonData.SaveToMyInsights,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT :jsonData.Save,
}

export const InsightDeleteWarnModal= {
  TITLE : jsonData.Delete,
  CONTENT :jsonData.ConfirmInsightDeletion,
  CANCEL_BTN_TEXT :jsonData.Cancel,
  CONFIRM_BTN_TEXT :jsonData.Confirm,
}

export const scheduleDeleteWarnModal= {
  TITLE : jsonData.Delete,
  CONTENT : jsonData.ConfirmScheduleDeletion,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.Confirm,
}

export const InsightRenameWarnModal= {
  TITLE :jsonData.Rename,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.Rename,
}

export const ScheduleWorkItemModal = {
  TITLE : jsonData.SaveToMyWorkItems,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT :jsonData.Save,
}

export const MyWorkItemRenameWarnModal = {
  TITLE :jsonData.Rename,
  CANCEL_BTN_TEXT : jsonData.Cancel,
  CONFIRM_BTN_TEXT : jsonData.Rename,
}
export const MyWorkItemDeleteWarnModal= {
  TITLE : jsonData.Delete,
  CONTENT :jsonData.ConfirmWorkItemDeletion,
  CANCEL_BTN_TEXT :jsonData.Cancel,
  CONFIRM_BTN_TEXT :jsonData.Confirm,
}

export const MyInsightActons ={
  DELETE : 'Delete ',
  RENAME : "Rename",
  SAVE : "Save"
}

export const MyWorkItemAction = {
  DELETE : 'Delete ',
  RENAME : "Rename",
  SAVE : "Save",
  EDIT : "Edit"
}

export const AvatarColorList = [
  '#FF5757',
  '#82D1A2',
  '#55C1F6',
  '#4D4D4D',
  'blue',
  'pink',
  'green',
]

export const ProgressMesaage = 'Work in Progress'

export const Role = {
  BIB_PLATFORM_ADMIN: 'Bibliotheca Platform Admin',
}

export enum PermissionTypes {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW = 'view',
  INDEX = 'index',
}

export enum RoleType {
  BIBLIOTHECA = 'BIBLIOTHECA',
  LIBRARY = 'LIBRARY',
  CUSTOM = 'CUSTOM',
}

export enum KeyboardKeyCharCode {
  ENTER_KEY = 13,
  LEFT_KEY = 37,
  RIGHt_KEY = 39,
}

export const NOTIFICATION_TOAST_CLOSING_TIME = 3 //seconds

export enum eventDeliveryModeCode {
  EMAIL_DELIVERY = 1,
  SMS_DELIVERY = 2,
  WEB_DELIVERY = 3,
}

export enum graphSeries {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export const DefaultTime = {
  START : '00:00',
  END : '23:59',
}

export const ScheduleDefaultTime = {
  START : '00:00',
  END : '23:59',
}

export enum PickerFormat {
  DATE = "DD/MM/YYYY",
  TIME = "HH:mm"
}

export enum SchedulePickerFormat {
  DATE = "DD/MM/YYYY",
  TIME = "HH:mm"
}
export enum OrganisationDatePicker {
  DATE_TIME_US = 'MM/DD/YYYY HH:mm:ss',
  DATE_TIME_GB = 'DD/MM/YYYY HH:mm:ss ',
}
export enum TimePattern {
  HH_MM = "HH:mm",
}

export enum DevicePickerFormat {
  OUTPUT_FORMAT = "YYYY-MM-DDTHH:mm:ss",
  DATE = "DD/MM/YYYY",
  TIME = "HH:mm:ss",
  DATE_TIME="DD/MM/YYYY HH:mm:ss",
}

export const DurationDropDownConstants = {
  TODAY_TEXT : 'Today',
  TODAY_VALUE : 'today',
  
  YESTERDAY_TEXT : 'Yesterday',
  YESTERDAY_VALUE : 'yesterday',
  
  THIS_WEEK_TEXT : 'This week',
  THIS_WEEK_VALUE : 'this week',
  
  LAST_WEEK_TEXT : 'Last week',
  LAST_WEEK_VALUE : 'last week',
  
  THIS_MONTH_TEXT : 'This Month',
  THIS_MONTH_VALUE : 'this month',
  
  LAST_MONTH_TEXT : 'Last Month',
  LAST_MONTH_VALUE : 'last month',
  
  THIS_YEAR_TEXT : 'This Year',
  THIS_YEAR_VALUE : 'this year',

  LAST_YEAR_TEXT : 'Last Year',
  LAST_YEAR_VALUE : 'last year',

  CUSTOM_TEXT : 'Custom Range',
  CUSTOM_VALUE : 'custom',
}

export const AlertDropDownConstants = {

  SELECT_DURATION_TEXT : 'Select Duration',
  SELECT_DURATION_VALUE : 'Select Duration',

  DAY_TEXT : 'Day',
  DAY_VALUE : 'day',
  
  WEEK_TEXT : 'Week',
  WEEK_VALUE : 'week',
  
  MONTH_TEXT : 'Month',
  MONTH_VALUE : 'month',
  
  QUARTER_TEXT : 'Quarter',
  QUARTER_VALUE : 'quarter',
  
  YEAR_TEXT : 'Year',
  YEAR_VALUE : 'year',

  CUSTOM_TEXT : 'Custom',
  CUSTOM_VALUE : 'custom',
}

export const FrequencyDropdown = {

  HOUR_TEXT : 'Hours',
  HOUR_VALUE : 'hour',

  DAY_TEXT : 'Daily',
  DAY_VALUE : 'day',

  MONTH_TEXT : 'Monthly',
  MONTH_VALUE : 'month'

}

export const AlertStatusDropdown = {
  ACTIVE_TEXT: 'Active',
  ACTIVE_VALUE: false,

  CLEARED_TEXT: 'Cleared',
  CLEARED_VALUE: true
}

export const AlertTypeDropdown = {

  ALL_TEXT: 'All',
  ALL_VALUE: "All",

  ACTIVE_TEXT: 'Active',
  ACTIVE_VALUE: true,

  INACTIVE_TEXT: 'Inactive',
  INACTIVE_VALUE: false,

}

export enum InsightRepresentation {
  TABULAR = 'Table View',
  GRAPHICAL = 'Graph View'
}

export const filterOptions = {
  default:{
    report: {text: 'Report View', value: 'all'},
    frequency: 'Frequency',
    dateRange: DurationDropDownConstants.THIS_WEEK_VALUE
  },
  frequency : [
    {
      text: FrequencyDropdown.HOUR_TEXT,
      value: FrequencyDropdown.HOUR_VALUE
    },
    {
      text: FrequencyDropdown.DAY_TEXT,
      value: FrequencyDropdown.DAY_VALUE
    },
    {
      text:FrequencyDropdown.MONTH_TEXT,
      value: FrequencyDropdown.MONTH_VALUE
    },
  ],
  dateRange : [
    {
      text: DurationDropDownConstants.TODAY_TEXT,
      value: DurationDropDownConstants.TODAY_VALUE
    },
    {
      text: DurationDropDownConstants.YESTERDAY_TEXT,
      value: DurationDropDownConstants.YESTERDAY_VALUE
    },
    {
      text: DurationDropDownConstants.THIS_WEEK_TEXT,
      value: DurationDropDownConstants.THIS_WEEK_VALUE
    },
    {
      text: DurationDropDownConstants.LAST_WEEK_TEXT,
      value: DurationDropDownConstants.LAST_WEEK_VALUE
    },
    {
      text: DurationDropDownConstants.THIS_MONTH_TEXT,
      value: DurationDropDownConstants.THIS_MONTH_VALUE
    },
    {
      text: DurationDropDownConstants.LAST_MONTH_TEXT,
      value: DurationDropDownConstants.LAST_MONTH_VALUE
    },
    {
      text: DurationDropDownConstants.THIS_YEAR_TEXT,
      value: DurationDropDownConstants.THIS_YEAR_VALUE
    },
    {
      text: DurationDropDownConstants.LAST_YEAR_TEXT,
      value: DurationDropDownConstants.LAST_YEAR_VALUE
    },
    {
      text: DurationDropDownConstants.CUSTOM_TEXT,
      value: DurationDropDownConstants.CUSTOM_VALUE
    },
  ],
  alertDateRange : [
    {
      text: AlertDropDownConstants.DAY_TEXT,
      value: AlertDropDownConstants.DAY_VALUE
    },
    {
      text: AlertDropDownConstants.WEEK_TEXT,
      value: AlertDropDownConstants.WEEK_VALUE
    },
    {
      text: AlertDropDownConstants.MONTH_TEXT,
      value: AlertDropDownConstants.MONTH_VALUE
    },
    {
      text: AlertDropDownConstants.QUARTER_TEXT,
      value: AlertDropDownConstants.QUARTER_VALUE
    },
    {
      text: AlertDropDownConstants.YEAR_TEXT,
      value: AlertDropDownConstants.YEAR_VALUE
    },
    {
      text: AlertDropDownConstants.CUSTOM_TEXT,
      value: AlertDropDownConstants.CUSTOM_VALUE
    },
  ],
  alertStatus : [
    {
      text: AlertStatusDropdown.ACTIVE_TEXT,
      value: AlertStatusDropdown.ACTIVE_VALUE
    },
    {
      text: AlertStatusDropdown.CLEARED_TEXT,
      value: AlertStatusDropdown.CLEARED_VALUE
    }
  ],
  alertType : [
    {
      text: AlertTypeDropdown.ALL_TEXT,
      value: AlertTypeDropdown.ALL_VALUE
    },
    {
      text: AlertTypeDropdown.ACTIVE_TEXT,
      value: AlertTypeDropdown.ACTIVE_VALUE
    },
    {
      text: AlertTypeDropdown.INACTIVE_TEXT,
      value: AlertTypeDropdown.INACTIVE_VALUE
    },
  ]
}

export enum GroupBy {
  HOUR = 'By Hour',
  DAY = 'By Day',
  MONTH = 'By Month'
}

export enum Pages {
  ACCOUNT_SETTINGS = 'ACCOUNT SETTINGS',
  SETTINGS = 'SETTINGS',
  DEVICES = 'DEVICES',
  JUNTO_PORTAL ='JUNTO PORTAL',
  BIBLIOTHECA = 'BIBLIOTHECA',
  BIBLIOTHECA_ACCOUNT_SETTINGS = 'BIBLIOTHECA ACCOUNT SETTINGS'
}

export enum WarnCalledBy {
  SETTING_ICON = 'SETTING ICON',
  ACCOUNT_SETTING_ICON = 'ACCOUNT SETTING ICON'
}

export enum Links {
  CLOUD_LIBRARY = 'https://CAT.YourCloudLibrary.com'
}

export const DEFAULT_PAGE = 1
export const DEFAULT_LIMIT = 200
export const EV_LOG_PAGE_SIZE = 60
export const AUDIT_DEFAULT_LIMIT = 200
export const AUDIT_LOG_PAGE_SIZE = 60
export const ALERT_DEFAULT_LIMIT = 1000
export const ALERT_PAGE_SIZE = 60
export const ALERT_CONFIG_DEFAULT_LIMIT = 1000
export const ALERT_CONFIG_PAGE_SIZE = 60
export const DEVICE_SETTING_DEFAULT_LIMIT = 1000
export const DEVICE_SETTING_PAGE_SIZE = 60
export const WORK_ITEM_DEFAULT_LIMIT = 200
export const WORK_ITEM_PAGE_SIZE = 60
export const Login_URL= process.env.REACT_APP_LOGO_URL  || "https://libraryconnect.bibliothecatest.com"

export const GroupByText={
  HOUR:jsonData.GroupbyHourofDay,
  DAY:jsonData.GroupbyDayofWeek,
  MONTH:jsonData.GroupbyMonthofYear,
}

export const Status_Type = {
  GOOD : 'good',
  WARNING : 'warning',
  INFO : 'info',
  ERROR : 'error',
  UNMONITORED : 'off',
  UNCERTAIN : 'uncertain'
}

export const languageStaticOptions = [
  { text: "Deutsch (German)", value: "German",code:"de" },
  { text: "English (English)", value: "English",code:"en" },
  { text: "español (Spanish)", value: "Spanish",code:"es" },
  { text: "français (French)", value: "French",code:"fr" },
  { text: "dansk (Danish)", value: "Danish",code:"da" },
  { text: "français canadien (French Canadian)", value: "French-Canadian",code:"fr-CA" },
];

export const deviceHealthSorting = 
  [
    {
      text: jsonData.NameAtoZ,
      value: "deviceName-asc"
    },
    {
      text: jsonData.NameZtoA,
      value: "deviceName-desc"      
    },
    {
      text: jsonData.LocationAtoZ,
      value:"location-asc"      
    },
    {
      text: jsonData.LocationZtoA,
      value:"location-desc"      
    },
    {
      text: jsonData.ProductTypeAtoZ,
      value: "deviceType-asc"      
    },
    {
      text: jsonData.ProductTypeZtoA,
      value:"deviceType-desc"
    },
    {
      text: jsonData.SeverityErrorGood,
      value: "state-desc"
    },
    {
      text: jsonData.SeverityGoodError,
      value: "state-asc"
    },
    {
      text: jsonData.LastUpdateNewestOldest,
      value: "lastUpdated-desc"
    },
    {
      text: jsonData.LastUpdateOldestNewest,
      value: "lastUpdated-asc"
    }
  ];


  export const alertDashBoardSorting =
  [
    {
      text: jsonData.LastUpdateNewest,
      value: "eventTimeStamp-desc"
    },
    {
      text: jsonData.LastUpdateOldest,
      value: "eventTimeStamp-asc"
    },
    {
      text: jsonData.AlertNameAtoZ,
      value: "alertName-asc"
    },
    {
      text: jsonData.AlertNameZtoA,
      value: "alertName-desc"
    },
    {
      text: jsonData.LocationAtoZ,
      value:"hierarchyName-asc"
    },
    {
      text: jsonData.LocationZtoA,
      value:"hierarchyName-desc"
    },
    {
      text: jsonData.SeverityErrorGood,
      value: "severity-asc"
    },
    {
      text: jsonData.SeverityGoodError,
      value: "severity-desc"
    },
    {
      text: jsonData.DeviceNameAtoZ,
      value: "srcName-asc"
    },
    {
      text: jsonData.DeviceNameZtoA,
      value:"srcName-desc"
    },
  ];


export const deviceComponentSorting = 
 [
    {
      text: jsonData.NameAtoZ,
      value: "name-asc",
    },

    {
      text: jsonData.NameZtoA,
      value: "name-desc",
    },
    {
      text: jsonData.SeverityErrorGood,
      value: "severity-desc",
    },
    {
      text: jsonData.SeverityGoodError,
      value: "severity-asc",
    },
    {
      text: jsonData.LastUpdatedNewestOldest,
      value: "lastUpdated-desc",
    },
    {
      text: jsonData.LastUpdatedOldestNewest,
      value: "lastUpdated-asc",
    }
  ];

export const ClearAlertMethod = {
  MANUAL : 'Manual Clear',
  AUTOMATIC : 'Automatic Clear'
}

export const KioskUsageReport = {
  // for summary report
  Report : "all",
  Title : jsonData.KioskUsage,
  // for successful => 'Item Borrowed', 'Item Returned', 'Item Renewed'
  Columns : [1,3,7],
  Id: "Success"
}

export const scheduleFrequency = 
  [
    {
      text: "Daily",
      value: "0"
    },
    {
      text: "Weekly",
      value: "1"      
    },
    {
      text: "Monthly",
      value:"2"      
    },
  ];

export const scheduleExport = [
    {
      text: "GroupExport",
      value: "grouped"
    },
    {
      text: "FlatExport",
      value: "flat"
    } 
]

export const scheduleExportPDR = [
  {
    text: "FlatExport",
    value: "flat"
  } 
]

  export const weekSchedule = 
  [
    {
      text: "Monday",
      value: 2
    },
    {
      text: "Tuesday",
      value: 3     
    },
    {
      text: "Wednesday",
      value: 4     
    },
    {
      text: "Thursday",
      value: 5      
    },
    {
      text: "Friday",
      value: 6      
    },
    {
      text: "Saturday",
      value: 7      
    },
    {
      text: "Sunday",
      value: 1      
    },

  ];

  export const Device_Type = {
    SMART_GATE: "smartgate manager",
    CLOUDCHECK: "cloudcheck tablet",
    // KIOSK: ["selfcheck 500", "selfcheck 500 desktop", "selfcheck 1000","selfcheck 1000 d", "selfcheck 1000 hybrid", "selfcheck 1000 hybrid d", "selfcheck 280", "selfcheck 460", "selfcheck 480", "smartserve410 pay", "smartserve410 recycler", "smartserve 1000 cash", "smartserve1000 card", "smartserve 1000 cash & card", "smartserve1000-d","smartserve1000-d unlock", "smartserver1000-d  card", "smartserve1000-d unlock card", "smartserve1000-m", "smartserve"],
    KIOSK: ["selfcheck", "smartserv","420","420d"],
    EMISECURITY: "ems security controller",
    SECURITYSTATION:"security station",
    LOCKER: ["smartlocker", "outdoor locker"], 
    SHELF:["smartblade", "smartshelf borrow"],
    CLOUDCHECK_MOBILE: ["cloudcheck mobile"],
    bookReturn: ["bookreturn"],
    staffConnect: ["staffconnect"],
    CLOUDCHECKMOBILE_LOC: 'Mobile Devices',
    CLOUDCHECKMOBILE_LOCID: 'd452c8ca-2f55-4cb7-ac22-7d59cfb2dd8a'
    }

  export const FORBIDDEN_STATUS = [Status_Type.UNCERTAIN, Status_Type.UNMONITORED];

  export const WorkItemStatuses = [
    {
      value: "CREATED",
      text: "Created"
    },
    {
      value: "IN_PROGRESS",
      text: "In Progress"
    },
    {
      value: "SUCCESS",
      text: "Success"
    },
    {
      value: "FAILED",
      text: "Failed"
    },
    {
      value: "DELETED",
      text: "Deleted"
    }
  ];

  export enum WorkItemStatusEnum {
    CREATED = "CREATED",
    IN_PROGRESS = "IN_PROGRESS",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    DELETED = "DELETED"
  }
  export const severityList = [
    {
      value: "good",
      text: "good"
    },,
    {
      value: "warning",
      text: "warning"
    },
    {
      value: "error",
      text: "error"
    },
    {
      value: "uncertain",
      text: "uncertain"
    },
    {
      value: "off",
      text: "off"
    }
  ]

  export const CountryLanguageMapping = { 
    "United Kingdom": {
      "English": "en-GB"
    },
    "United States": {
      "English": "en-US"
    },
    "Australia": {
      "English": "en-AU"
    },
    "New Zealand": {
      "English": "en-AU"
    },
    "Canada": {
      "English": "en-US",
      "French": "fr-CA"
    },
    "Denmark": {
      "Danish": "da-DK"
    },
    "France": {
      "French": "fr-FR"
    },
    "Germany": {
      "German": "de-DE"
    },
    "Italy": {
      "English": "en-US"
    },
    "Spain": {
      "Spanish": "es-ES"
    },
    "Switzerland": {
      "German": "de-DE",
      "French": "fr-FR",
    },
    "Portugal": {
      "English": "en-US"
    },
    "Brazil": {
      "English": "en-US"
    },
    "Default": {
      "English": "en-US"
    }
  };


  export const reportTypes = {
    sorting: 'sorting',
    paymentDetails: 'payment details'
  }

  export const paymentDetailPageSize = 200;

  export const newAmountEnteredGraph = ['Card Payment','Cash Payment','ePayment']