/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react"
import { MainLayoutService } from "../../../../services/ui/mainLayoutService"
import { AppRoutes, HeaderType, UserRole } from "../../../../constants/enums"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction"
import { SideBarItems } from "../../../../constants/sideBarConstants"
import { Device_Type, Pages, jsonData } from "../../../../constants/constants"
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper"
import { Form, PageHeader, Row, Col, Checkbox, Tooltip } from 'antd'
import { Breadcrumbs } from "../../../../../bit_components/common/breadcrumbs"
import { Custominput } from "../../../../../bit_components/common/custominput"
import { AppError } from "../../../stateless/common/appError"
import { Button } from "../../../../../bit_components/common/button"
import { Dropdown } from "../../../../../bit_components/common/dropdown"
import { cameFromOrganisation, urlModification } from "../../../../../utility/appUtil"
import './style.less'
import { AddDeviceTitle } from "./index.styles"
import { addDevice, fetchDeviceTypeList } from "../../../../../redux/actions/device/deviceAction"
import { fetchTransformedLocationList } from "../../../../../redux/actions/role/locationAction"
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer"
import { appSelector } from "../../../../../redux/reducers/app/appReducers"
import { sortList } from "../../../../../utility/utils"
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer"
import { clearDeviceFormStates, DeviceSelector, clearAddDeviceErrorState } from "../../../../../redux/reducers/device/deviceReducer"
import { AppService } from "../../../../services/ui/appService"
import { Messages } from "../../../../constants/messages"
import SquareCheckbox from "../../../stateless/common/checkbox/checkBox"
import { Redirect } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker"

export const CreateDevice = (props: any) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [form] = Form.useForm()
    const { appUser } = useSelector(userStateSelector)
    const { selectedOrganisation } = useSelector(appSelector)
    const { locations } = useSelector(locationListSelector)
    const { deviceTypeList, addDeviceFormstate, addDeviceErrorState } = useSelector(DeviceSelector)
    const [isChecked, setIsChecked] = useState(false)
    const [disabled, setDisabled] =useState<boolean>(false)
    const { t } = useTranslation()
    const [deviceTypeName, setDeviceTypeName] = useState('');
    let deviceTypeOptions: any = [];

    const getOrgId = () => {
        let orgId = appUser?.organization.id as string;
        if (cameFromOrganisation()) {
            orgId = selectedOrganisation?.id;
        }
        return orgId;
    }


    useEffect(() => {
        dispatch(onPage({ onComponent: Pages.SETTINGS }));
        MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
        dispatch(sidebarNavigate(SideBarItems.DEVICE_SETTINGS));
        dispatch(fetchDeviceTypeList(null))
        dispatch(fetchTransformedLocationList(getOrgId()))
    }, [])


    const routes = [{
        href: urlModification(AppRoutes.DEVICE_SETTINGS),
        text: (t(SideBarItems.DEVICE_SETTINGS)),
    }]

    const getDeviceProducts = () => {
        const arrTypes = deviceTypeList.map((x: any) => {
            return {
                text: x.deviceType,
                value: x.id,
            }
        })
        const sortedBylabel = arrTypes.sort(sortList('text'));
        const finalOptions = sortedBylabel.filter((data:any) => data.text === 'cloudCheck mobile' || data.text === 'cloudCheck tablet')
        deviceTypeOptions = finalOptions;
        return finalOptions
    }

    const getLocations = () => {
        let typeName : any = deviceTypeName?.toLowerCase()
        if(Device_Type.CLOUDCHECK_MOBILE?.includes(typeName)){
            return [{
                text: Device_Type.CLOUDCHECKMOBILE_LOC,
                value: Device_Type.CLOUDCHECKMOBILE_LOCID
            }]
        }
        else{
            const arrLocation = locations.map((x: any) => {
                return {
                    text: x.name,
                    value: x.id.toString(),
                }
            })
            const sortedBylabel = arrLocation.sort(sortList('text'));
            const finalSortedLoc = sortedBylabel.filter(data => data.text !== Device_Type.CLOUDCHECKMOBILE_LOC )
            return finalSortedLoc
        }
    }

    const getDefaultDeviceType = (list : any[]) => {
        let currentType = deviceTypeName || 'cloudCheck tablet'
        let type = list?.filter((item) => item.deviceType === currentType)[0]?.id
        form.setFieldsValue({
            product: type,
        })
        return type
    }

    const onSelectProduct = (id: string, e: any) => {
        setDeviceTypeName(e.children[0]);
        form.setFieldsValue({
            product: id,
        })
        getLocations()
        form.setFieldsValue({
            location: null,
        })
    }

    const onSelectLocation = (id: string) => {
        form.setFieldsValue({
            location: id,
        })
    }

    const handleFinish = (data: any) => {
        let deviceData: any = {
            organisationId: getOrgId(),
            info: { name: data.name },
            deviceTypeId: data.product,
            hierarchyId: data.location,
            yearOfManufacturing: data.yearOfManufacturing,
            serialNo: data.serialNo,

            state: 'working',
            isActive: true,
            isAssigned: true,
            createdBy: appUser?.id,
            updatedBy: appUser?.id,
        }
        if (isChecked) {
            deviceData.deviceProfile = {
                deviceTypeId: data.product,
                version: data.version,
                modelNo: data.modelNo,
                endOfSupport: data.endOfSupport,
                createdBy: appUser?.id,
                updatedBy: appUser?.id
            }
        }

        dispatch(addDevice(deviceData))
        setDisabled(true)
    }

    useEffect(() => {
        if (addDeviceErrorState?.errorType) {
            setDisabled(false)
            AppService.showToast(addDeviceErrorState?.message, true)
            dispatch(clearAddDeviceErrorState())
        } else if (addDeviceFormstate?.isSuccess) {
            AppService.showToast(t(Messages.DEVICE_ADDED))
            history.push(urlModification(AppRoutes.DEVICE_SETTINGS));
            dispatch(clearDeviceFormStates())
            dispatch(clearAddDeviceErrorState())
        }
        else if (addDeviceFormstate?.isError) {
            AppService.showToast(t(Messages.ERROR_OCCURRED), true)
            dispatch(clearDeviceFormStates())
        }
    }, [addDeviceFormstate])

    const handleKeyPress = () => {
        history.push(urlModification(AppRoutes.DEVICE_SETTINGS))
    }

    const handleAddDevice = () => {
        form.submit();
        UseGAEventTracker("Device Settings Category", "Add Devices", "New Device Add")
    }
    return (
        <>
         {(appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK || appUser.locations[0].role.name === UserRole.ORGANISATION_ADMIN )?

            <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
            :
            <Form layout="vertical" form={form} name="create-device" requiredMark={false} onFinish={handleFinish} scrollToFirstError={true}>
                <div className="pl-vw">
                    <PageHeader
                        title={<AddDeviceTitle>{t(jsonData.AddDevice)}</AddDeviceTitle>}
                        breadcrumb={<div onKeyPress={handleKeyPress}><Breadcrumbs breadcrumbs={routes}></Breadcrumbs></div>}
                        className='create-device-header'
                    />
                    <CompWrapper otherClasses="pl-23">
                        <Row gutter={[50, 30]}>
                            <Col md={12} lg={8} className="labelName">
                                <Custominput
                                    labelSubName={jsonData.DeviceName}
                                    labelName="name" 
                                    name="name"
                                    placeholder={jsonData.DeviceName}
                                    autoComplete="off"
                                    tabIndex={0}
                                    rules={[
                                        {
                                            required: true,
                                            message: jsonData.DeviceNameRequired
                                        },
                                        {
                                            max: 100,
                                            message: jsonData.DeviceNameLengthExceededMsg
                                        },
                                        { 
                                            pattern: new RegExp(/.*?[^0-9].*/),
                                            message: jsonData.DeviceNameNotEmptyMsg
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={12} lg={8} >
                                <Form.Item
                                    className="create-device-dropDown"
                                    label={t(jsonData.DeviceType)}
                                    name="product"
                                    initialValue={getDefaultDeviceType(deviceTypeList ?? [])}
                                    rules={[{ required: true, message: t(jsonData.DeviceTypeIsRequired) }]}
                                >
                                    <Dropdown
                                        optionValue={getDeviceProducts()}
                                        onChange={onSelectProduct}
                                        // disabled={true}
                                        tabIndex={0}
                                    ></Dropdown>
                                </Form.Item>
                            </Col>
                            <Col span={12} lg={8} >
                                <Form.Item
                                    className="create-device-dropDown"
                                    label={t(jsonData.Location)}
                                    name="location"
                                    initialValue={null}
                                    rules={[{ required: true, message: t(jsonData.LocationIsRequired)}]}
                                >
                                    <Dropdown
                                        optionValue={getLocations()}
                                        onChange={onSelectLocation}
                                        tabIndex={0}
                                    ></Dropdown>
                                </Form.Item>
                            </Col>
                            {/* <Col md={12} lg={8} className="labelName">
                                <Custominput labelSubName="Serial No." labelName="serialNo" name="serialNo" placeholder="Serial No." autoComplete="off"/>
                            </Col>
                            <Col md={12} lg={8} className="labelName">
                                <Custominput
                                    rules={[
                                        {
                                            pattern: new RegExp(/^[0-9]*$/),
                                            message: 'Please enter a valid year'
                                        }
                                    ]}
                                    labelSubName="Year of Manufacturing"
                                    labelName="yearOfManufacturing"
                                    name="yearOfManufacturing"
                                    placeholder="Year of Manufacturing"
                                    autoComplete="off"
                                />
                            </Col>*/}
                        </Row>
                        {/*<hr className="create-device-separator" />
                        <div className="create-device-additionalInfo">
                            <SquareCheckbox className="create-device-checkbox" onChange={() => setIsChecked(!isChecked)} isChecked={isChecked} />
                            Add Device Profile Information
                        </div>
                        {
                            isChecked ?
                                <Row gutter={[50, 30]} className='create-device-additionalInfoForm'>
                                    <Col md={12} lg={8} className="labelName">
                                        <Custominput
                                            labelSubName="Version"
                                            labelName="version"
                                            name="version"
                                            placeholder="Version"
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col md={12} lg={8} className="labelName">
                                        <Custominput
                                            labelSubName="Model No."
                                            labelName="modelNo"
                                            name="modelNo"
                                            placeholder="Model No."
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col md={12} lg={8} className="labelName">
                                        <Custominput
                                            labelSubName="End of Support"
                                            labelName="endOfSupport"
                                            name="endOfSupport"
                                            placeholder="End of Support"
                                            autoComplete="off"
                                        />
                                    </Col> 
                                </Row>
                                :
                                ''
                        }*/}
                        <AppError show={false} errorMsg={''} />
                            <Col md={12} lg={8} onKeyDown={handleAddDevice}>
                                <div className="add-device-wrapper">
                                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.AddDevice)}>
                                        <div>
                                            <Button type="primary add-device-btn" disabled={disabled} onClick={handleAddDevice}>{t(jsonData.AddDevice)}</Button>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Col>
                    </CompWrapper>
                </div>
            </Form>
        }
        </>
    )
};
