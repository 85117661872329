import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,

} from "../../../utility/stateUtil";
import { fetchAlertByOrgId, setChartCordinates, setChartTooltipFilter,setSelectedCordinatesCards, setAlertDuration,  fetchTransformedAlertCount, updateActiveAlert, resendAlertNotification } from "../../actions/alert/alertAction";
import { AlertState } from "../../states/alert/alertState";
import { RootState } from "../../store/rootReducer";

const initialState: AlertState = {
  alerts: [],
  totalAlerts: 0,
  alertCount:0,
  warningCount: 0,
  errorCount: 0,
  alertChart: [],
  formState : resetState(),
  AlertCountformState:resetState(),
  selectedCordinatesCards:[],
  chartCordinates:[],
  chartTooltipFilter:[],
  alertDuration: {},
  fetchAlertFormState: resetState(),
  alertClearFormState: resetState(),
  alertCleared: null,
  additionalApiPayload : null,
  resendAlertFormState: resetState()
};

export const AlertSlice = createSlice({
  name: "alert",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearFetchAlertFormState: (state) => {
      state.fetchAlertFormState = resetState();
      return state;
    },
    clearResendAlertFormState: (state) => {
      state.resendAlertFormState = resetState();
      return state;
    },
    clearfetchTransformedAlertCount: (state) => {
      state.AlertCountformState = resetState();
      return state;
    },
    clearAlertList: (state) => {
      state.alerts = []
      return state
    },
    clearUpdateActiveAlert: (state) => {
      state.alertCleared = null
      state.alertClearFormState = resetState()
      return state
    },
    setAdditionalApiPayload: (state, { payload }) => {
      state.additionalApiPayload = payload
      return state
    },
  },
  extraReducers: {
    // Action - fetchAlertByOrgId
    [fetchAlertByOrgId.fulfilled.toString()]: (state, { payload }) => {
      state.alerts = payload?.data;
      state.totalAlerts = payload?.alertCount;
      state.fetchAlertFormState = fulfilledState();
      // state.formState = fulfilledState();
      return state;
    },
    [fetchAlertByOrgId.rejected.toString()]: (state, { payload }) => {
      // state.formState = rejectedState(payload?.message);
      state.fetchAlertFormState = rejectedState(payload?.message);

    },
    [fetchAlertByOrgId.pending.toString()]: (state) => {
      // state.formState = pendingState();
      state.fetchAlertFormState = pendingState();
    },
    [resendAlertNotification.fulfilled.toString()]: (state, { payload }) => {
      state.resendAlertFormState = fulfilledState();
      return state;
    },
    [resendAlertNotification.rejected.toString()]: (state, { payload }) => {
      state.resendAlertFormState = rejectedState(payload?.message);
      return state;
    },
    [resendAlertNotification.pending.toString()]: (state) => {
      state.resendAlertFormState = pendingState();
      return state;
    },
    [updateActiveAlert.fulfilled.toString()]: (state, { payload }) => {
      state.alertCleared = payload
      state.alertClearFormState = fulfilledState();
      return state;
    },
    [updateActiveAlert.rejected.toString()]: (state, { payload }) => {
      state.alertClearFormState = rejectedState(payload?.message);

    },
    [updateActiveAlert.pending.toString()]: (state) => {
      state.alertClearFormState = pendingState();
    },
    
      // Action - setSelectedCordinatesCards
      [setSelectedCordinatesCards.fulfilled.toString()]: (state, { payload }) => {
        state.selectedCordinatesCards = payload;
        state.formState = fulfilledState();
        return state;
      },
      [setSelectedCordinatesCards.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload?.message);
      },
      [setSelectedCordinatesCards.pending.toString()]: (state) => {
        state.formState = pendingState();
      },
      // set Chart data
      [setChartCordinates.fulfilled.toString()]: (state, { payload }) => {
        state.chartCordinates = payload;
        state.formState = fulfilledState();
        return state;
      },
      [setChartCordinates.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload?.message);
      },
      [setChartCordinates.pending.toString()]: (state) => {
        state.formState = pendingState();
      },
       // filter cards
       [setChartTooltipFilter.fulfilled.toString()]: (state, { payload }) => {
        state.chartTooltipFilter = payload;
        state.formState = fulfilledState();
        return state;
      },
      [setChartTooltipFilter.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload?.message);
      },
      [setChartTooltipFilter.pending.toString()]: (state) => {
        state.formState = pendingState();
      },
      [setAlertDuration.fulfilled.toString()]: (state, { payload }) => {
        state.alertDuration = payload;
        state.formState = fulfilledState();
        return state;
      },
      [setAlertDuration.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload?.message);
      },
      [setAlertDuration.pending.toString()]: (state) => {
        state.formState = pendingState();
      },
        // transformation -> fetch alert count in header
        [fetchTransformedAlertCount.fulfilled.toString()]: (state, { payload }) => {
          state.errorCount = payload?.errorCount;
          state.warningCount = payload?.warningCount;
          state.AlertCountformState = fulfilledState();
          return state;
        },
        [fetchTransformedAlertCount.rejected.toString()]: (state, { payload }) => {
          state.AlertCountformState = rejectedState(payload?.message);
        },
        [fetchTransformedAlertCount.pending.toString()]: (state) => {
          state.AlertCountformState = pendingState();
        },
  },
  
});

export const { clearState, clearFetchAlertFormState, clearAlertList, clearUpdateActiveAlert,clearfetchTransformedAlertCount, setAdditionalApiPayload, clearResendAlertFormState } = AlertSlice.actions;
export const AlertSelector = (state: RootState) => state.alert;
export const AlertFormStateSelector = (state: RootState) => state.alert.formState;
export default AlertSlice.reducer;