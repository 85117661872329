/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col, Card, Popover,Tooltip, Spin } from "antd";
import {
  DevicePopUpDescription,
  DevicePopUpHeading,
  DevicePopUpOccurred,
  HealthLastUpdate,
  DeviceInfo,
  LocationInfo,
  ProductName,
  DeviceHealthStateContainer,
} from "./index.styles";
import { AMH, cloudCheckMobile, connectedIcon, connectionLostIcon, connectionUnstableIcon, ErrorIcon, FutureDeviceIcon, GateImage, KioskImage, LockerImage, ShelfImage, TabletImage, warningIcon } from "../../../../../images";
import './deviceHealthCard.less'
import { getElapsedTime, urlModification } from "../../../../../../utility/appUtil";
import { AppRoutes } from "../../../../../constants/enums";
import { displayDeviceMenu, storeSelectedDevice, storeDeviceType } from "../../../../../../redux/reducers/app/appReducers";
import { Device_Type, Status_Type, jsonData } from "../../../../../constants/constants";
import { UseGAEventTracker } from "../../../../../../utility/useGAEventTracker";
import { useTranslation } from 'react-i18next';
import { clearCache } from "../../../../../../utility/utils";
import { clearDeviceConfigStates } from "../../../../../../redux/reducers/device/deviceReducer";
import { LoadingOutlined } from "@ant-design/icons";

export const DeviceHealthCard = (props) => {
  const { t } = useTranslation()
  const { item } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const [warningItem, setWarningItem] = useState([])
  const [errorItem, setErrorItem] = useState([])
  const [deviceState, setDeviceState] = useState<string>()
  const [notClickable, setNotClickable] = useState<boolean>(false)
  const [icon, setIcon] = useState<string>()

  useEffect(() => {
    let foundWarning = []
    let foundError = []
    if(Array.isArray(item?.components)){
      for (let x of item?.components) {
        switch (x?.statusType) {
          case Status_Type.WARNING:
            foundWarning.push(x)
            break
          case Status_Type.ERROR:
            foundError.push(x)
            break
        }
      }
    }
    setWarningItem(foundWarning)
    setErrorItem(foundError)

    //get connection status
    getConnectionStatus(item?.statusType)
    // refresh()
  }, [item])

  const getConnectionStatus = (status: string) => {
    
    switch (status?.toLowerCase()) {
      case Status_Type.UNCERTAIN:
        setDeviceState(Status_Type.UNCERTAIN)
        setIcon(connectionUnstableIcon)
        break;
        
      case Status_Type.UNMONITORED:
        setDeviceState(null)
        setIcon(connectionLostIcon)
        break;

      default:
        setDeviceState(null)
          setIcon(connectedIcon)
    }
  } 

  const getDeviceIcon = () => {
    let type = item.deviceType.toLowerCase()
    if (type === Device_Type.CLOUDCHECK){
      return TabletImage
    }
    else if (type === Device_Type.SMART_GATE) {
      return GateImage
    }    
    else if (Device_Type.KIOSK?.includes(type.slice(0, 9))) {
      return KioskImage
    }
    else if (Device_Type.LOCKER?.includes(type)) {
      return LockerImage
    }
    else if (Device_Type.SHELF?.includes(type)) {
      return ShelfImage
    }
    else if(Device_Type.bookReturn?.includes(type.slice(0,10)) || Device_Type.staffConnect?.includes(type.slice(0,12))){
      return AMH
    }
    else if(Device_Type.CLOUDCHECK_MOBILE?.includes(type)){
      return cloudCheckMobile
    }
    else {
      return FutureDeviceIcon
    }
  }

  const overlay = (overlayContent: any[], type: string) => {
    let typeImg
    switch (type) {
      case Status_Type.WARNING:
        typeImg = warningIcon
        break
      case Status_Type.ERROR:
        typeImg = ErrorIcon
        break
    }

    return (
      overlayContent?.map((x:any, index: number)=>{
      return <Card key={index} className="health-card-overlay">
        <Row className="health-card-overlayInfo">
          <Col>
            <img src={typeImg} alt="" />
          </Col>
          <Col className="health-card-description">
            <DevicePopUpHeading>{x?.status}</DevicePopUpHeading>
            {x?.info?.DisplayText?.length && <DevicePopUpDescription>{x?.info?.DisplayText.replace(/(<([^>]+)>)/gi, "")}</DevicePopUpDescription>}
          </Col>
          <Col className="health-card-occurredAt">
            {x?.timestamp ? <DevicePopUpOccurred>{getElapsedTime(x?.timestamp)}</DevicePopUpOccurred> : ''}
          </Col>
        </Row>
      </Card>
      })
    )
  }

  const goToDevice = () => {
    clearCache("nodeMenus");
    clearCache("configViews");
    dispatch(clearDeviceConfigStates())
    dispatch(displayDeviceMenu(true))
    dispatch(storeSelectedDevice(item))
    dispatch(storeDeviceType(item?.deviceType))

    UseGAEventTracker("Device Health Category", "device health tile", `Selected ${item.deviceName} tile`);

    history.push(urlModification(AppRoutes.DEVICE_COMPONENTS))
  }

  const handleOnClick = (visible: boolean) => {
    setNotClickable(visible)
  }

  // const refresh = () => {
  //   setTimeout(() => {
  //     latestContent()
  //   },60000)
  // }
    
  // const latestContent = () => {
  //   const node = document.getElementById(`healthTimestamp-${item?.deviceId}`)
  //   if(node){
  //     node.innerHTML = getOrganizationDateAndTime(item?.timestamp, null, true, null, true)
  //     getConnectionStatus(item?.timestamp)
  //     refresh()
  //   }
  // }

  const insertStringValue = (text: string, a: string, b: any) => {

    let x = text;
    var y = x.replace(/\{0\}/g, a).replace(/\{1\}/g, b);
    return y;
  }
  

  return (
    <>
      <Card tabIndex={0} aria-label={insertStringValue(t("Device, {0} is in {1} state"),item?.deviceName, item?.statusType)} role={"link"} onKeyPress={item?.components?.length && !notClickable ? goToDevice : () => {}} className={`health-card ${item?.components?.length && !notClickable ? 'linkDeviceComponent' : ''}`} onClick={item?.components?.length && !notClickable ? goToDevice : () => {}}>
        <div className="deviceHealthDiv">
          <DeviceHealthStateContainer component={deviceState ?? item?.statusType?.toString().toLowerCase()} className={`health-status`}></DeviceHealthStateContainer>
          <div className="health-info">
            <div>
              <div className="health-card-div">
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={item?.deviceType}>
                  <img className="deviceHealthIcon" src={getDeviceIcon()} alt=''/>
                </Tooltip>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={item?.statusType}>
                  <img src={icon} alt=''/>
                </Tooltip>
                {warningItem?.length > 0 &&
                  <Popover  overlayClassName="health-popUp" placement="bottomLeft" content={overlay(warningItem, 'warning')} trigger={['hover', 'focus']} onVisibleChange={handleOnClick} getPopupContainer={(trigger: any) => trigger.parentNode}>
                    <img tabIndex={0} aria-label={insertStringValue(t("{0} has {1} warning alerts"), item?.deviceName, warningItem?.length)} className="health-card-alertImg" src={warningIcon} alt="" />
                  </Popover>
                }
                {errorItem?.length > 0 &&
                  <Popover overlayClassName="health-popUp" placement="bottomLeft" content={overlay(errorItem, 'error')} trigger={['hover', 'focus']} onVisibleChange={handleOnClick} getPopupContainer={(trigger: any) => trigger.parentNode}>
                    <img tabIndex={0} aria-label={insertStringValue(t("{0} has {1} error alerts"), item?.deviceName, errorItem?.length)} className="health-card-alertImg" src={ErrorIcon} alt="" />
                  </Popover>
                }
                {item?.isProcessingWorkItem &&
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.WorkItemInProgress)}>
                    <Spin className="deviceSpinner" indicator={<LoadingOutlined style={{ color: "#525aff" }} spin />} size="small" />
                  </Tooltip>
                }
                <HealthLastUpdate id={`healthTimestamp-${item?.deviceId}`}>{item?.timestamp ? getElapsedTime(item?.timestamp, {text: 'long'}) : ''}</HealthLastUpdate>
              </div>
              <DeviceInfo><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={item?.deviceType}>{item?.deviceType}</Tooltip></DeviceInfo>
              <ProductName  className="health-card-deviceName"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={item?.deviceName}><span>{item?.deviceName}</span></Tooltip></ProductName>
              <LocationInfo>
              <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={item?.hierarchyName}>
                {item?.hierarchyName}
                </Tooltip>
              </LocationInfo>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};