import { createAsyncThunk } from "@reduxjs/toolkit";
import MyWorkService from "../../../web/services/workItems/myWorkService";

const myWorkService = new MyWorkService();

export const fetchMyWorkItemList = createAsyncThunk(
  "workItems/fetchMyWorkItems",
  async (_args: any, thunkAPI) => {
    try {
      const data = await myWorkService.fetchMyWorkItemList(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchMyWorkItemById = createAsyncThunk(
  "workItems/byIdfetchMyWorkItems",
  async (_args: any, thunkAPI) => {
    try {
      const data = await myWorkService.fetchMyWorkItemById(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const storeMyWorkItem = createAsyncThunk(
  "workItems/storeMyWorkItem",
  async (_args: any, thunkAPI) => {
    try {
      const data = await myWorkService.storeMyWorkItem(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateMyWorkItem = createAsyncThunk(
  "workItems/updateMyWorkItem",
  async (_args: any, thunkAPI) => {
    try {
      const data = await myWorkService.updateMyWorkItem(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteMyWorkItem = createAsyncThunk(
  "workItems/deleteMyworkItem",
  async (_args: any, thunkAPI) => {
    try {
      const data = await myWorkService.deleteMyWorkItem(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);