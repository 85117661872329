/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Dropdown } from '../../../../../bit_components/common/dropdown'
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer'
import {
  ReportHead,
  ReportDiv,
  GroupByWrapper,
} from './InsightHeader.styles'
import { Col, Row, Checkbox, Tooltip, Dropdown as AntdDropdown, Menu } from 'antd'
import { fetchTransformedLocationList } from '../../../../../redux/actions/role/locationAction'
import { locationListSelector } from '../../../../../redux/reducers/location/locationListReducer'
import { cameFromOrganisation, urlModification, getClientTimeZoneOffset, getCustomDuration, getDuration, getFormattedDate, translateOptions } from '../../../../../utility/appUtil'
import { appSelector } from '../../../../../redux/reducers/app/appReducers'
import './insightHeader.less'
import {
  fetchMyReport,
  fetchReportDataByReportId,
  fetchReportsForUser,
  saveMyReport,
  updateMyReport
} from '../../../../../redux/actions/insights/insightsAction'
import {
  clearState,
  setEventDataValues,
  insightsSelector,
  setShowEventLog,
  showPrevReport,
  storeValues,
  clearSaveReportFormstate,
  clearUpdateReportFormstate,
  clearDeleteReportFormstate,
  clearviewUpdateFormstate,
  setViewDataValues,
  showInitialMyReport,
} from '../../../../../redux/reducers/insights/insightsReducer'
import {
  DefaultTime,
  DurationDropDownConstants,
  filterOptions,
  FrequencyDropdown,
  GroupByText,
  InsightRepresentation,
  jsonData,
  Pages,
  paymentDetailPageSize,
  reportTypes,
  TimePattern,
} from '../../../../constants/constants'
import {
  onPage,
  sidebarNavigate,
} from '../../../../../redux/actions/app/appAction'
import { SideBarItems } from '../../../../constants/sideBarConstants'
import { fetchDeviceListByOrgId, fetchDeviceFilterByOrgId } from '../../../../../redux/actions/device/deviceAction'
import { DeviceSelector } from '../../../../../redux/reducers/device/deviceReducer'
import { daysDifference, getHighestFrequency, numberOfPages, updatePageRange } from '../../../../../utility/insightUtils'
import { downloadIcon, tableIcon, graphIcon } from '../../../../images'
import { Report } from '../../../../models/insights/reports'
import MultiSelectButton from '../../../stateless/common/multiSelect/multiSelectButton/multiSelectButton'
import { sortList } from '../../../../../utility/utils'
import { Button as CustomButton } from "../../../../../bit_components/common/button"
import '../../../stateless/common/button/button.less'
import CustomRange from '../../../stateless/common/customRange'
import { AppRoutes } from '../../../../constants/enums'
import WarnModal from '../../../stateless/common/warnModal'
import { SaveInsightWarnModal } from "../../../../constants/constants";
import { Form } from 'antd'
import { Custominput } from '../../../../../bit_components/common/custominput'
import { Breadcrumbs } from '../../../stateless/common/breadcrumbs'
import { AppService } from '../../../../services/ui/appService'
import { Messages } from '../../../../constants/messages'
import moment from "moment-timezone";
import InsightsService from '../../../../services/insights/insights'
import { organisationDetailsSelector } from '../../../../../redux/reducers/organisation/organisationDetailsReducer'
import { useTranslation } from 'react-i18next';
import { UseGAEventTracker } from '../../../../../utility/useGAEventTracker'
import ExportModel from "./exportModel"

const InsightHeader = (props: any) => {
  const {
    activeRepresentation, setActiveRepresentation, groupByValue, setGroupByValue,
    setShowLoading, pageChanged, currentPage, pages, setPages, onPageChange,
    setCurrentPage, updatePageInfo, setPageChanged, breakFrom, breakTo, setBreakFrom, setBreakTo,
    selectedLocations, setSelectedLocations, selectedDevices, setSelectedDevices, viewInsight, selectedFrequency,
    onlyCloudCheck,setOnlyCloudCheck, insighthasData, setInisghtReportValue,setReportViewValue, exportGraph, downloadingGraph,
    reportViewValue, 
  } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const location: any = useLocation()
  const { appUser } = useSelector(userStateSelector)
  const { locations, formState } = useSelector(locationListSelector)
  const { organisation} = useSelector(organisationDetailsSelector)
  // selectors for reports
  const { reports, reportData, reportValues, prevReport, viewDataValues,myReports, saveReportFormstate, deleteReportFormstate, updateReportFormstate, viewUpdateFormstate, initialMyReport, reportDataFormState } = useSelector(insightsSelector)
  const [orgId, setOrgId] = useState('')
  const { selectedOrganisation } = useSelector(appSelector)
  const [filterDuration, SetFilterDuration] = useState(!viewInsight && !location?.state?.editable && !prevReport && filterOptions.default.dateRange)
  const [reportView, setReportView] = useState<any>()
  const [filterUpdated, setFilterUpdated] = useState<any>()
  const [frequency, setFrequency] = useState(filterOptions.default.frequency)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedHasOtherDevice, setSelectedHasOtherDevice] = useState<boolean>(false)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [paramsUpdated, setParamsUpdated] = useState(true)
  const [isCustomDate, setIsCustomDate] = useState(false)
  const { deviceList, deviceFormState, deviceListFilter,deviceListFilterFormState  } = useSelector(DeviceSelector)
  const [startPage, setStartPage] = useState<any>()
  const [endPage, setEndPage] = useState<any>()
  const [customValues, setCustomValues] = useState<boolean>(false)
  const [showWarn, setShowWarn] = useState(false)
  const [insightName, setInsightName] = useState("");
  const [disable, setDisable] = useState<boolean>(true);
  const [deviceSelected, setDeviceSelected] = useState<boolean>(false);
  const [saveDisable, setSaveDisable] = useState<boolean>(true);
  const [updatedParams, setUpdatedParams] = useState<any>()
  const [downloading, setDownloading] = useState<boolean>(false)
  const [exportModel, setexportModel] = useState<boolean>(false)
  const [exportFormat,setExportFormat]= useState(undefined)

  const [form] = Form.useForm()
  const { t } = useTranslation()

  var ReportAll:number = reports.filter((data:any) => data?.name === "All")[0]?.id
  var ReportPayment:number = reports.filter((data:any) => data?.name === 'Payment')[0]?.id
  var ReportRecom:number = reports.filter((data:any) => data?.name === 'Recommendations')[0]?.id
  var Footfall:number = reports.filter((data:any) => data?.name === 'Footfall')[0]?.id
  var Sorting:number = reports.filter((data:any) => data?.name === 'Sorting')[0]?.id

  const routes = [
    {
      href: urlModification(AppRoutes.MY_INSIGHTS),
      text: (t(jsonData.MyInsights)),
    }
  ]

  const initialValues = {
    frequency: viewDataValues?.filters?.interval,
    selectedDevices:viewDataValues?.filters?.clientdevices ? (viewDataValues?.filters?.clientdevices).slice().sort() : [],
    filterDuration: viewDataValues?.filters?.dateRange,
    reportView: viewDataValues?.reportId,
    locations: viewDataValues?.filters?.locations ? (viewDataValues?.filters?.locations).slice().sort() : [],
    groupByValue: viewDataValues?.filters?.groupByValue,
    activeRepresentation: viewDataValues?.filters?.dataRepresentation
  };

  useEffect(() => {
    setUpdatedParams({ ...updatedParams, frequency: frequency, selectedDevices: selectedDevices.slice().sort(), filterDuration: filterDuration, reportView: reportView, locations: selectedLocations.slice().sort(), groupByValue: groupByValue, activeRepresentation: activeRepresentation })
  }, [frequency, selectedDevices, filterDuration, reportView, selectedLocations, groupByValue, activeRepresentation])

  useEffect(()=>{

    if(updatedParams !== undefined && 
        updatedParams.frequency !== undefined && 
        updatedParams.selectedDevices !== undefined && 
        updatedParams.filterDuration !== undefined && 
        updatedParams.reportView !== undefined && 
        updatedParams.locations !== undefined && 
        updatedParams.groupByValue !== undefined &&
        updatedParams.activeRepresentation !== undefined ){
    if( JSON.stringify(initialValues) === JSON.stringify(updatedParams))
    {
      setSaveDisable(true)
    }
    else
    {
      setSaveDisable(false)
    }
  }
  },[updatedParams])

  useEffect(() => {
    formState?.loading || deviceListFilterFormState?.loading ?
      setShowLoading(true)
      :
      setShowLoading(false)
  }, [formState, deviceListFilterFormState])

  // get Locations
  const getLocations = () => {
    const arrLocation = locations.map((x: any) => {
      return {
        label: x.name,
        value: x.id.toString(),
      }
    })
    const sortedBylabel = arrLocation.sort(sortList('label'));
    return sortedBylabel
  }

  
  const getDeviceList = () => {
    const roleSelected = reports.find((x: any) => x.id === reportView)?.name === "Footfall"
    let arrDevices
    if (roleSelected){
     arrDevices = deviceListFilter?.rows?.filter((x: any) => x?.deviceType === "smartgate manager").map((x: any) => {
      return {
        label: x.info.name ?? (x.serialNo ?? x.id),
        value: x.id.toString(),
        deviceType:x.deviceType
      }
    })
  }
    else{
      arrDevices = deviceListFilter?.rows?.filter((x: any) => x?.deviceType !== "smartgate manager").map((x: any) => {
        return {
          label: x.info.name ?? (x.serialNo ?? x.id),
          value: x.id.toString(),
          deviceType:x.deviceType
        }
      })
    }
    const sortedBylabel = arrDevices?.sort(sortList('label'));
    return sortedBylabel
  }

  // set orgId whenever selectedOrganisation Changes

  useEffect(() => {
    setOrgId(getOrgId())
  }, [selectedOrganisation])

  useEffect(() => {
    if (!prevReport && !viewInsight && !location?.state?.editable) {
      //to select defult report
      const defaultReport = reports.filter((x: Report) => x.name.toLowerCase() === filterOptions.default.report.value.toLowerCase())
      let report = (defaultReport.length ? defaultReport[0]?.id : reports[0]?.id)
      setReportView(report)
    }
  }, [reports])

  // fetch locations whenever orgId changes
  useEffect(() => {
    if (!prevReport && orgId) {
      dispatch(fetchTransformedLocationList(orgId))
    }
  }, [orgId])

  useEffect(() => {
    dispatch(onPage({ onComponent: Pages.JUNTO_PORTAL }))
    dispatch(sidebarNavigate(SideBarItems.INSIGHTS))
    dispatch(fetchReportsForUser({ organisationId: getOrgId(), userId: appUser.id }))

    return () => {
      clearState();
      dispatch(showPrevReport(false))
    }
  }, [])

  useEffect(() => {
    dispatch(storeValues({
      startDate: startDate,
      endDate: endDate,
      frequency: frequency,
      filterDuration: filterDuration,
      reportView: reportView,
      startTime: startTime,
      endTime: endTime,
      locations: selectedLocations,
      groupByValue: groupByValue,
      pages: pages,
      currentPage: currentPage
    }))
  }, [startDate, endDate, frequency, filterDuration, reportView, startTime, endTime, selectedLocations, groupByValue, pages, currentPage])

  useEffect(() => {
    if (prevReport) {
      dispatch(setShowEventLog(false))
      dispatch(setEventDataValues(null))
      reportValues.startDate && setStartDate(reportValues.startDate)
      reportValues.endDate && setEndDate(reportValues.endDate)
      reportValues.frequency && setFrequency(reportValues.frequency)
      reportValues.filterDuration && SetFilterDuration(reportValues.filterDuration)
      reportValues.reportView && setReportView(reportValues.reportView)
      reportValues.startTime && setStartTime(reportValues.startTime)
      reportValues.endTime && setEndTime(reportValues.endTime)
      reportValues.locations && setSelectedLocations(reportValues.locations)
      reportValues.devices && setSelectedDevices(reportValues.devices)
      reportValues.groupByValue && setGroupByValue(reportValues.groupByValue)
      reportValues.pages && setPages(reportValues.pages)
      reportValues.currentPage && onPageChange(reportValues.currentPage)
      reportValues.filterDuration === DurationDropDownConstants.CUSTOM_VALUE && setCustomValues(true)
    }
  }, [prevReport])

  useEffect(() => {

    if((viewInsight || location?.state?.editable) && !prevReport)  {
      dispatch(showInitialMyReport(true))
      // setMyReportFilters()
    }
  }, [viewInsight,location?.state?.editable])

  useEffect(() => {
    if(initialMyReport){
      setMyReportFilters()
    }
  }, [initialMyReport])

  const setMyReportFilters = () => {
    viewDataValues?.filters?.dateRange && SetFilterDuration(viewDataValues?.filters?.dateRange)
    viewDataValues?.reportId && setReportView(viewDataValues?.reportId)
    viewDataValues?.filters?.locations && setSelectedLocations(viewDataValues?.filters?.locations)
    viewDataValues?.filters?.clientdevices && setSelectedDevices(viewDataValues?.filters?.clientdevices)
    viewDataValues?.filters?.groupByValue && setGroupByValue(viewDataValues?.filters?.groupByValue)
    viewDataValues?.filters?.interval && setFrequency(viewDataValues?.filters?.interval)
  }


  useEffect(() => {
    if (selectedLocations.length < locations.length && selectedLocations.length) {
      orgId && dispatch(fetchDeviceFilterByOrgId({ orgId: orgId, locations: selectedLocations }))
    }
    else {
      orgId && dispatch(fetchDeviceFilterByOrgId({ orgId: orgId }))
    }
  }, [selectedLocations, orgId])

  useEffect(() => {
    if (!prevReport && startDate && endDate && !isCustomDate && reportViewValue) {
      let interval = (frequency === filterOptions.default.frequency) ? 'hour' : frequency
      let startDateTime = startDate + " " + (startTime ?? DefaultTime.START)
      let endDateTime = endDate + " " + (endTime ?? DefaultTime.END)

      if (reportViewValue === reportTypes.paymentDetails) {
        setCurrentPage(1)
        updatePageInfo({startPage, endPage, startDateTime, endDateTime })
        setTimeout(() => setParamsUpdated(true), 300)
        return null
      }

      if((
          (filterDuration === DurationDropDownConstants.CUSTOM_VALUE && frequency !== FrequencyDropdown.HOUR_VALUE) ||
          (filterDuration === DurationDropDownConstants.THIS_WEEK_VALUE) ||
          (filterDuration === DurationDropDownConstants.LAST_WEEK_VALUE)
        ) && (frequency === getHighestFrequency(startDateTime, endDateTime))
      ){
          setPages(1)
          setStartPage(startDateTime)
          setEndPage(endDateTime)
          setCurrentPage(1)
          setParamsUpdated(true)
          updatePageInfo({startDateTime: startDateTime, endDateTime: endDateTime,startPage: startDateTime, endPage: endDateTime})
      }
      else   { 
      numberOfPages(startDateTime, endDateTime, interval).then((data: any) => {
        if (data) {
          setPages(data?.pages)
          setStartPage(data?.startPage)
          setEndPage(data?.endPage)
          setCurrentPage(1)
          setParamsUpdated(true)
          updatePageInfo({ ...data, startDateTime: startDateTime, endDateTime: endDateTime, selectedFrequency: frequency })
        }
      })}
    }
  }, [reportViewValue, startDate, startTime, endDate, endTime, frequency])
 
  useEffect(() => {
    if (reportViewValue === reportTypes.paymentDetails) {
      setPages(reportData?.totalPages)
      setStartPage(1)
      setEndPage(reportData?.totalPages)
      setCurrentPage(reportData?.currentPage)
    }
  },[reportData]);

  useEffect(() => {
    if (pageChanged && startDate && endDate) {
      if(reportViewValue !== reportTypes.paymentDetails){
        const startDateTime = startDate + " " + (startTime ?? DefaultTime.START)
        const endDateTime = endDate + " " + (endTime ?? DefaultTime.END)
        const lastpage = pages && pages !== 0 ? pages : 1
        updatePageRange(startDateTime, endDateTime, frequency, currentPage, lastpage).then((values: any) => {
          setStartPage(values.startPage)
          setEndPage(values.endPage)
          updatePageInfo({ ...values, startDateTime: startDateTime, endDateTime: endDateTime, selectedFrequency: frequency })
          setParamsUpdated(true)
        })
      } else {
        setParamsUpdated(true)
      }
    }
  }, [pageChanged, frequency])

  useEffect(() => {
    if (
      reports.length && paramsUpdated && 
      ((reportView === 10) || (startPage && endPage)) &&
      startDate && endDate && !isCustomDate &&
      reportView && deviceListFilter
    ) {
      const startDateTime = startDate + " " + (startTime ?? DefaultTime.START)
      const endDateTime = endDate + " " + (endTime ?? DefaultTime.END)

      let isFilterChanged: boolean = false
      if ((reportViewValue === reportTypes.paymentDetails) && (
          (filterUpdated && filterUpdated?.devices !== selectedDevices.length) || (filterUpdated && filterUpdated?.locations !== selectedLocations.length)
        )
      ){
        isFilterChanged = true
      }

      dispatch(
        fetchReportDataByReportId({
          reportId: reportView,
          organisationId: getOrgId(),
          locations:
            selectedLocations.length < locations.length
              ? selectedLocations
              : [],
          clientdevices:
            selectedDevices.length < (deviceListFilter?.rows?.length)
              ? selectedDevices
              : [],
          startDate: (reportViewValue === reportTypes.paymentDetails) || groupByValue ? startDateTime : startPage,
          endDate: (reportViewValue === reportTypes.paymentDetails) || groupByValue ? endDateTime : endPage,
          interval: frequency === filterOptions.default.frequency ? FrequencyDropdown.HOUR_VALUE : frequency,
          groupByValue: groupByValue,
          pageSize: paymentDetailPageSize,
          page: (reportViewValue === reportTypes.paymentDetails) && isFilterChanged ? 1 : currentPage,
        }),
      )
      setParamsUpdated(false)
      setPageChanged(false)
      dispatch(showPrevReport(false))
      dispatch(showInitialMyReport(false))
      setFilterUpdated(false)
    }
  }, [paramsUpdated, isCustomDate, selectedLocations, selectedDevices, startPage, endPage, reports, groupByValue, reportView])

  useEffect(() => {
    if(reportData && Object.values(reportData) && reportData?.columns?.length){
      props?.onChangeReport()
    }

    if(reportData?.fetchFootfall) {
      onChangeReportView(Footfall)
    }

  }, [reportData])

  // get Orgid
  const getOrgId = () => {
    let orgId = appUser?.organization.id as string
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id
    }
    return orgId
  }

  // set date
  const setDate = (startDateValue: string, endDateValue: string) => {
    setStartDate(startDateValue)
    setEndDate(endDateValue)
    props.onDateChange({ startDate: startDateValue, endDate: endDateValue, startTime: startTime, endTime: endTime })
  }

  const updateDates = (value: any) => {
    let durationObj: any = {}
    if (value === DurationDropDownConstants.THIS_WEEK_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.endDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.DAY_VALUE)
    }
    else if (value === DurationDropDownConstants.LAST_WEEK_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.endDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.DAY_VALUE)
    }
    else if (value === DurationDropDownConstants.TODAY_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.startDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.HOUR_VALUE)
    }
    else if (value === DurationDropDownConstants.YESTERDAY_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.startDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.HOUR_VALUE)
    }
    else if (value === DurationDropDownConstants.THIS_MONTH_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.endDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.DAY_VALUE)
    }
    else if (value === DurationDropDownConstants.LAST_MONTH_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.endDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.DAY_VALUE)
    }
    else if (value === DurationDropDownConstants.THIS_YEAR_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.endDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.MONTH_VALUE)
    }
    else if (value === DurationDropDownConstants.LAST_YEAR_VALUE) {
      durationObj = getDuration(value)
      setDate(durationObj.startDate, durationObj.endDate)
      if (!viewInsight && !initialMyReport)
        setFrequency(FrequencyDropdown.MONTH_VALUE)
    }
    else if (value === DurationDropDownConstants.CUSTOM_VALUE) {
      !customValues && setIsCustomDate(true)
    }
  }

  // on selection of duration from dropdown
  const onChangeDuration = (value: string) => {
    if(reportViewValue !== reportTypes.paymentDetails){
      setStartPage(null)
      setEndPage(null)
    }
    SetFilterDuration(value)

    UseGAEventTracker("Insights Category", "insight range filter", `Selected ${value} range`);
  }

  useEffect(() => {
    if (!prevReport) {
      !endTime && setEndTime(DefaultTime.END)
      !startTime && setStartTime(DefaultTime.START)
      setIsCustomDate(false)
      updateDates(filterDuration)
    }
  }, [filterDuration])

  // on location change
  const updateLocations = (selected) => {
    setFilterUpdated({
      devices: selectedDevices.length,
      locations: selectedLocations.length
    })
    setSelectedLocations(selected)
    selectedDevices?.length > 0 && setSelectedDevices([])
    prevReport && dispatch(showPrevReport(false))
    setParamsUpdated(true)
  }

  // on device change
  const updateDevices = (selected) => {
    setFilterUpdated({
      devices: selectedDevices.length,
      locations: selectedLocations.length
    })
    dispatch(storeValues({
      devices: selected
    }))
    setSelectedDevices(selected)
    prevReport && dispatch(showPrevReport(false))
    // setParamsUpdated(true)
    let selectedDev = getDeviceList()?.filter((device:any) => selected?.includes(device.value))

    let otherThanCloudCheck = selectedDev?.some(device => !device.deviceType?.includes('cloudCheck'))
    if(selected.length) {
      setOnlyCloudCheck(!otherThanCloudCheck)
    } else {
      setOnlyCloudCheck(organisation?.result?.onlyCloudCheck)
    }
    if((selectedDev?.length && !otherThanCloudCheck) && (reportView === ReportPayment || reportView === ReportRecom ) ) {
      onChangeReportView(ReportAll)
    } else {
      setParamsUpdated(true)
    }
  }


  const getReportList = () => {
    const list = reports.map((x: any) => {
      return {
        text: x.name,
        value: x.id
      }
    })
    const sortedBylabel = list.sort(sortList('text'));
    if(onlyCloudCheck){
     return sortedBylabel?.filter((item:any)=>{
         if(!item?.text?.includes("Payment") && item?.text !== "Recommendations"){
          return item
         }
      })
    }else{
      return sortedBylabel
    }
  }

  const updateFrequency = (values: any) => {
    let start = values?.customStartDate + " " + values?.customStartTime
    let end = values?.customEndDate + " " + values?.customEndTime
    let days = daysDifference(start, end)
    switch (true) {
      case days >= 31:
        setFrequency(FrequencyDropdown.MONTH_VALUE)
        break
      case days >= 1:
        setFrequency(FrequencyDropdown.DAY_VALUE)
        break
      default:
        setFrequency(FrequencyDropdown.HOUR_VALUE)
    }
  }

  // on custom range
  const onCustomRange = (values: any) => {
    setEndDate(values?.customEndDate)
    setStartDate(values?.customStartDate)
    setStartTime(values?.customStartTime)
    setEndTime(values?.customEndTime)
    setIsCustomDate(false)
    props.onDateChange({
      startDate: values?.customStartDate,
      endDate: values?.customEndDate,
      startTime: values?.customStartTime,
      endTime: values?.customEndTime,
    })
    updateFrequency(values)
  }

  const handleSaveInsight = (type: 'create' | 'update') => {
    setShowWarn(false)
    const startDateTime = startDate + " " + (startTime ?? DefaultTime.START)
    const endDateTime = endDate + " " + (endTime ?? DefaultTime.END)

    let myReport = {
      reportId: reportValues.reportView,
      filters: {
        interval: reportValues.frequency,
        organisationId: getOrgId(),
        dateRange: reportValues.filterDuration,
        locations:
          selectedLocations.length < locations.length
            ? selectedLocations
            : [],
        clientdevices:
          selectedDevices.length < deviceListFilter.rows.length
            ? selectedDevices
            : [],
        groupByValue: reportValues.groupByValue,
        activeRepresentation : activeRepresentation,
        onlyCloudCheck:onlyCloudCheck
      },
      updatedBy: appUser.id,
      startDate: startDateTime,
      endDate: endDateTime

    }
    switch (type) {
      case 'create':

        dispatch(saveMyReport(
          {

            ...myReport,
            createdBy: appUser.id,
            name: insightName,
            isActive : true
          }
        ))

        UseGAEventTracker("Insights Category", "create my insights", `Created my insights report`);

        break

      case 'update':
        dispatch(updateMyReport({

          ...myReport,
          name: viewDataValues?.name,
          id: viewDataValues?.id

        }))
        dispatch(setViewDataValues({
          ...myReport,
          name: viewDataValues?.name,
          id: viewDataValues?.id
        }))
        break
    }
  }

  useEffect(() => {
    if(breakFrom && breakTo){
      const values = {
        customEndDate: getFormattedDate(breakTo,'YYYY-MM-DD'),
        customStartDate: getFormattedDate(breakFrom,'YYYY-MM-DD'),
        customStartTime: getFormattedDate(breakFrom,TimePattern.HH_MM),
        customEndTime: getFormattedDate(breakTo,TimePattern.HH_MM),
      }
      onCustomRange(values)
      setCustomValues(true)
      setBreakFrom(null)
      setBreakTo(null)
      SetFilterDuration(DurationDropDownConstants.CUSTOM_VALUE)
    }
  }, [breakFrom, breakTo])

  // on report view change
  const onChangeReportView = (value: any) => {
    setReportView(value)
    //setParamsUpdated(true)
    let selectedReportType = getReportList().filter((item)=>item.value === value)
    if(selectedReportType && selectedReportType[0]){        
      UseGAEventTracker("Insights Category", "insight report type", `Selected ${selectedReportType[0].text} report type`);
    }
    
    if(value === Sorting) {
      setInisghtReportValue(true)
    } else {
      setInisghtReportValue(false)
    }
  }

  // on frequency change
  const onChangeFrequency = (value: any) => {
    setFrequency(value)
  }

  const changeGroupBy = (value: any) => {
    setGroupByValue(value.target.checked)
    setParamsUpdated(true)
  }

  useEffect(() => {
    const node = document.querySelector<HTMLElement>(".insightsHeader");
    const resizeObserver = new ResizeObserver((entries) => {
      props.onInsightHeaderResize(node?.clientHeight)
    });
    resizeObserver.observe(node);
  }, []);

  const frequencyFilter = (startDate: string, endDate: string) => {
    let numberOfDays = daysDifference(startDate, endDate)
    let options = [{
      text: t(FrequencyDropdown.HOUR_TEXT),
      value: FrequencyDropdown.HOUR_VALUE
    }]
    switch (true) {
      case numberOfDays >= 31:
        options.push({
          text: t(FrequencyDropdown.DAY_TEXT),
          value: FrequencyDropdown.DAY_VALUE
        }, {
          text: t(FrequencyDropdown.MONTH_TEXT),
          value: FrequencyDropdown.MONTH_VALUE
        })
        return options
      case numberOfDays >= 1:
        options.push({
          text: t(FrequencyDropdown.DAY_TEXT),
          value: FrequencyDropdown.DAY_VALUE
        })
        return options
      default:
        return options
    }
  }

  function groupByText(frequency) {
    switch (frequency) {
      case FrequencyDropdown.HOUR_VALUE:
        return GroupByText.HOUR
      case FrequencyDropdown.MONTH_VALUE:
        return GroupByText.MONTH
      case FrequencyDropdown.DAY_VALUE:
        return GroupByText.DAY
      default:
        return ''
    }
  }

  const refreshPage = () => {
    setParamsUpdated(true)
  }

  const goToMyInsight = () => {
    history.push(urlModification(AppRoutes.MY_INSIGHTS))
  }

  const onNameChange = (e: any) => {
    setInsightName(e.target.value)
  }

  useEffect(() => {
    if (insightName === "") {
      setDisable(true)
    } else (
      setDisable(false)
    )
  }, [insightName])

  const onEditClick = () => {
    const array = AppRoutes.MY_SAVED_INSIGHT_EDIT.split(':id')
    const arr1 = array[0]
    const arr2 = array[1]
    history.push(urlModification(arr1 + viewDataValues?.id + arr2), { editable: true })
    dispatch(showInitialMyReport(true))
  }

  const onCancel = () => {
    history.push(urlModification(AppRoutes.MY_INSIGHTS))  }

  useEffect(() => {
    if (saveReportFormstate?.isSuccess) {
      AppService.showToast(t(Messages.SAVED));
      dispatch(clearSaveReportFormstate());
      history.push(urlModification(AppRoutes.MY_INSIGHTS))
    }
  }, [saveReportFormstate?.isSuccess]);

  useEffect(() => {
    if (updateReportFormstate?.isSuccess) {
      AppService.showToast(t(Messages.UPDATED));
      dispatch(clearUpdateReportFormstate());
      dispatch(fetchMyReport({ organisationId: getOrgId(), userId: appUser.id }))
    }
  }, [updateReportFormstate?.isSuccess])

  useEffect(() => {
    if (myReports && viewDataValues) {
      const name = myReports.filter((x: any) => x.id === viewDataValues?.id)[0]?.name
      dispatch(setViewDataValues({ ...viewDataValues, name: name }))
    }
  }, [myReports])


  useEffect(() => {
    if (deleteReportFormstate?.isSuccess) {
      AppService.showToast(t(Messages.DELETED));
      dispatch(clearDeleteReportFormstate());
      dispatch(fetchMyReport({ organisationId: getOrgId(), userId: appUser.id }))
    }

  }, [deleteReportFormstate?.isSuccess]);

  useEffect(() => {
    if (viewUpdateFormstate?.isSuccess) {
      AppService.showToast(t(Messages.SAVED));
      dispatch(clearviewUpdateFormstate());
      history.push(urlModification(AppRoutes.MY_INSIGHTS));
    }
  }, [viewUpdateFormstate?.isSuccess])

  const startDateTime = startDate + " " + (startTime ?? DefaultTime.START)
    const endDateTime = endDate + " " + (endTime ?? DefaultTime.END)

  const csvFileName = 
    moment(startDateTime).format("DMMMMYYYY") +
    "_" +
    moment(endDateTime).format("DMMMMYYYY");


  // const [exportMethod,setExportMethod]= useState('grouped')

  const paramsForExport = async () => {
    let exportForOnlyCloudCheck
    if (location?.pathname.includes("insights/saved")) {
      if (selectedDevices?.length > 0) {
        let deviceTypes: any = []
        for (var i = 0; i < selectedDevices.length; i++) {
          let deviceTypeArray: any = deviceListFilter.rows.filter((device: any) => device.id === selectedDevices[i])
          deviceTypes.push(deviceTypeArray[0].deviceType)
        }
        let otherThanCloudCheck = deviceTypes?.some(device => !device?.includes('cloudCheck'))
        if (otherThanCloudCheck) {
          exportForOnlyCloudCheck = false
        } else {
          exportForOnlyCloudCheck = true
        }
      } else {
        exportForOnlyCloudCheck = organisation?.result?.onlyCloudCheck
      }
    } else {
      exportForOnlyCloudCheck = selectedDevices?.length < 1 ? organisation?.result?.onlyCloudCheck : onlyCloudCheck
    }
    
    const evParam = {
      reportId: reportView,
      organisationId: getOrgId(),
      locations:
        selectedLocations.length < locations.length
          ? selectedLocations
          : [],
      clientdevices:
        selectedDevices.length < deviceListFilter?.rows?.length
          ? selectedDevices
          : [],
      startDate:  startDateTime,
      endDate:  endDateTime,
      interval: frequency === filterOptions.default.frequency ? FrequencyDropdown.HOUR_VALUE : frequency,
      groupByValue: groupByValue,
      onlyCloudCheck: exportForOnlyCloudCheck,
      exportFormat:exportFormat,
    };
    setExportFormat(undefined)
    return evParam
  }

  const onExport = (fileName: string) => {
    setDownloading(true)
    paramsForExport().then((params: any) => {
      new InsightsService().fetchExportReportData(params).then((res: any) => {
        setDownloading(false)
        return AppService.showToast(res.message)
      }).catch(()=> {
          setDownloading(false)
          return AppService.showToast(t(jsonData.UnableToDownload), true)
      })
    })

    let selectedReportType = getReportList().filter((item)=>item.value === reportView)
    UseGAEventTracker("Insights Category", "insight export", `Exported ${selectedReportType[0].text} report type`);
  }

  useEffect(() => {
    if (exportFormat !== undefined) {

      onExport(csvFileName)
    }
  }, [exportFormat])

  const saveExcel = () => {
    onExport(csvFileName)
  };

  const onExportClick = () => {
    InsightRepresentation.GRAPHICAL === activeRepresentation
      ? exportGraph(csvFileName)
      : setexportModel(true);
  };

  const handleKeyPress = () => {
    setGroupByValue(!groupByValue);
    setParamsUpdated(true);
  }

  useEffect(()=>{
    let selectedReportType = getReportList().filter((item)=>item.value === reportView)
    if(selectedReportType && selectedReportType[0]){   
      setReportViewValue(selectedReportType[0]?.text?.toLowerCase())
    }
  },[reportView,reports])

  return (
    <><WarnModal
      cancelButton={t(SaveInsightWarnModal.CANCEL_BTN_TEXT)}
      confirmButton={t(SaveInsightWarnModal.CONFIRM_BTN_TEXT)}
      primaryText={t(SaveInsightWarnModal.TITLE)}
      disabled={disable}
      secondaryText={ !insighthasData ? null : <Form form={form} >
        <Form.Item name="name" >
          <Custominput
            customLabelClass="labelName formControl mt-20"
            name={t(jsonData.Name)}
            labelName="name"
            placeholder={t(jsonData.Name)}
            rules={[
              {
                required: true,
                message: t(jsonData.PleaseMentionName)
              },
              {
                max: 100,
                message: t(jsonData.LimitExceeded100characters)
              }
            ]}
            onChange={onNameChange}
          />
        </Form.Item>
      </Form>}
      isModalVisible={showWarn}
      cancelCallback={() => {
        setShowWarn(false)
        form.resetFields()
      }}
      confirmCallback={() => handleSaveInsight('create')}
      hideOkText={false}
    />

      <ReportHead className='insightsHeader'>
      {viewInsight || location?.state?.editable ?
        <Row><Breadcrumbs breadcrumbs={routes} /></Row> 
        :null}
        <Row gutter={[0,15]}>
          <Col className='insight-heading insight-heading-center'>
            {viewInsight || location?.state?.editable ? (
              <ReportDiv className='myInightNameWrapper'>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={viewDataValues?.name}>
                 {viewDataValues?.name}
                 </Tooltip>
              </ReportDiv>
            )
              :
              <ReportDiv className='insightPaddingdiv'>{t(jsonData.Insights)}</ReportDiv>
            }
          </Col>
          { insighthasData && (reportViewValue !== reportTypes.paymentDetails) ?
            <Col className='insight-heading-center'>
              <div className='toggle-insight'>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(InsightRepresentation.TABULAR.replace(/\s/g, ''))}>
                  <div role='button' tabIndex={0} aria-label={t(jsonData.insightTableView)} onKeyPress={() => { setActiveRepresentation(InsightRepresentation.TABULAR) }} className={`toggleIcon display-left ${InsightRepresentation.TABULAR === activeRepresentation && 'activeToggle'}`} onClick={() => { setActiveRepresentation(InsightRepresentation.TABULAR) }}>
                    <img className='inactive-icon' alt='' src={tableIcon} />
                  </div>
                </Tooltip>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(InsightRepresentation.GRAPHICAL.replace(/\s/g, ''))}>
                  <div role='button' tabIndex={0} aria-label={t(jsonData.insightGraphView)} onKeyPress={() => { setActiveRepresentation(InsightRepresentation.GRAPHICAL) }} className={`toggleIcon display-right ${InsightRepresentation.GRAPHICAL === activeRepresentation && 'activeToggle'}`} onClick={() => { setActiveRepresentation(InsightRepresentation.GRAPHICAL) }}>
                    <img className='active-icon' alt='' src={graphIcon} />
                  </div>
                </Tooltip>
              </div>
            </Col>
           : null
          }
          <Col className='insight-heading insight-heading-center'>
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Refresh)}>
              <div>
                <CustomButton type="actionButton export-report" onClick={refreshPage}>
                  {t(jsonData.Refresh)}
                </CustomButton>
              </div>
            </Tooltip>

            {(downloading || downloadingGraph) ? 
            <CustomButton type="actionButton export-report" onClick={saveExcel} >
              {/* <img src={downloadIcon} className="export-icon" alt="" />Export  */}
              <span className='exportText' >{t(jsonData.Export)}</span><div className="loaderButton"><span className="spinner"></span></div>
            </CustomButton> :
            insighthasData ?
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Export)}>
            <div>
              <CustomButton type="actionButton export-report" onClick={onExportClick}>
              {/* <img src={downloadIcon} className="export-icon" alt="" /> */}
              {t(jsonData.Export)}
             </CustomButton>
            </div>
           </Tooltip>
            : null
            }
            {viewInsight || location?.state?.editable ?
              <>
                {location?.state?.editable ?
                  <>
                  <Tooltip overlayClassName={`dashBoardTooltip ${(filterDuration === DurationDropDownConstants.CUSTOM_VALUE || saveDisable) === true ? 'insightSave' : '' }`} placement="bottom" title={t(jsonData.Save)}>
                    <div>
                    <CustomButton type="actionButton export-report" disabled={ filterDuration === DurationDropDownConstants.CUSTOM_VALUE || saveDisable } onClick={() => handleSaveInsight('update')}>
                      {t(jsonData.Save)}
                    </CustomButton>
                    </div>
                    </Tooltip>
                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Cancel)}>
                      <div>
                    <CustomButton type="clearButton filter-button" onClick={onCancel}>
                      {t(jsonData.Cancel)}
                    </CustomButton>
                    </div>
                    </Tooltip>
                  </>
                  :
                   <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Edit)}>
                    <div>
                  <CustomButton type="actionButton export-report" onClick={() => onEditClick()}>
                    {t(jsonData.Edit)}
                  </CustomButton>
                  </div>
                  </Tooltip>
                  }
              </>
              :
              <>
                {/* <CustomButton type="actionButton export-report" onClick={() => { goToMyInsight() }} >
                  {t(jsonData.MyInsights)}
                </CustomButton> */}
                {
                  insighthasData ?
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.CreateMyInsights)}>
                <div>
                <CustomButton type="actionButton export-report" disabled={filterDuration === DurationDropDownConstants.CUSTOM_VALUE} onClick={() => { setShowWarn(true) }} >
                  {t(jsonData.CreateMyInsights)}
                </CustomButton>
                </div>
                </Tooltip>
                : null }
              </>
            }

          </Col>
          {!viewInsight || location?.state?.editable ?
            <Col className='insight-filter'>
              {
                insighthasData ?
              <Row>
                { reportViewValue !== reportTypes.paymentDetails && 
                  <Col>
                    <div tabIndex={0} onKeyPress={handleKeyPress} className='groupBy-checkbox'>
                      <Checkbox  tabIndex={-1} checked={groupByValue} onChange={changeGroupBy} >
                      <GroupByWrapper>{t(groupByText(frequency))}</GroupByWrapper></Checkbox>
                    </div>
                  </Col>
                }

                <Col className='insightLocation'>
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.SelectLocation)}>
                    <MultiSelectButton
                    tabIndex={0}
                      optionList={translateOptions(getLocations(),t,'label')}
                      onApply={updateLocations}
                      selectedItems={selectedLocations}
                      allSelectedText={t(jsonData.AllLocations)}
                      name='location'
                      placeholder={t(jsonData.SelectLocation)}
                      optionListClass='location-optionList'
                      wrapperClassName={location?.state?.editable && 'editMyinsight-multiSelectHeight'}
                      allowSearch={true}
                    />
                  </Tooltip>
                </Col>
                <Col>
                 
                    <MultiSelectButton
                    tabIndex={0}
                      optionList={getDeviceList()}
                      onApply={updateDevices}
                      selectedItems={selectedDevices}
                      allSelectedText={t(jsonData.AllDevices)}
                      name='device'
                      placeholder={t(jsonData.SelectDevice)}
                      optionListClass='device-optionList'
                      wrapperClassName={location?.state?.editable && 'editMyinsight-multiSelectHeight'}
                      allowSearch={true}
                    />
                </Col>
                {/* <Col>
                  <Dropdown
                    dropdownAlign={{ offset: [-110, 4] }}
                    className={`filterDropDown insightsDropdown ${location?.state?.editable && 'editMyinsight-DropDownHeight'}`}
                    onChange={onChangeReportView}
                    value={reportView}
                    bordered={false}
                    optionValue={getReportList()}
                  />
                </Col> */}
                <Col>
                  <Dropdown
                    dropdownAlign={{ offset: [-105, 4] }}
                    className={`filterDropDown insightsDropdown ${location?.state?.editable && 'editMyinsight-DropDownHeight'}`}
                    onChange={onChangeDuration}
                    value={filterDuration}
                    bordered={false}
                    optionValue={translateOptions(filterOptions.dateRange,t,'text')}
                    tabIndex={0}
                  />
                </Col>
                { reportViewValue !== reportTypes.paymentDetails && 
                  <Col>
                    <Dropdown         
                      ariaLabel={`${frequency}`}
                      dropdownAlign={{ offset: [-135, 4] }}
                      className={`filterDropDown insightsDropdown ${location?.state?.editable && 'editMyinsight-DropDownHeight'}`}
                      onChange={onChangeFrequency}
                      value={frequency}
                      bordered={false}
                      optionValue={frequencyFilter(startDate + " " + (startTime ?? DefaultTime.START), endDate + " " + (endTime ?? DefaultTime.END))}
                      tabIndex={0}
                    />
                  </Col>
                }
              </Row>
              : null }
            </Col>
            :
            null}

        </Row>
        {filterDuration === DurationDropDownConstants.CUSTOM_VALUE && !viewInsight ? (
          <CustomRange displayHeading='From' onChange={onCustomRange} customValues={customValues} setCustomValues={setCustomValues} start={startDate} end={endDate} from={startTime} to={endTime} />
        ) : null}
         {!viewInsight && insighthasData || location?.state?.editable && insighthasData ?
        <Row className='insightReportView'>
          {t(jsonData.ReportType)}: 
          <Col>
            <Dropdown
              dropdownAlign={{ offset: [-110, 4] }}
              className={`filterDropDown insightsDropdown ${location?.state?.editable && 'editMyinsight-DropDownHeight'}`}
              onChange={onChangeReportView}
              value={reportView}
              bordered={false}
              optionValue={translateOptions(getReportList(),t,'text', true)}
              tabIndex={0}
              activeRepresentation={activeRepresentation}
            />
            </Col>
        </Row> : null }
        <ExportModel isModalVisible={exportModel} setexportModel={setexportModel} setExportFormat={setExportFormat} saveExcel={saveExcel} isGroupDisabled={reportViewValue === reportTypes.paymentDetails}/>
      </ReportHead>
    </>
  )
}

export default InsightHeader