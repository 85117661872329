/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import { Col,Menu, Popover, Row, Tooltip } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { JuntoBlackSideBarItems, BibiliothecaRedSideBarItems, LibrarySettingsSideBarItems, SideBarColor, SideBarBgColor, AccountSettingsSideBarItems, LibrarySettingsSideBarItemsForFrontOffice, DeviceBlackSideBarItems, SideBarItems } from "../../../../constants/sideBarConstants";
import { HeaderType, UserRole } from "../../../../constants/enums";
import { getAppHeaderType, getOrganizationDateAndTime, getUserRoles, urlModification } from "../../../../../utility/appUtil";
import { useDispatch, useSelector } from "react-redux";
import { appSelector,displayDeviceMenu } from "../../../../../redux/reducers/app/appReducers";
import { BackIconImg, connectedIcon, connectionLostIcon, connectionUnstableIcon, ErrorIcon, warningIcon, FilesIcon, AMHWhite } from "../../../../images";
import "./styles.less"
import { settingsExit, sidebarNavigate, accountSettingsExit, warnUnsave, moveToItem, settingsEntered } from "../../../../../redux/actions/app/appAction";
import {
  clearSavedFormValues,
  commonSelector,
} from "../../../../../redux/reducers/common/commonReducer";
import { AppRoutes } from "../../../../constants/enums";
import { DeviceMenuStatus, SideBarHeader, SideBarTitle, SideNavDate, SideNavLocationName, StyledSideMenu } from "./sideNav.Styles";
import { Device_Type, PickerFormat, Status_Type, jsonData} from "../../../../constants/constants";
import { DeviceSelector,clearDeviceInfo,clearDeviceFormStates} from "../../../../../redux/reducers/device/deviceReducer";
import { useTranslation } from 'react-i18next'
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
import { InfoCircleOutlined } from "@ant-design/icons";
import secureLocalStorage from "react-secure-storage";
import { fetchDeviceInfo,fetchDeviceTypeList, updateDeviceInfo } from "../../../../../redux/actions/device/deviceAction";

export const SideNav = (props: any) => {
  const { t } = useTranslation()
  const history = useHistory();
  const loc = useLocation();
  // const [ isSettingsHeaderVisible, setSettingsHeaderVisible] = useState<boolean>(false);
  const { isSettingState, settingsReturnPath, selectedSidebarMenuItem,
    isJuntoAccountSettings, isBibliothecaAccountSettings,
    juntoAccountSettingsReturnPath, bibAccountSettingsReturnPath, isDeviceMenu, selectedDevice, deviceType,
    backToSetting, backToJuntoAccountSettings, isWarnUnsaveOpen, closeWarn, calledBy, itemIndex } = useSelector(appSelector);
  const dispatch = useDispatch();
  const { deviceComponents,deviceInfo} = useSelector(DeviceSelector)
  const { appUser } = props;
  const { isDirty } = useSelector(commonSelector);
  const BACK = 'BACK';
  const ITEM = 'ITEM';
  const localeInfo:any = secureLocalStorage.getItem('locale')

  let sidebar: any = JuntoBlackSideBarItems;

  let sideBarColor = SideBarColor.WHITE;
  let sideBarBgColor = SideBarBgColor.BLACK;
  let sbIconfillColor = 'white';
  let selectedItemStyle =  'selectedItemBlack'
  let isSettingsHeaderVisible = false;
  let navClass = ''

  const DeviceType= deviceInfo?.deviceType;
  const serialNo = deviceInfo?.serialNo;
  const setSideBarColor = (color: any) => {
    sideBarColor = color;
  }

  const setSideBarBgColor = (bgColor: any) => {
    sideBarBgColor = bgColor;
  }

  const setSelectedItemStyle = (style: any) => {
    selectedItemStyle = style;
  }

  const setSettingsHeaderVisible = (isVisible: boolean) => {
    isSettingsHeaderVisible = isVisible;
  }

  const getSideBarItemsForRole = ()=> {
    let role = [];
    if(appUser) role = getUserRoles(appUser);
    if(role.includes(UserRole.LIBRARY_FRONT_DESK)) return LibrarySettingsSideBarItemsForFrontOffice;
    return LibrarySettingsSideBarItems;
  }

  const getInfoIconPermission = ()=> {
    let role = [];
    if(appUser) role = getUserRoles(appUser);
    if(role.includes(UserRole.PLATFORM_ADMIN) || role.includes(UserRole.BIBLIOTHECA_USER) || role.includes(UserRole.ORGANISATION_ADMIN)) return 1;
    return 0;
  }

  const setNavClass = (navi: any) => {
    navClass = navi;
  }


  if (getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL) {
    if((isSettingState || backToSetting) && !isJuntoAccountSettings) {
      setSettingsHeaderVisible(true);
      sidebar = getSideBarItemsForRole();
      setSideBarColor(SideBarColor.BLACK);
      setSideBarBgColor(SideBarBgColor.DEFAULT); //default is actually white
      sbIconfillColor = 'black';
      setSelectedItemStyle('selectedSidebarWhite');
      setNavClass("juntoWhite");
    } else if(isJuntoAccountSettings || backToJuntoAccountSettings) {
      setSettingsHeaderVisible(true);
      sidebar = AccountSettingsSideBarItems;
      setSideBarColor(SideBarColor.BLACK);
      setSideBarBgColor(SideBarBgColor.DEFAULT); //default is actually white
      sbIconfillColor = 'black';
      setSelectedItemStyle('selectedSidebarWhite');
      setNavClass('juntoAccount');
    }
    else {
      const roles = getUserRoles(appUser);
      const isFrontOfficeRole = roles.includes(UserRole.LIBRARY_FRONT_DESK);
      setSettingsHeaderVisible(false);
      setSideBarColor(SideBarColor.WHITE);
      setSideBarBgColor(SideBarBgColor.BLACK);
      sbIconfillColor = 'white';
      setSelectedItemStyle('selectedItemBlack');
      sidebar = isDeviceMenu ? deviceType.toLowerCase() == 'cloudcheck tablet' || isFrontOfficeRole ? DeviceBlackSideBarItems.filter(item => item.name !== SideBarItems.DEVICE_CONFIGURATION) : DeviceBlackSideBarItems : JuntoBlackSideBarItems;
      setNavClass("juntoBlack");
    }

  }
  else {
    if(isBibliothecaAccountSettings) {
      setSettingsHeaderVisible(true);
      sidebar = AccountSettingsSideBarItems;
      setSideBarColor(SideBarColor.BLACK);
      setSideBarBgColor(SideBarBgColor.DEFAULT); //default is actually white
      sbIconfillColor = 'black';
      setSelectedItemStyle('selectedSidebarWhite');
      setNavClass("biblioAccount")

    } else {
      setSettingsHeaderVisible(false);
      sidebar = BibiliothecaRedSideBarItems;
      setSideBarColor(SideBarColor.WHITE);
      setSideBarBgColor(SideBarBgColor.RED);
      sbIconfillColor = 'white';
      setSelectedItemStyle('selectedSidebarRed');
      setNavClass("biblioRed");
    }

  }

  const sideBarItemClick = (selectedIndex: string, url: string) => {
    if (url) {
      UseGAEventTracker("Sidebar Category","sidebar menu click",`selected ${selectedIndex} menu from sidebar`)
      if(selectedIndex === "Configuration") {
        UseGAEventTracker("Device Health Category","device health configuration menu click", `selected configuration menu from sidebar`)
      }
      history.push(urlModification(url));
      if(!isDirty){
        dispatch(sidebarNavigate(selectedIndex))
      }
      else{
        dispatch(moveToItem({itemIndex: selectedIndex,url: url}));
        dispatch(warnUnsave({calledBy: ITEM, isWarnUnsaveOpen: true}));
      }
    }
  };

  const menuScroll = (event : any) => {
    const node = document.querySelector('.sidebarHeader')
    if(event.target.scrollTop > 0 && !node.classList.contains('shadow-bottom')){
      node.classList.add('shadow-bottom')
    }
    else if(event.target.scrollTop === 0 && node.classList.contains('shadow-bottom')){
      node.classList.remove('shadow-bottom')
    }
  }

  useEffect(() => {
    if(isWarnUnsaveOpen && (calledBy===ITEM)){
      if(closeWarn){
        dispatch(sidebarNavigate(itemIndex));
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      }
    }
  })

  const onBack = () => {
    if(getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL) {
      if((isSettingState || backToSetting) && !isJuntoAccountSettings) {
        history.push(urlModification(settingsReturnPath));
        if(!isDirty){
          dispatch(settingsExit());
        }
        else{
          dispatch(warnUnsave({calledBy: BACK, isWarnUnsaveOpen: true}));
        }
      } else if( isJuntoAccountSettings || backToJuntoAccountSettings) {
        history.push(urlModification(juntoAccountSettingsReturnPath));
        if(!isDirty){
          dispatch(accountSettingsExit({headerType: HeaderType.JUNTO_PORTAL, isAccountSettings: false}));
        }
        else{
          dispatch(warnUnsave({calledBy: BACK, isWarnUnsaveOpen: true}));
        }
      } else if(isDeviceMenu){
        // dispatch(displayDeviceMenu(false))
        history.push(urlModification(AppRoutes.DEVICE_HEALTH))
      }
    }
    else {
      if( isBibliothecaAccountSettings) {
        history.push(urlModification(bibAccountSettingsReturnPath));
        if(!isDirty){
          dispatch(accountSettingsExit({headerType: HeaderType.BIBLIOTHECA_PORTAL, isAccountSettings: false}));
        }
        else{
          dispatch(warnUnsave({calledBy: BACK, isWarnUnsaveOpen: true}));
        }
      }
    }
  }

  useEffect(() => {
    if(isWarnUnsaveOpen && (calledBy===BACK)){
      if(closeWarn){
        if(getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL) {
          if((isSettingState || backToSetting) && !isJuntoAccountSettings) {
            dispatch(settingsExit());
          } else if( isJuntoAccountSettings || backToJuntoAccountSettings) {
            dispatch(accountSettingsExit({headerType: HeaderType.JUNTO_PORTAL, isAccountSettings: false}));
          }
        }
        else {
          if( isBibliothecaAccountSettings) {
            dispatch(accountSettingsExit({headerType: HeaderType.BIBLIOTHECA_PORTAL, isAccountSettings: false}));
          }
        }
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      }
    }
  },[]);

  // useEffect(() => {
  //   return history.listen(location => {
  //     if (history.action === 'POP') {
  //       onBack();
  //     }
  //   }
  //   )
  // }, [locationKeys])

  let sideNavHeader = 'Settings';
  if(loc?.pathname.includes('accountSettings')){
    sideNavHeader = jsonData.AccountSettings
  }

  const getConnectionStatus = (status: string, date: any) => {
    
    switch (status?.toLowerCase()) {
      case Status_Type.UNCERTAIN:
        return <><img className="device-menu-img" src={connectionUnstableIcon} alt=''/><span>{t(jsonData.uncertain)}</span></>
        
      case Status_Type.UNMONITORED:
        return <><img className="device-menu-img" src={connectionLostIcon} alt=''/><span>{t(jsonData.off)}</span></>

      default:
        return <><img className="device-menu-img" src={connectedIcon} alt=''/><span>{t(jsonData.connected)}</span></>
    }
  }

  const getSelectedDeviceContent = (components) => {
    let err = 0
    let warn = 0

    if(components?.length){
      for(let x of components){
        switch(x?.statusType){
          case 'warning' :
            warn = warn + 1
            break
          case 'error' :
            err = err + 1
            break
        }
      }
    }

    return(
      <div className="device-menu-content" >
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={`${warn} ${t(jsonData.warnings)}`}>
          <DeviceMenuStatus><img className="device-menu-img" src={warningIcon} alt=''/><span>{warn}</span></DeviceMenuStatus>
        </Tooltip>
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={`${err} ${t(jsonData.errors)}`}>
          <DeviceMenuStatus><img className="device-menu-img" src={ErrorIcon} alt=''/><span>{err}</span></DeviceMenuStatus>
        </Tooltip>
      </div>
    )
  }

  const filesPage = () => {
    UseGAEventTracker("Sidebar Category","sidebar menu click",`selected files menu from sidebar`)

    UseGAEventTracker("Device Health Category","device health files menu click",`selected files menu from sidebar`)
      
    window.open(`${process.env.REACT_APP_DEVICE_URL}/Secure/Devicefiles.aspx?deviceids=${selectedDevice.lcDeviceId}`);
  }

  const sortingLayoutPage = () => {
    UseGAEventTracker("Sidebar Category","sidebar menu click",`selected sorting layout menu from sidebar`)

    UseGAEventTracker("Device Health Category","device health sorting layout menu click",`selected sorting layout menu from sidebar`)

    window.open(`${process.env.REACT_APP_DEVICE_URL}/Secure/SortingLayout.aspx?deviceids=${selectedDevice.lcDeviceId}`);
  }

  // const [selectedNavStyle, setSelectedNavStyle] = useState<any>("")

  // const element = document?.getElementsByTagName('li')
  // if(element[1]?.classList?.contains('selectedItemBlack')) {
  //   setSelectedNavStyle("")
  // } else if (element[1]?.classList?.contains('selectedSidebarWhite')) {
  //   setSelectedNavStyle("")
  // } else if (element[1]?.classList?.contains('selectedSidebarRed') ) {
  //   setSelectedNavStyle("")
  // } else {
  //   setSelectedNavStyle("noNavColor")
  // }
 
 
  // console.log("li---",element[1]?.classList.contains('selectedItemBlack'))
  useEffect(() => {
    if (selectedDevice?.deviceId) {
        dispatch(fetchDeviceInfo({ id: selectedDevice.deviceId }));
    }
    return () => {
        dispatch(clearDeviceInfo());
        dispatch(clearSavedFormValues());
    };
}, [selectedDevice?.deviceId]); 
  const onDeviceInfoIconClick = () => {
    return (
      <div className="deviceInfo">
        <span> <strong>{t(jsonData.DeviceType)} - </strong> {DeviceType}</span>
        {serialNo ? <span> <strong>{t(jsonData.SerialNumber)} - </strong>{serialNo}</span>:"" }
        {deviceInfo?.profile?.softwareVersion && 
        Object.entries(deviceInfo.profile.softwareVersion).map(([key, value]) => 
        ( <span key={key}> <strong>{key} - </strong> {value} </span> )
        )}
      </div>
    );
  };

  return (

    <StyledSideMenu
      mode="inline"
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      color={sideBarColor} background={sideBarBgColor}
      className={`${navClass} sideNavLayout`}
      onScroll={menuScroll}
      tabIndex={0}
      aria-label={t(jsonData.applicationSidebar)} 
      role={""}
    >
      {isSettingsHeaderVisible ?

        <SideBarHeader className="sidebarHeader"  bgcolor= {sideBarBgColor}>
          <Menu.Item tabIndex={0} aria-label={t(jsonData.back)} role={"link"}  key='Back'  onKeyPress={() => onBack()} onClick={() => onBack()} className="sidenav-back-button" >
          <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Back)}>
            <img src={BackIconImg} alt="" className="dashboardSideNavBack" />
            </Tooltip>
            {t(jsonData.Back)}
          </Menu.Item >
          <Row key='sidebar-header' className="sideBarDashboardItems">
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(sideNavHeader)}>
              <span className="settingsSideNav">{t(sideNavHeader)}</span>
            </Tooltip>
          </Row>
        </SideBarHeader>
        : isDeviceMenu && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL ?
          <SideBarHeader className={`sidebarHeader ${(loc?.pathname.includes('components') ||loc?.pathname.includes('devices/configuration')) && 'sibarComponentHeader'}  `}  bgcolor= {sideBarBgColor}  onKeyPress={() => onBack()} >
            <div tabIndex={0} aria-label={t(jsonData.back)} role={"link"} key='Back' onClick={() => onBack()} className="sidenav-back-button" >
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Back)}>
              <img src={BackIconImg} alt="" className="dashboardSideNavBack" />
              </Tooltip>
              {t(jsonData.Back)}
            </div>
            <div key='sidebar-header' className="device-sidenav-header" >
              <div>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={selectedDevice?.deviceName}>
                  <SideBarTitle className="deviceNameSideBar">{selectedDevice?.deviceName}</SideBarTitle>
                </Tooltip>
              <div className="device-info-icon">
                <Popover
                trigger={["click","focus"]}
                placement="bottomLeft" 
                content={onDeviceInfoIconClick}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  {getInfoIconPermission() ?
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t (jsonData.information)}>
                    <InfoCircleOutlined tabIndex={0} className="informationIcon"  onClick={onDeviceInfoIconClick}></InfoCircleOutlined>
                  </Tooltip>: ''}
                </Popover>
              </div>
              </div>
              <SideNavLocationName>{selectedDevice?.hierarchyName}</SideNavLocationName>
              {selectedDevice?.timestamp && <SideNavDate>{t(jsonData.LastUpdatedAt)} : {getOrganizationDateAndTime(selectedDevice?.timestamp,localeInfo?.momentFormat + " " + PickerFormat.TIME)}</SideNavDate>}
              <DeviceMenuStatus>{getConnectionStatus(selectedDevice?.statusType ,selectedDevice?.timestamp)}</DeviceMenuStatus>
              {getSelectedDeviceContent(selectedDevice?.components)}
            </div>
          </SideBarHeader>
          :
          <div className="dashboardUpper"></div>
      }
      {/* {loc?.pathname.includes('accountSettings') ?
        (
          <div>
            <Row key='Back' onClick={() => onBack()}>
              <Col span={1}>
                <img src={BackIconImg} />
              </Col>
              <Col span={8}>
                Back
              </Col>
            </Row>
            <Row key='sidebar-header'>
              <span>Account Settings</span>
            </Row>
          </div>
        ) : (<div></div>)
      } */}
      {
        sidebar.filter((item: any) => {
          if(item?.visibility){
            if(item?.visibility?.includes(appUser?.locations[0].role.name)){
              return true
            }
            else{
              return false
            }
          }
          return true
        }).map((item: any, index: number) => (
          <Menu.Item tabIndex={0} aria-label={t(`${item.name.replace(/\s/g, '')}`)} role={'link'}  key={index} className={"bib-menu-item-row " + (selectedSidebarMenuItem === item.name ? selectedItemStyle : 'noChange') }
          onClick={() => sideBarItemClick(item.name, item.url)}>
            <Row className="sidebarRow" >
              <Col xs={7} sm={7} md={7} lg={7} xl={5} xxl={4}  className="img-column">
                <svg  height='16'  fill= {sbIconfillColor}>
                  <image href={item.img} className={item.classname} />
                </svg>
              </Col>
              <Col xs={17} sm={17} md={17} lg={17} xl={19} xxl={20} className={item.textclass + " " + "sideNavSlice sideNavEllipsis"}>
                {/* {t(`${item.name.replace(/\s/g, '')}`)} */}
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(`${item.name.replace(/\s/g, '')}`)}>
                {t(`${item.name.replace(/\s/g, '')}`)}
                </Tooltip>
              </Col>
            </Row>
          </Menu.Item >
        ))
      }

      {
        (loc?.pathname.includes('components') || loc?.pathname.includes('devices/configuration')) && (Device_Type.bookReturn?.includes(deviceType.toLowerCase().slice(0,10)) || Device_Type.staffConnect?.includes(deviceType.toLowerCase().slice(0,12))) ?
          <>
            <Menu.Item  tabIndex={0} className="sidebarRow bib-menu-item-row" onClick={sortingLayoutPage} >
              <Row className="sidebarRow" >
                <Col xs={7} sm={7} md={7} lg={7} xl={5} xxl={4} className="img-column">
                  <img src={AMHWhite} alt="not found" className="devicesIconFix compIcon" />
                </Col>
                <Col xs={17} sm={17} md={17} lg={17} xl={19} xxl={20} className="sideNavSlice devicesTextFix">
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.SortingLayout)}>
                    <span>{t(jsonData.SortingLayout)}</span>
                  </Tooltip>
                </Col>
              </Row>
            </Menu.Item>
          </>
        : null
      }

      {
        (loc?.pathname.includes('components') ||loc?.pathname.includes('devices/configuration'))&&  deviceType.toLowerCase() != 'cloudcheck tablet' ?
          <>
            <Menu.Item  tabIndex={0} className="sidebarRow bib-menu-item-row" onClick={filesPage} >
            <Row className="sidebarRow" >
              <Col xs={7} sm={7} md={7} lg={7} xl={5} xxl={4} className="img-column">
                <img src={FilesIcon} alt="not found" className="devicesIconFix compIcon" />
              </Col>
              <Col xs={17} sm={17} md={17} lg={17} xl={19} xxl={20} className="sideNavSlice devicesTextFix">
              <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Files)}>
                <span>{t(jsonData.Files)}</span>
                </Tooltip>
              </Col>
              </Row>
            </Menu.Item>
          </>
          : null
      }
    </StyledSideMenu>
  );
};
