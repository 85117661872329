/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Modal, notification, Button, Typography, Tooltip,Select } from 'antd'
import './appHeader.less'
import { ProfileThumbnailPicture } from '../../../stateless/user/profile/profileThumbnailPicture'
import {
  AvatarColorList,
  graphSeries,
  jsonData,
  NOTIFICATION_TOAST_CLOSING_TIME,
  WarnCalledBy,
} from "../../../../constants/constants";
import { AppRoutes } from "../../../../constants/enums";
import { useHistory } from "react-router-dom";
import {
  fetchTransformedAlertCount
} from '../../../../../redux/actions/alert/alertAction'
import {
  BellIconImg,
  Bibliotheca,
  SettingIconImg,
  warningIcon,
  seperatorIcon,
  disableSettingIcon,
  ErrorIcon,
  warningZeroIcon,
  errorZeroIcon,
  UnionIconImg,
  UnionDot,
} from '../../../../images'
import { Filter } from '../../../../../bit_components/common/filter'
import ServicesMenu from '../servicesMenu'
import { useLocation } from 'react-router-dom'
import { HeaderType, UserRole } from '../../../../constants/enums'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationSelector } from '../../../../../redux/reducers/notifications/notificationsReducer'
import {
  appSelector,
  logout,
  setAlertClicked,
  setAlertSeverity,
  ssoLogout,
  ssoLogoutTrigger
} from '../../../../../redux/reducers/app/appReducers'
import {
  accountSettingsEntered,
  getSelectedOrg,
  settingsEntered,
  settingsExit,
  sidebarNavigate,
  warnUnsave,
} from "../../../../../redux/actions/app/appAction";
import {
  cameFromOrganisation,
  concatNames,
  getAppHeaderType,
  getFormattedNumber,
  getUserRoles,
  urlModification,
} from '../../../../../utility/appUtil'
import {
  AlertSelector,
} from '../../../../../redux/reducers/alert/alertReducer'
import { ThumbNailWrapper } from './appHeader.styles'
import { _io, _manager, _url } from '../../../../services/websocket/websocket'
import {
  fetchTransformedNotifications,
  postReadNotification,
} from "../../../../../redux/actions/notifications/notificationsAction";
import NotificationCards from "../notificationCard/notificationcaard";
import { getFormattedString } from "../../../../../utility/utils";
import {
  commonSelector,
} from "../../../../../redux/reducers/common/commonReducer";
import { fetchDeviceComponents, fetchDevicesHealthByOrgId } from '../../../../../redux/actions/device/deviceAction';
import { DeviceSelector } from '../../../../../redux/reducers/device/deviceReducer';
import { Redirect } from "react-router-dom"
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer';
import { UseGAEventTracker } from '../../../../../utility/useGAEventTracker';
import secureLocalStorage from 'react-secure-storage';
import { WorkSelector, setWorkItemRefresh } from "../../../../../redux/reducers/workItems/workReducer";
import { MyWorkSelector } from "../../../../../redux/reducers/workItems/myWorkReducer";

import { fetchMyWorkItemList } from '../../../../../redux/actions/workItems/myWorkAction';
import { AppService } from '../../../../services/ui/appService';

const languages = [
  {
    value: 'fr',
    label: 'Français',
    country_code: 'fr',
  },
  {
    value: 'en',
    label: 'English',
    country_code: 'gb',
  },
  {
    value: 'de',
    label: 'German',
    country_code: 'de',
  },
  {
    value: 'es',
    label: 'Spanish',
    country_code: 'es',
  },
  {
    value: 'da',
    label: 'Danish',
    country_code: 'da',
  },
  {
    value: 'fr-CA',
    label: 'French (Canadian)',
    country_code: 'fr-CA',
  },
 
]


export const AppHeader = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { isSettingState, isJuntoAccountSettings, isBibliothecaAccountSettings,
    isWarnUnsaveOpen, calledBy, closeWarn, selectedOrganisation, selectedDevice } = useSelector(appSelector);
  const { deviceHealthParams, deviceComponentParams,deviceComponentsFormstate ,devicesHealthFormstate,cancelToken} = useSelector(DeviceSelector)
  const {
    warningCount,
    errorCount,
    AlertCountformState,
  } = useSelector(AlertSelector)
  const { notifications, notificationsFormState } = useSelector(NotificationSelector)
  const history = useHistory()
  const [logo, setLogo] = useState<string>('')
  const [logoClassName, setClassName] = useState<string>('')
  const [isWarnAlertVisible, setWarnAlertVisible] = useState<boolean>(true)
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [ackPopup, setAckPopup] = useState<boolean>(true)
  const [isFromBiblothecaPortal, setFromBibliothecaPortal] = useState<boolean>(
    false,
  )
  const [setting, showSetting] = useState<boolean>(false)
  const [menuDisplay, setMenuDisplay] = useState<boolean>(false)
  var menuRef = useRef<HTMLElement>(null)
  var iconRef = useRef<HTMLImageElement>(null)
  var bellRef = useRef(null)
  const {appUser} = useSelector(userStateSelector)
  const [count, updateCount] = useState<number>(-1)
  const [socketData, setSocketData] = useState<any>([])
  const [notificationsArray, setNotificationsArray] = useState<any>([])
  const [postShow, setPostShow] = useState(true)
  const [ackInputs, setAckInputs] = useState<any>()
  const [showPortalLink, setShowPortalLink] = useState<boolean>(false)
  const [logoLink, setLogoLink] = useState<string>('')
  const [exportNotifications, setExportNotifications] = useState<any>([]);
  const locationUrl = useLocation()
  const { Paragraph } = Typography
  let socket = _io(_url, {
    autoConnect: false,
  })
  const { isDirty } = useSelector(commonSelector);
  const { fetchWorkItemsFormState } = useSelector(WorkSelector)
  const { myWorkItemListFormState } = useSelector(MyWorkSelector)


  useEffect(()=>{
    return ()=>{
      socket.off('message')   
      socket.disconnect() 
      socket.close()
    }
  },[])

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id
    }
    return orgId
  }

  const getNotification = {
    userId: appUser?.id,
    organisationId: getOrgId()
  };

  //web socket
  useEffect(() => {
    if (appUser?.isAuthenticated) {
      socket.auth = { userId: appUser?.id, sessionId: appUser?.session }
      socket.connect()
    }
  }, [appUser?.id])

  // update after fetching notifications
  useEffect(() => {
    setNotificationsArray(notifications)
  }, [notifications, socketData])

  useEffect(() => {
    dispatch(fetchTransformedNotifications(getNotification))
      dispatch(fetchTransformedAlertCount({ organizationId: getOrgId() }))
  }, [])

  useEffect(() => {
    if (notificationsArray?.result?.length < 1) {
      updateCount((prevCount) => (prevCount = 0))
    }
    updateCount((prevCount) => (prevCount = 0))
  }, [notificationsArray])

  useEffect(() => {
    const node = document.querySelector('.animateWarning')
    if(node && AlertCountformState.isSuccess){
      node.classList.add("popout")
      setTimeout(() => {
        node.classList.remove("popout")
      }, 1000);
    }  
  },[warningCount])

  useEffect(() => {
    const node = document.querySelector('.animateError')
    if(node && AlertCountformState.isSuccess){
    node.classList.add("popout")
    setTimeout(() => {
    node.classList.remove("popout")
    }, 1000);
  }
  }, [errorCount]) 



  const notificationBell = () => {
    setModalShow(true)
    setAckPopup(true)
    setTimeout(() => {
      let NotificationDiv = document.getElementById("bellBoundary");
      if (NotificationDiv) {
        NotificationDiv.focus();
      }
    }, 500);
    UseGAEventTracker("App Header Category", "Notifications Selection", "Notifications Selected")
  }

  useEffect(() => {
    if (!socketData?.refresh && !socketData?.refreshAlertCount && !notificationsFormState.loading && !socketData?.isAlert && !socketData?.refreshWorkItem && !socketData?.refreshScheduleWorkItem) {
      if (socketData?.content?.isExportNotification
        && ((socketData?.organisationId?.toLowerCase() == selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id))
      ) {
        dispatch(fetchTransformedNotifications(getNotification))
        updateCount((prevCount) => prevCount + 1)
        AppService.showToast(t(jsonData.ExportCompletionMessage))
      } else {
        dispatch(fetchTransformedNotifications(getNotification))
        updateCount((prevCount) => prevCount + 1)
      }
    }
    if (socketData?.refresh) {
      //refresh device heath page when devices' status updates
      if (locationUrl.pathname === AppRoutes.DEVICE_HEALTH && !devicesHealthFormstate.loading) {
        if ((socketData?.organisationId?.toLowerCase() == selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id)) {
          dispatch(fetchDevicesHealthByOrgId({ ...deviceHealthParams, organizationId: getOrgId() }))
        }
      }
      //refresh user dashboard page when devices' status updates
      else if (locationUrl.pathname === AppRoutes.USER_DASHBOARD && !devicesHealthFormstate.loading) {
        if ((socketData?.organisationId?.toLowerCase() == selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id)) {
          dispatch(fetchDevicesHealthByOrgId({ organizationId: getOrgId() }))
        }
      }
      //refresh device components page when status of part changes 
      else if (locationUrl.pathname === AppRoutes.DEVICE_COMPONENTS && selectedDevice?.deviceId && !deviceComponentsFormstate.loading) {
        if ((socketData?.organisationId?.toLowerCase() == selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id)) {
          dispatch(fetchDeviceComponents({ ...deviceComponentParams, id: selectedDevice?.deviceId,cancelToken :cancelToken}))
        }
      }
    }
    //refresh alert count when alert has update
    if ((socketData?.refreshAlertCount ||socketData.isAlert) && !AlertCountformState.loading) {
      if ((socketData?.organisationId?.toLowerCase() == selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id)) {
        dispatch(fetchTransformedAlertCount({ organizationId: getOrgId() }))
      }
    }
    //refresh work items page for work item status refresh
    if (socketData?.refreshWorkItem && locationUrl.pathname === AppRoutes.WORK_ITEMS && !fetchWorkItemsFormState.loading) {
      if ((socketData?.organisationId?.toLowerCase() === selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id)) {
        dispatch(setWorkItemRefresh(true))
      }
    }
    //refresh schedule my workItems
    if (socketData?.refreshScheduleWorkItem && locationUrl.pathname === AppRoutes.MY_WORK_ITEMS && !myWorkItemListFormState.loading) {
      if ((socketData?.organisationId?.toLowerCase() === selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id)) {
        dispatch(fetchMyWorkItemList({organisationId: getOrgId(), userId: appUser?.id}))
      }
    }
    //refresh device health page for work item status refresh
    if (socketData?.refreshWorkItem && locationUrl.pathname === AppRoutes.DEVICE_HEALTH && !devicesHealthFormstate.loading) {
      if ((socketData?.organisationId?.toLowerCase() === selectedOrganisation?.id?.toLowerCase()) || (socketData?.organisationId?.toLowerCase() === appUser?.organization?.id)) {
        dispatch(fetchDevicesHealthByOrgId({ ...deviceHealthParams, organizationId: getOrgId() }))
      }
    }

  }, [socketData])

  socket.on('connect', () => {
    console.log('Socket on connect:: ', {
      connected: socket?.connected,
      socketID: socket?.id,
      userID: appUser?.id,
    })
  })

  socket?.on('message', (msg: any) => {
    try{
    if(socket === null){
      return
    }
    setSocketData(msg)
    if(msg?.content =="ssoLogout" && msg?.userId== appUser?.id){
      if (socket?.connected) {
        socket.disconnect()
        socket.close()
      }
      dispatch(ssoLogoutTrigger())
    }
    if (locationUrl.pathname !== AppRoutes.ALERT_CENTRE) {
      if ((msg?.eventData?.eventSrcType) || (msg?.eventData?.srcType) && msg?.isPopUpBanner) {
        openNotification(msg)
      }
    }
  }
  catch(e){
    console.log("Error occured in socket message",e)
  }
  })

  const gotoAlertCenter = (key, data) => {
    if (data.severity === graphSeries.WARNING || data.severity === graphSeries.ERROR) {
      history.push(urlModification(AppRoutes.ALERT_CENTRE))
      onNotificationDismiss(key, data)
    } else {
      return null
    }
  }

  const updateExportNotification = (notificationId) => {
      setExportNotifications((prev) =>
        prev.map((notif) =>
          notif.id === notificationId ? { ...notif, isDownloaded: true } : notif
        )
      );
    };

  const addExportNotification = (notification) => {
    setExportNotifications((prev) => [...prev, notification]);
  };
   

  const onNotificationDismiss = (key, data) => {
    notification.close(key)
    if (data.isAlert === false) {
      updateCount((prevCount) => prevCount - 1)
    }
    const notificationInput: any = {
      readNotificationIds: [data?.id !== undefined ? data?.id : 0],
    }
    if ((data?.userId !== undefined) && (data?.userId !== null)) {
      notificationInput.userId = data?.userId;
    }
    if(ackInputs?.organisationId && ackInputs?.organisationId !== ''){
      notificationInput.organisationId = ackInputs?.organisationId
    } else if (appUser?.organization?.id){
      notificationInput.organisationId = appUser?.organization?.id
    }
    else if ((data?.organisationId !== undefined) && (data?.organisationId !== null)) {
      notificationInput.organisationId = data?.organisationId;
    }
    if ((data?.locationId !== undefined) && (data?.locationId !== null)) {
      notificationInput.locationId = data?.locationId;
    }
    dispatch(postReadNotification(notificationInput))
  }
  socket.on('disconnect', () => {
    console.log('socket disconnected!')
  })

  socket.on('close', () => {
    console.log('Socket closed!!')
  })

  const openNotification = (data: any) => {
    const key = `open${Date.now()}`
    const btn = (
      <div>
        <Button
          className="notificatioButton"
          type="primary"
          size="small"
          onClick={() => onNotificationDismiss(key, data)}
        >
          Dismiss
        </Button>
      </div>
    )
    notification.open({
      message: (
        <div onClick={() => gotoAlertCenter(key, data)} >
          <span className="notificationTitleIcon">
            {data.severity === 'warning' ? (
              <img src={warningIcon} alt="icon" />
            ) : null}
            {data.severity === 'error' ? (
              <img src={ErrorIcon} alt="icon" />
            ) : null}
            {data.severity === 'info' ? '' : null}
          </span>
          <span className={`notificationTitleText ${data.severity}`}>
            {data?.content?.metadata?.name}
          </span>
          <span className="duration">Now</span>
          <span className="locationToaster">
            {data?.eventData?.hierarchyName}
          </span>
          {/* <span className="deviceName">{data?.eventData?.deviceName}</span> */}
        </div>
      ),
      duration: NOTIFICATION_TOAST_CLOSING_TIME,
      description: (
        <div className="notificationDescription">
          {data?.isAlert ? (
            <Tooltip placement="top" title={getFormattedString(
              data?.content?.description?.content,
              data?.content?.description?.params,
            )}>
              <Paragraph ellipsis className="describe">

                {getFormattedString(
                  data?.content?.description?.content,
                  data?.content?.description?.params,
                )}
              </Paragraph>
            </Tooltip>
          ) : (
            <Tooltip placement='top' title={data?.content?.description?.content}><Paragraph ellipsis className="describe">{data?.content?.description?.content}</Paragraph></Tooltip>
          )}
        </div>
      ),
      btn,
      key,
      placement: 'topRight',
      // onClose: close,
    })
  }

  const onLogout = () => {
    let keyUser = secureLocalStorage.getItem('sso_user')   
    console.log("keyUser--get-onlogout",keyUser)
    if (socket?.connected) {
      console.log('closing websocket....')
      socket.disconnect()
      socket.close()
    }
    // if(keyUser){
    //   console.log("keyUser--if",keyUser)
    //   dispatch(ssoLogout())
    // }
    // else {
    //   dispatch(logout())
    // }
    dispatch(ssoLogout())
  }

  const onAccountSettings = () => {
    if (getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL) {
      if (!isJuntoAccountSettings) {
        secureLocalStorage.setItem('path', window.location.pathname);
        history.push(urlModification(AppRoutes.ACCOUNT_SETTINGS_ACCOUNT));
        if (!isDirty) {
          dispatch(
            accountSettingsEntered({
              headerType: getAppHeaderType(appUser),
              path: secureLocalStorage.getItem('path'),
            })
          );
        }
        else {
          dispatch(warnUnsave({ calledBy: WarnCalledBy.ACCOUNT_SETTING_ICON, isWarnUnsaveOpen: true }));
        }
      }
    }
    else {
      if (!isBibliothecaAccountSettings) {
        secureLocalStorage.setItem('path', window.location.pathname);
        history.push(urlModification(AppRoutes.ACCOUNT_SETTINGS_ACCOUNT));
        if (!isDirty) {
          dispatch(
            accountSettingsEntered({
              headerType: getAppHeaderType(appUser),
              path: secureLocalStorage.getItem('path'),
            })
          );
        }
        else {
          dispatch(warnUnsave({ calledBy: WarnCalledBy.ACCOUNT_SETTING_ICON, isWarnUnsaveOpen: true }));
        }
      }
    }
  };

  useEffect(() => {
    if (isWarnUnsaveOpen && (calledBy === WarnCalledBy.ACCOUNT_SETTING_ICON)) {
      if (closeWarn) {
        dispatch(
          accountSettingsEntered({
            headerType: getAppHeaderType(appUser),
            path: secureLocalStorage.getItem('path'),
          })
        );
        dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }));
      }
    }
  });

  const onClick = (key: number, values: any) => {
    if (values[key] === 'Log Out') {
      onLogout()
    }

    if (values[key] === 'Account Settings') {
      onAccountSettings()
    }
  }

  useEffect(() => {
    renderHeader(getAppHeaderType(appUser))
  }, [])

  // useEffect(() => {
  //   if(selectedOrganisation.id === undefined && appUser?.organization.id !== undefined) {
  //     dispatch(getSelectedOrg(getOrgId()))
  //   }
  // },[selectedOrganisation])

  const renderHeader = (header: any) => {
    switch (header) {
      case HeaderType.BIBLIOTHECA_PORTAL:
        setLogo(Bibliotheca)
        showSetting(false)
        setLogoLink('bibliotheca')
        setWarnAlertVisible(false)
        setClassName('bibLogoSty')
        setShowPortalLink(true)
        break
      case HeaderType.JUNTO_PORTAL:
        setLogo(Bibliotheca)
        setLogoLink('junto')
        let role = [];
        if (appUser) role = getUserRoles(appUser);
        if (role.includes(UserRole.LIBRARY_FRONT_DESK)) {
          showSetting(false);
        }
        else {
          showSetting(true)
        }
        setClassName('biblioLogoSty')
        if (cameFromOrganisation()) {
          setFromBibliothecaPortal(true)
          setLogoLink('cameFromOrg')
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (
      window.location &&
      window.location.pathname.includes(AppRoutes.USER_DASHBOARD)
    ) {
      dispatch(settingsExit())
    }
    if (cameFromOrganisation()) {
      console.log("cameFromOrganisation");
      const oidValue = new URLSearchParams(window.location.search).get('oid')
      const orgId: string = oidValue ? oidValue : ''
      console.log("orgId", orgId, orgId !== '');
      orgId !== '' && dispatch(getSelectedOrg(orgId))
    }
  }, [])

  useEffect(() => {
    let handler = (event: any) => {
      if (menuDisplay) {
        if (!menuRef.current?.contains(event.target)) {
          setMenuDisplay(false)
        }
      } else if (iconRef.current?.contains(event.target)) {
        setMenuDisplay(true)
        UseGAEventTracker("App Header Category", "App Launcher Selection", "App Launcher Selected")
      }
    }
    let handleClickOutside = event => {
      if (bellRef.current && !bellRef.current.contains(event.target)) {
        if (modalShow) {
          setModalShow(false)
          setAckPopup(false)
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true)
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const onSettings = () => {
    if (!isSettingState) {
      secureLocalStorage.setItem('path', window.location.pathname);
      let role = [];
      if (appUser) role = getUserRoles(appUser);
      // if front Office user then redirect to locations page else users page
      if (role.includes(UserRole.LIBRARY_FRONT_DESK)) {
        history.push(urlModification(AppRoutes.ORGANISATION_LOCATIONS))
        // UseGAEventTracker("App Header Category", "Settings Selection", "Front Desk User Settings Selected")
      } else {
        history.push(urlModification(AppRoutes.GENERAL_SETTINGS))
        UseGAEventTracker("App Header Category", "Settings Selection", "General Settings Selected")
      }
      if (!isDirty) {
        dispatch(
          settingsEntered({
            path: secureLocalStorage.getItem('path'),
            isSettingState: true,
          })
        );
      }
      else {
        dispatch(warnUnsave({ calledBy: WarnCalledBy.SETTING_ICON, isWarnUnsaveOpen: true }));
      }
    }
  }

  useEffect(() => {
    if (isWarnUnsaveOpen && (calledBy === WarnCalledBy.SETTING_ICON)) {
      if (closeWarn) {
        dispatch(
          settingsEntered({
            path: secureLocalStorage.getItem('path'),
            isSettingState: true,
          })
        );
        dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }));
      }
    }
  });

  const getSettingImg = () => {
    return !isSettingState ? SettingIconImg : disableSettingIcon
  }

  const onClickbellicon = () => {
    setModalShow(false)
    setPostShow(!postShow)
  }

  useEffect(() => {
    const node = document.querySelector<HTMLElement>(
      '.ant-modal-root .modalBoxCover',
    )
    if (node) {
      node.addEventListener('click', () => {
        setPostShow(!postShow)
      })
    }
  })
  const alertIconClick = (key: string) => {
    dispatch(settingsExit())
    // const graphSeriesObj: any = {
    //   warnNotifications: key === graphSeries.WARNING ? true : false,
    //   errorNotifications: key === graphSeries.ERROR ? true : false,
    // }
    dispatch(setAlertSeverity(key))
    if(window.location.pathname !== AppRoutes.ALERT_CENTRE )
    {
      dispatch(setAlertClicked(true))
      history.push(urlModification(AppRoutes.ALERT_CENTRE))
    }else{
       dispatch(setAlertClicked(true))
  }
  dispatch(sidebarNavigate('Alerts Centre'))

  UseGAEventTracker("App Header Category", "Warning/Error Alert selection", `${key} Selected`)
}

  const getAckParam = (data: any) => {
    setAckInputs(data)
  }

  //bell notification
  useEffect(() => {
    if (ackPopup === false) {
      if (
        ackInputs?.readNotificationIds?.length !== 0 ||
        (undefined && ackInputs?.organisationId !== null) ||
        (undefined && ackInputs?.userId !== null) ||
        (undefined && ackInputs?.locationId !== null) ||
        undefined
      ) {
        setTimeout(() => {
          // locaton and orginastaion are optional
          if (notificationsArray?.result && notificationsArray?.result?.length >= 1) {
            let params:any = {readNotificationIds:ackInputs?.readNotificationIds}
            if(ackInputs?.organisationId && ackInputs?.organisationId !== ''){
              params.organisationId = ackInputs?.organisationId
            }
            if(ackInputs?.userId && ackInputs?.userId !== ''){
              params.userId = ackInputs?.userId
            }else{
              params.userId = appUser.id
            }
            if(ackInputs?.locationId && ackInputs?.locationId !== ''){
              params.locationId = ackInputs?.locationId
            }
            if (exportNotifications.length) {
              const notificationIdsToExclude = exportNotifications
                .filter((notif: any) => {
                  return ackInputs?.readNotificationIds.includes(notif?.id) && !notif?.isDownloaded;
                })
                .map((notif: any) => notif.id);
            
              if (notificationIdsToExclude.length) {
                params.readNotificationIds = params.readNotificationIds.filter(
                  (id: any) => !notificationIdsToExclude.includes(id)
                );
              }
            }
            if (params.readNotificationIds.length) {
              dispatch(postReadNotification(params))
            }
          }
        }, 100);
        setTimeout(() => {
          if (notificationsArray?.result && notificationsArray?.result?.length >= 1) {
            dispatch(fetchTransformedNotifications(getNotification));
          }
        }, 1500);
      }
      else {
        return null
      }
    }
  }, [ackPopup])


  // alert Count
  const finalWarnCount = warningCount
  const finalErrorCount = errorCount

  const onJuntoClick = () => {
    window.open(
      process.env.REACT_APP_LOGO_URL,
      '_blank'
    );
    // if (logoLink === 'junto') {
    //   history.push(AppRoutes.USER_DASHBOARD)
    // }
    // if (logoLink === 'cameFromOrg') {
    //   history.push(AppRoutes.USER_DASHBOARD + window.location.search)
    // }
    // else {
    //   return null
    // };
  }
  const tooltipWarningData = getFormattedNumber(finalWarnCount) +" "+ t(jsonData.warnings)
  const tooltipErrorData =  getFormattedNumber(finalErrorCount) +" "+ t(jsonData.errors)

  const token = window.localStorage.getItem('id-token')
  let LCRole: any
  if(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    var decodeToken = JSON.parse(jsonPayload);
    var redirectUrl = secureLocalStorage.getItem('redirectUrl')
    LCRole = decodeToken?.role
  }

  const handleLangChange = (value: string) => {
    console.log(`selected ${value}`);
    i18next.changeLanguage(value)
  };

  const onEsc = (event) => {
    if(event.keyCode == 13) {
      setMenuDisplay(!menuDisplay)
    }
    if(event.keyCode == 27) {
      setMenuDisplay(false)
    }
  }

  const handelAccountSelect =(e:any)=>{
    
    if(e.keyCode === 13){
      const dropdwon:any = document.querySelector('.accountSeetingDrop .ant-dropdown-trigger')
      if(dropdwon){
          dropdwon.click()
          setTimeout(()=>{
            const select = document.querySelector<HTMLElement>('.headProfileIconSelect')
            select.focus()
          },300)
      }
    }
  }

  return (  
    <>
    {!getOrgId() ? 
      <Redirect to={AppRoutes.ORGANISATION_LIST} /> :
    <Row className="headerContainer">
      <Col span={14}>
        <div className="logo">
          <span className="logoOne" >
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.appLauncher)}>
            <img
              src={UnionDot}
              id="serviceMenuIcon"
              alt="Union Icon"
              ref={iconRef}
              className="unionIcon"
              tabIndex={0}
              aria-label={t(jsonData.headerAppLauncher)} 
              role={"button"}
              onKeyDown={onEsc}
            />
            </Tooltip>
          </span>
          {menuDisplay ? (
            <span id="servicesMenu" ref={menuRef}>
              <ServicesMenu setDisplay={setMenuDisplay} showPortalLink={showPortalLink} redirectUrl = {redirectUrl} role={LCRole}/>
            </span>
          ) : null}
          <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.logoImg)}>
          <img tabIndex={0} onKeyPress={onJuntoClick} src={logo} alt="Logo" className={logoClassName} onClick={onJuntoClick} />
          </Tooltip>
          {isFromBiblothecaPortal ? (
            <>
              <span className="pl-29">
                <img src={seperatorIcon} height="32" alt='' />
              </span>
              <span className="headerLogo">
                <ProfileThumbnailPicture src={selectedOrganisation?.info?.logo} firstName={selectedOrganisation?.name?.split(' ')?.[0] || ''} lastName={selectedOrganisation?.name?.split(' ')?.[1] || ''} colors={AvatarColorList} round={true} size="35" />
              </span>
              <p className="headerLogoText">
                {selectedOrganisation?.shortName}
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </Col>
      <Col flex={'auto'}>
        <div>
          <Row className="right-header-icon headerRight logo">
            <Col>
                {/* <div className='alertIconCover'> */}
                  {isWarnAlertVisible ? (
                    <div className='alertIconInner'>
                      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={tooltipWarningData}>
                      <div  className='alertIconCover animateWarning'>
                      <span key="1">
                        {(finalWarnCount) > 0 ? (
                          <div  tabIndex={0} aria-label={`${t(jsonData.warningAlert)} ${finalWarnCount}`} role={"link"} className="alertCountwrap"
                            onKeyPress={() => alertIconClick(graphSeries.WARNING)}
                            onClick={() => alertIconClick(graphSeries.WARNING)}
                          >
                            <span>
                              <img
                                src={warningIcon}
                                alt="Header Alert"
                                className="headerAlert"
                              />
                            </span>
                            <span className="count">
                              {getFormattedNumber(finalWarnCount)}
                            </span>
                            <span className="point right">
                              <div className="warnPoint"></div>
                            </span>
                          </div>
                        ) : (
                          <img
                            src={warningZeroIcon}
                            alt="Header Alert"
                            className="headerAlert"
                          />
                        )}                        
                      </span>
                      </div>
                      </Tooltip>
                      <div className='warningIconCover'>
                      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={tooltipErrorData}>
                      <div  className='alertIconCover animateError'>
                        <span key="1">
                          {(finalErrorCount) > 0 ? (
                            <div className="alertCountwrap"
                              tabIndex={0}
                              aria-label={`${t(jsonData.errorAlert)} ${finalErrorCount}`} 
                              role={"link"}
                              onKeyPress={() => alertIconClick(graphSeries.ERROR)}
                              onClick={() => alertIconClick(graphSeries.ERROR)}
                            >
                              <span>
                                <img
                                  src={ErrorIcon}
                                  alt="Header Alert"
                                  className="headAlert"
                                />
                              </span>
                              <span className="count">
                                {getFormattedNumber(finalErrorCount)}
                              </span>
                              <span className="point right">
                                <div className="errorPoint"></div>
                              </span>
                            </div>
                          ) : (
                            <img
                              src={errorZeroIcon}
                              alt="Header Alert"
                              className="headAlert"
                            />
                          )}
                        </span>
                        </div>
                        </Tooltip>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                {/* </div> */}
            </Col>
            <Col className="seperatorCover">
              {isWarnAlertVisible ? (
                <div className="right">
                  <span>
                    <img src={seperatorIcon} height="32" alt="seperatorIcon" />
                  </span>
                </div>
              ) : (
                <div></div>
              )}
            </Col>
            {/* <Col>
                  <Select
                    defaultValue="English"
                    style={{ width: 120 }}
                    onChange={handleLangChange}
                    options={languages}
                  />
            </Col> */}

            <Col
              className={`mlBell ml-30 ${setting === true ? 'setIconOn' : 'setIconOff'
                }`}
            >
              <div tabIndex={0}
               aria-label={t(jsonData.notificationPressEnterToSeeTheNotifications)} 
               role={"button"}
                className={`mlBellInner ${modalShow === true ? 'bellbackround' : ''
                  }`}
                onClick={() => setPostShow(false)}
                onKeyPress={notificationBell}
              >
                <>
                  {notificationsArray &&
                    notificationsArray?.result?.length >= 1 ? (
                    <span className="pulse bellWrap" key="2">
                      <img
                        src={BellIconImg}
                        alt="Header bell"
                        className="bellIconFix rotate"
                        onClick={notificationBell}
                      />
                      <span className="bellcount">
                        {notificationsArray?.result?.length
                          ? notificationsArray?.result?.length + count
                          : null}
                      </span>
                    </span>
                  ) : (
                    <Tooltip overlayClassName='notiNotFound' placement="bottom" title={t(jsonData.NoNotifications)}>
                    <img
                      src={BellIconImg}
                      alt="Header bell"
                      className="bellIconFix"
                      onClick={notificationBell}
                    />  
                    </Tooltip>                  
                  )}
                </>
                <sub></sub>
                <Modal
                  wrapClassName="modalBoxCover"
                  className={`mlBell-modal ${setting === true ? 'modalBox' : 'modalBoxInner'
                    }`}
                  visible={modalShow}
                  closable={false}
                  footer={null}
                  onOk={() => onClickbellicon}
                  onCancel={() => setModalShow(false)}
                  maskClosable={true}
                >
                  <div ref={bellRef} className="bellContent">
                    <NotificationCards
                      onModelOpen={modalShow}
                      onPopupOpen={postShow ? 'PopupClosed' : 'PopupOpened'}
                      getNotificationData={notificationsArray}
                      getUserDetails={getNotification}
                      exportNotifications={exportNotifications}
                      onUpdateExportNotification={updateExportNotification}
                      onAddExportNotification={addExportNotification}
                      ackParameters={getAckParam}
                    />
                  </div>
                </Modal>
              </div>
            </Col>
            {setting ? (
              <Col className={`ml-30 ${isSettingState ? 'setIconoff' : 'setIcon'
                }`}>
                <div  tabIndex={!isSettingState ? 0 : -1} aria-label={t(jsonData.settingsPressEnterToGoToSettings)} role={"link"} onKeyPress={onSettings} className="right">
                  <Tooltip overlayClassName={`${isSettingState ? 'dashBoardTipoff' : 'dashBoardTooltip'}`}
                    placement="bottom" title={t(jsonData.Settings)}>
                    <span key="3">
                      <img
                        src={getSettingImg()}
                        alt="Header Setting"
                        onClick={onSettings}
                        className="settingIconFix"
                        
                        onKeyPress={onSettings}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Col>
            ) : (
              <></>
            )}
            <Col
              className={`ml-30 accountSeetingDrop ${setting === false ? 'headProfileIcon' : ' '
                }`}
                onKeyUp={handelAccountSelect}
                tabIndex={0}
                aria-label={t(jsonData.userAccountPressEnterToGoToAccountSettingsOrLogout)} 
                role={"button"}
                id="profileIconActive"
            >
              <Filter
                values={['Account Settings', 'Log Out']}
                onClick={onClick}
                className="headProfileIconSelect"
              >
                <Tooltip mouseLeaveDelay={0} overlayClassName='dashBoardTooltip' placement="bottom" title={ concatNames(appUser?.firstName,  appUser?.lastName)}>
                <ThumbNailWrapper>
                  <ProfileThumbnailPicture
                    src={appUser?.info?.logo || appUser.profileUrl}
                    colors={AvatarColorList}
                    firstName={appUser?.firstName}
                    lastName={appUser?.lastName}
                    size="20"
                  />
                </ThumbNailWrapper>
                </Tooltip>
              </Filter>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
}</>
  )


}
