/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dropdown } from '../../../../../bit_components/common/dropdown'
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer'
import {
  WorkHead,
  WorkDiv,
  DropDownWrapper,
  WorkHeaders,
} from './index.styles'
import { Col, Row, Tooltip } from 'antd'
import { cameFromOrganisation, getFormattedDate, translateOptions, urlModification } from '../../../../../utility/appUtil'
import { appSelector, goToPrevious } from '../../../../../redux/reducers/app/appReducers'
import './styles.less'
import { AlertDropDownConstants, DefaultTime, filterOptions, jsonData, TimePattern } from '../../../../constants/constants'
import CustomRange from '../../../stateless/common/customRange'
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage'
import {Button as CustomButton} from "../../../../../bit_components/common/button";
import { setWorkItemRefresh, WorkSelector } from "../../../../../redux/reducers/workItems/workReducer";
import CreateWorkItemModal from './workItemModals'
import { getUserRoles } from '../../../../../utility/appUtil';
import { UserRole } from '../../../../constants/enums'
import { AppRoutes } from '../../../../constants/enums'


const WorkHeader = (props: any) => {
  let localeInfo:any = secureLocalStorage.getItem('locale')
  moment.tz.setDefault(localeInfo.timezone)
  const datePattern = "D MMMM, YYYY " + TimePattern.HH_MM
  const dispatch = useDispatch()
    const history = useHistory();
  const { appUser } = useSelector(userStateSelector)
  const { selectedOrganisation, showCustomRange } = useSelector(appSelector)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(DefaultTime.START)
  const [endTime, setEndTime] = useState(DefaultTime.END)
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false)
  const [filterDuration, setFilterDuration] = useState<any | null>(AlertDropDownConstants.WEEK_VALUE)
  const [customValues, setCustomValues] = useState<boolean>(false)
  const { workItemRefresh, orgSupportedWorkItemList } = useSelector(WorkSelector)
  const [isWorkItemModalOpen, setIsWorkItemModalOpen] = useState<boolean>(false);
  const [createWorkItemOpen, setCreateWorkItemOpen] = useState<boolean>(false);


  const { t } = useTranslation()
  const roles = getUserRoles(appUser);
  
  useEffect(() => {
    return () => {
      dispatch(goToPrevious({showCustomRange: false}))
    }
  }, [])

  useEffect(() => {
    if (!isCustomDate && endDate && startDate) {
      let params = {
        organisationId: getOrgId(),
        startDate: filterDuration !== AlertDropDownConstants.SELECT_DURATION_VALUE ? startDate + ' ' + startTime : null ,
        endDate: filterDuration !== AlertDropDownConstants.SELECT_DURATION_VALUE ? endDate + ' ' + endTime : null,
        hierarchyIdList:[],
        deviceIdList:[],
        status:[],
        workItemName:[],
        userName:[]
      }
      props?.onParamsUpdated(params)
    }
  }, [startDate, endDate, startTime, endTime, isCustomDate])

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id
    }
    return orgId
  }

  /************* Handle Duration Filter ************/
  const onChangeDuration = (value: string) => {
    dispatch(goToPrevious({showCustomRange: false}))
    setFilterDuration(value)
  }

  useEffect(() => {
    if(!customValues && !showCustomRange){
      setIsCustomDate(false)
      filterDuration && updateDates(filterDuration)
    }
  }, [filterDuration])

  const updateDates = (value: any) => {
    let durationObj: any = {}
    if(value !== AlertDropDownConstants.CUSTOM_VALUE) {
      const selectedDate = moment();
      let newEndDate = selectedDate.format(datePattern);
      let newStartDate = selectedDate.subtract(1, value).format(datePattern);
      setDate(newStartDate, newEndDate)
    }
    else if (value === AlertDropDownConstants.CUSTOM_VALUE) {
      setIsCustomDate(true)
    }
  }

  const handleRefresh = () => {
    if(filterDuration !== AlertDropDownConstants.CUSTOM_VALUE) {
      const selectedDate = moment();
      let endDateValue = selectedDate.format(datePattern);
      let startDateValue = selectedDate.subtract(1, filterDuration).format(datePattern)
      
      let startDate = getFormattedDate(startDateValue,"YYYY-MM-DD")
      let endDate = getFormattedDate(endDateValue,"YYYY-MM-DD")
      let startTime = getFormattedDate(startDateValue,TimePattern.HH_MM)
      let endTime = getFormattedDate(endDateValue,TimePattern.HH_MM)
      
      let params = {
        startDate: `${startDate} ${startTime}`,
        endDate: `${endDate} ${endTime}`
      }
      props?.onParamsUpdated(params)
    }
    else if (filterDuration === AlertDropDownConstants.CUSTOM_VALUE) {
      props?.onParamsUpdated({})
    }
  }

  const handleCreate = () => {
    setIsWorkItemModalOpen(true)
    setCreateWorkItemOpen(true)
  }

  // set date
  const setDate = (startDateValue: string, endDateValue: string) => {
    let startDate = getFormattedDate(startDateValue,"YYYY-MM-DD")
    let endDate = getFormattedDate(endDateValue,"YYYY-MM-DD")
    let startTime = getFormattedDate(startDateValue,TimePattern.HH_MM)
    let endTime = getFormattedDate(endDateValue,TimePattern.HH_MM)
    
    setStartDate(startDate)
    setEndDate(endDate)
    setStartTime(startTime)
    setEndTime(endTime)
  }
    const goToMyWorkItems = () => {
      history.push(urlModification(AppRoutes.MY_WORK_ITEMS))
    }

  const onCustomRange = (values: any) => {
    setEndDate(values?.customEndDate)
    setStartDate(values?.customStartDate)
    setStartTime(values?.customStartTime)
    setEndTime(values?.customEndTime)
    setIsCustomDate(false)
  }
    useEffect(() => {
    if (workItemRefresh) {
      handleRefresh()
      dispatch(setWorkItemRefresh(false))
    }
  }, [workItemRefresh])

  return (
    <WorkHead>
      <Row>
        <Col>
          <WorkDiv>{t(jsonData.WorkItems.replace(/\s/g, ""))}</WorkDiv>
        </Col>
        <Col className="create-workitem-button">
          {
            <Tooltip
              overlayClassName="dashBoardTooltip"
              placement="bottom"
              title={t(jsonData.CreateWorkItem)}
            >
              <div>
                {roles && !roles.includes(UserRole.LIBRARY_FRONT_DESK) && (
                  <CustomButton
                    tabIndex={0}
                    type="actionButton"
                    onClick={handleCreate}
                    aria-label={""}
                    role={""}
                  >
                    {t(jsonData.CreateWorkItem)}
                  </CustomButton>
                )}
              </div>
            </Tooltip>
          }
        </Col>
        <Col className="myworkitem-button">
            {
              <Tooltip
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={t(jsonData.MyWorkItems)}
              >
                <div>
                {roles && !roles.includes(UserRole.LIBRARY_FRONT_DESK) && (
                  <CustomButton
                    tabIndex={0}
                    type="actionButton"
                    onClick={goToMyWorkItems}
                    aria-label={""}
                    role={""}
                  >
                    {t(jsonData.MyWorkItems)}
                  </CustomButton>
                )}
                </div>
              </Tooltip>
            }
          </Col>
        <WorkHeaders>
          <Row>
            <Col>
              <DropDownWrapper>
                <Dropdown
                  ariaLabel={t(jsonData.Day)}
                  defaultValue={AlertDropDownConstants.DAY_VALUE}
                  className="filterDropDown workPageDropdown"
                  onChange={onChangeDuration}
                  value={filterDuration}
                  bordered={false}
                  optionValue={translateOptions(
                    filterOptions.alertDateRange,
                    t,
                    "text"
                  )}
                  tabIndex={0}
                />
              </DropDownWrapper>
            </Col>
          </Row>
        </WorkHeaders>
      </Row>
      {filterDuration === AlertDropDownConstants.CUSTOM_VALUE ? (
        <CustomRange
          onChange={onCustomRange}
          displayHeading="From"
          customValues={customValues}
          setCustomValues={setCustomValues}
          start={startDate}
          end={endDate}
          from={startTime}
          to={endTime}
        />
      ) : (
        ""
      )}
      {createWorkItemOpen && (
        <CreateWorkItemModal
          orgSupportedWorkItemList={orgSupportedWorkItemList}
          isWorkItemModalOpen={isWorkItemModalOpen}
          setIsWorkItemModalOpen={setIsWorkItemModalOpen}
          setCreateWorkItemOpen={setCreateWorkItemOpen}
        />
      )}
    </WorkHead>
  );
}

export default WorkHeader;