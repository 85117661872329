import React, {useState} from 'react'
import { Card, Row, Col, Tooltip } from 'antd'
import { concatNames } from '../../../../../../utility/appUtil';
import User from '../../../../../models/user/user';
import { ProfileThumbnailPicture } from '../../../../stateless/user/profile/profileThumbnailPicture';
import { UserThreeDottedMenu } from '../../userThreeDottedMenu';
import './userRowItem.less';
import { ThreeDotsImg, ThreeDotsImgHover } from '../../../../../images';
import { Location } from '../../../../../models/common/location';
import { RowUserDetail, RowUserInfo, RowUserLocation, RowUserName, RowUserRole } from './index.styles';
import { UserRole } from '../../../../../constants/enums';

export const UserRowItem = (props: any): JSX.Element => {
  const { appUser, user, avatarColorList, blockUserCallback, resetPwdCallback, removeUserCallback, goToProfileCallback, unBlockUserCallback, resendInviteCallback,setSaveUnblockUser } = props
  const onGoToUserProfile = (user: User) => {
    if (goToProfileCallback) {
      goToProfileCallback(user);
    }
  }
  const [iconImg, setIconImg] = useState(ThreeDotsImg);

  const getLocationDetails = (user: User) => {
    // debugger;
    if (user && user.locations) {
      const locationCount = user.locations.length
      if (locationCount > 0) {
        if (locationCount === 1) {
          return user.locations[0].name
        } else {
          return user.locations.length + ' locations'
        }
      } else {
        return '0 locations'
      }
    }
    return ''
  }

  const getUserRoleDetails = (user: User) => {
    let roles: any[] = [];
    if (user.locations && user.locations.length) {
      user.locations.forEach((location: Location) => {
        if (!roles.includes(location.role?.name)) {
          roles.push(location.role?.name);
        }
      });
    }
    return roles.join(" ").trim() + " ";
  };

  return (
    <div>
      <Card className="cardItem" tabIndex={0} aria-label={""} role={""} onKeyPress={() => onGoToUserProfile(user)}>
        <Row>
          <Col span={24} onClick={() => onGoToUserProfile(user)} className="displayCenter rowInfo">
            <Row>
              <Col span={3} md={3} lg={2} className="userProfilePic">
              {user?.isActive !== true ? (
                user?.emailVerifiedAt !== "" ? (
                  <div>
                    <ProfileThumbnailPicture 
                     src={user?.info?.logo || user?.profileUrl} 
                     firstName={user?.firstName} 
                     lastName={user?.lastName} 
                     colors={avatarColorList} 
                     round={true} 
                     size='35' />
                     <div className="blockedUserListImg"></div>
                  </div>
                ) :
                (
                  <div>
                    <ProfileThumbnailPicture 
                     src={user?.info?.logo || user?.profileUrl} 
                     firstName={user?.firstName} 
                     lastName={user?.lastName} 
                     colors={avatarColorList} 
                     round={true} 
                     size='35' />
                    <div className="inviteUserRowImg"></div>
                  </div>
                )
              ) : (
                <ProfileThumbnailPicture 
                     src={user?.info?.logo || user?.profileUrl} 
                     firstName={user?.firstName} 
                     lastName={user?.lastName} 
                     colors={avatarColorList} 
                     round={true} 
                     size='35' />
              )
              }
                
              </Col>
              
              <Col span={5} md={4} lg={5} className="displayCenter">
                <Tooltip
                placement="bottomRight"
                title={concatNames(user?.firstName, user?.lastName, user?.middleName, user?.title)}>
                  <RowUserName className="userNameContentSlicing">{concatNames(user?.firstName, user?.lastName, user?.middleName, user?.title)}</RowUserName>
                </Tooltip>
              </Col>
              <Col span={6} md={5} lg={5} className="displayCenter">
                <div className="userListRoleContentSlicing">
                  <RowUserRole>{getUserRoleDetails(user)}</RowUserRole>
                  <RowUserLocation>in {getLocationDetails(user)}</RowUserLocation>
                </div>
              </Col>
              <Col md={4} lg={5} className='displayCenter'>
              <RowUserInfo className="userInfoContentSlicing">{user?.userName}</RowUserInfo>
              </Col>
              <Col span={6} md={4} lg={4} className="displayCenter">
                <RowUserDetail className="userDetailContentSlicing">{user?.email}</RowUserDetail>
              </Col>
              <Col span={4} md={4} lg={3} className="displayCenter">
                <RowUserInfo className="userInfoContentSlicing">{user?.phone}</RowUserInfo>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
