import { createAsyncThunk } from "@reduxjs/toolkit";
import WorkService from "../../../web/services/workItems/workService";

const workService = new WorkService();

const removeWorkItem = {
  description: "restart application"
}

export const fetchWorkItemsByOrgId = createAsyncThunk(
  "workItems/fetchWorkItemsByOrgId",
    async (_args: any, thunkAPI) => {
    try {
      const data = await workService.fetchWorkItemsList(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const storeWorkItem = createAsyncThunk(
  "device/storeWorkItem",
  async (_args:any, thunkAPI) => {
    try {
      const data = await workService.storeWorkItem(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const retryWorkItem = createAsyncThunk(
  "workItems/retryWorkItem",
    async (_args: any, thunkAPI) => {
    try {
      const data = await workService.retryWorkItem(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteWorkItem = createAsyncThunk(
  "workItems/deleteWorkItem",
    async (_args: any, thunkAPI) => {
    try {
      const data = await workService.deleteWorkItem(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const changeWorkItemStatus = createAsyncThunk(
  "workItems/changeWorkItemStatus",
    async (_args: any, thunkAPI) => {
    try {
      const data = await workService.changeWorkItemStatus(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchUserName = createAsyncThunk(
  "workItems/fetchUserName",
  async(_agrs: any, thunkAPI) => {
    try {
      const data = await workService.fetchUserNameList(_agrs);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch(e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchWorkItemName = createAsyncThunk(
  "workItems/fetchWorkItemName",
  async(_args: void, thunkAPI) => {
    try {
      const data = await workService.fetchWorkItemNameList();
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch(e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchOrgSupportedWorkItems = createAsyncThunk(
  "workItems/fetchOrgSupportedWorkItems",
  async(_args: any, thunkAPI) => {
    try {
      const data = await workService.fetchOrgSupportedWorkItemList(_args)
      if (data) {
        const filteredData = data.filter(item => item.description.trim().toLowerCase() !== removeWorkItem.description)
        return filteredData;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch(e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchWorkItemDevices = createAsyncThunk(
  "workItems/fetchWorkItemDevices",
  async(_args: any, thunkAPI) => {
    try {
      const data = await workService.fetchWorkItemDeviceList(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch(e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createWorkItemLcd = createAsyncThunk(
  "workItems/create",
  async(_args: any, thunkAPI) => {
    try {
      const data = await workService.createWorkItemLcd(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch(e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);