/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React ,{useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import { Row, Col } from 'antd'
import {
    StyledChartdiv,
    TooltipXDIv,
    TooltipYDiv,
    TooltipMainDiv,
    GraphHeading,
    InsightLineGraphRow,
    Legends,
    LegendsItem,
    ColorDot,
  } from '../insights.styles'
  import {  Dropdown, Button } from 'antd'
  import { DownOutlined , RightCircleFilled} from '@ant-design/icons'
  import { ResponsivePieCanvas } from '@nivo/pie'
import moment from 'moment-timezone'
import { insightsSelector } from '../../../../../redux/reducers/insights/insightsReducer'
import { colorCodes, formatScaling, getColorScheme } from '../../../../../utility/insightUtils'
import { PieGraphTooltipMainDiv, RightCircledStyle } from './index.styles'
import { getFormattedDate, getOrganizationDateAndTime } from '../../../../../utility/appUtil'
import { DurationDropDownConstants, FrequencyDropdown, GroupBy, jsonData } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next';

  const PieGraph = (props: any) => {
    const { t } = useTranslation();
    const { graphData, format, menu, groupBy, index, groupByValue, lineGraphData, startPage, endPage, colorKeys } = props;
    const { reportData, reportValues } = useSelector(insightsSelector);
    const [yMax, setYMax] = useState<number | 'auto'>('auto');
    const [yScale, setYScale] = useState<number[]>();
    const [screenWidth, setScreenWidth] = useState(document.body?.clientWidth);
  
    let chartData = graphData;
    const scheme = [...colorCodes];

    useEffect(() => {
      const node = document.body;
      const resizeObserver = new ResizeObserver((entries) => {
        setScreenWidth(node?.clientWidth);
      });
      resizeObserver.observe(node);
    }, []);
    
    const getColor=(data)=>{
      let sortingScreen = getColorScheme(colorKeys,scheme);
      if(sortingScreen){
        let color = sortingScreen[`${data.id}`] || 'red';
        return color;
      }
    } 

    return (
      <>
        <Col
          key={index}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
        >
          <StyledChartdiv id="sorting-pie-graph" screen={'pieGraph'} className="insightGraph">
            <InsightLineGraphRow>
              <Col>
              <GraphHeading>
                {(() => {
                  const lineGraphHeader = lineGraphData && lineGraphData[0]?.header?.split(':')[1] || "";
                  const heading = t(jsonData.NumberItemsSortedBins);
                  return (
                    <>
                      <span>{heading} </span>
                      <span className='dateHeader'> {lineGraphHeader}</span>
                    </>
                    );
                })()}
              </GraphHeading>
            </Col>
            </InsightLineGraphRow>
            {chartData.some(element => element?.value !== 0) ?
            <>
              <ResponsivePieCanvas
                data={chartData}
                //@ts-ignore
                height={250}
                margin={{ top: 10, right: 100, bottom: 50, left: 10 }}
                activeOuterRadiusOffset={8}
                colors={(data) => getColor(data)}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.6]],
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor="#333333"
                tooltip={(e: any) => {
                  let { datum: t } = e;
                  return (
                    <>
                      <PieGraphTooltipMainDiv>
                        <TooltipXDIv>{t.id}: {t.value}</TooltipXDIv>
                      </PieGraphTooltipMainDiv>
                    </>
                  );
                }}
                //@ts-ignore
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: 'ruby',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'c',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'go',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'python',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'scala',
                    },
                    id: 'lines',
                  },
                  {
                    match: {
                      id: 'lisp',
                    },
                    id: 'lines',
                  },
                  {
                    match: {
                      id: 'elixir',
                    },
                    id: 'lines',
                  },
                  {
                    match: {
                      id: 'javascript',
                    },
                    id: 'lines',
                  },
                ]}
                legends={[]}
              />
              <Legends className="scrollStyle">
                {chartData.map((item: any, index: number) => {
                  return (
                    <LegendsItem>
                      <ColorDot
                        style={{
                          backgroundColor: colorCodes[index],
                        }}
                      ></ColorDot>
                      <span style={{ paddingLeft: "8px" }}>{item.id}</span>
                    </LegendsItem>
                  );
                })}
              </Legends>
            </>
            :
            <p className="pieTextMessage">{t(jsonData.NoDataFound)}</p>
          }
          </StyledChartdiv>
        </Col>
      </>
    );
  };
  
  export default PieGraph;