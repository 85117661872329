import styled from 'styled-components'
import { Row ,Divider} from 'antd';

export const ReportScreen = styled.div`
  margin-left: 3.5%;
  // height: 100%;
`

export const AddNewReportDiv = styled.div`
  height: 35vh;
  background: #e5e5e5;
  border-radius: 10px;
  margin-left: 6.76%;
  margin-right: 2.8%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.016), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  &:hover {
    border: 1px solid #CBCBCB;
    box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.028), 0px 2.7px 3.3px rgba(0, 0, 0, 0.04), 0px 5px 6.3px rgba(0, 0, 0, 0.05), 0px 8.9px 11.2px rgba(0, 0, 0, 0.06), 0px 16.7px 20.9px rgba(0, 0, 0, 0.072), 0px 40px 50px rgba(0, 0, 0, 0.1);
  }
`

export const StyledChartdiv = styled.div<{screen: string}>`
  position: relative;
  height: ${(props) => props.screen === 'pieGraph' ? '80%' : '35vh'};
  min-height: ${(props) => props.screen === 'pieGraph' ? '150px !important' : ''};
  padding: 30px;
  padding-bottom:4rem;
  background: ${(props) => props.theme.colors.palette.white};
  box-shadow: 0px 54px 29px rgb(0 0 0 / 2%),
    0px 27.0264px 14.5142px rgb(0 0 0 / 2%),
    0px 16.2794px 8.74265px rgb(0 0 0 / 1%),
    0px 10.4327px 5.60275px rgb(0 0 0 / 1%),
    0px 6.76164px 3.63125px rgb(0 0 0 / 1%),
    0px 4.25637px 2.28583px rgb(0 0 0 / 1%),
    0px 2.44555px 1.31335px rgb(0 0 0 / 1%),
    0px 1.07635px 0.57804px rgb(0 0 0 / 0%);
  border-radius: 10px;
  margin-right: 3.37%;
  font-family: ${(props) => props.theme.fonts[0]};
  //overflow-y: auto;
  // display:flex;
  // flex-flow:column;
`

export const StyledReportsearchdiv = styled.div`
  // height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 6px;
  padding-left: 18px;
`

export const ReportTextdiv = styled.div`
  font-size: ${(props) => props.theme.fontSizes.h4};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.primary};
  line-height: 20px;
`

export const RecentReportdiv = styled.div`
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 20px;
  text-align: right;
`

export const TimeReportdiv = styled.div`
  //position: absolute;
  padding-right: 32px;
  padding-left: 20px;
  float: right;
  font-size: ${(props) => props.theme.fontSizes.footnote};
  line-height: 16px;
  color: ${(props) => props.theme.colors.text.quaternary};
`

export const DropdownIcon = styled.img`
  margin-left: 12.5px;
`

export const SearchIcondiv = styled.div`
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const SearchTextdiv = styled.div`
  font-size: ${(props) => props.theme.fontSizes.body};
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondary};
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  line-height: 20px;
  display: flex;
  align-items: center;
`
// check
export const Textdiv = styled.div`
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  line-height: 20px;
  padding-left: 18px;
  // border-bottom: 1px solid #EDEDED;
  // &:last-child {
  //     border-bottom: none;
  // }
`

export const Mbdiv = styled.div`
  margin-bottom: 25px;
`

export const ReportIcondiv = styled.div`
  margin-right: 19px;
  display: inline-block;
  padding-left: 18px;
`

export const Locationdiv = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.caption};
  color: ${(props) => props.theme.colors.text.quaternary};
  width: fit-content;
  &:hover {
    text-decoration-line: underline;
    // display: inline-block;
    cursor: pointer;
  }
`

export const Devicediv = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 20px;
  width: fit-content;
  &:hover {
    text-decoration-line: underline;
    // display: inline-block;
    cursor: pointer;
  }
`

export const WrapperDivForCards = styled.div`
  &:hover {
    border: 1px solid #ededed;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
      0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
      0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
      0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
      0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
      0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
      0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
      0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
  }
`

export const StatusDiv = styled.div`
  &:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
`

export const TooltipXDIv = styled.div`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.primary};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0px;
`

export const TooltipYDiv = styled.div`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h2};
  line-height: 26px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.primary};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`

export const TooltipMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  background: ${(props) => props.theme.colors.palette.white};
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  border-radius: 10px;
`
export const Legends = styled.div`
    max-height: 20vh;
    overflow-y: scroll;
    position: absolute;
    bottom: 20%;
    right: 10px;
    font-size: 10px;
    color: grey;
    background: white;
  
`
export const LegendsItem = styled.p`
    margin-bottom:5px;
    background: white;
    padding-right: 5px;
    display: flex;  
    align-items: center;
`
export const ColorDot = styled.span`
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
`
export const GraphHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h4};
  line-height: 20px;
  color: ${(props) => props.theme.colors.text.primary};
`
export const AddReportText = styled.p`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 20px;
    color: ${(props) => props.theme.colors.palette.darkestGray};
`

export const InsightDivider = styled(Divider)`
    border-color: #EDEDED;
    margin: 0px;
    min-width: 2px;
    width: 100%;
`;

export const InsightLineGraphRow = styled(Row)`
 justify-content: space-between;

`;