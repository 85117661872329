import { createSlice } from "@reduxjs/toolkit";
import {
    fulfilledState,
    rejectedState,
    pendingState,
    resetState,

} from "../../../utility/stateUtil";
import { RootState } from "../../store/rootReducer";
import { MyWorkState } from "../../states/workItems/myWorkState";
import { deleteMyWorkItem, fetchMyWorkItemById, fetchMyWorkItemList, storeMyWorkItem, updateMyWorkItem } from "../../actions/workItems/myWorkAction";

const initialState: MyWorkState = {
    myWorkItem: [],
    myWorkItemList: [],
    myWorkItemFormState: resetState(),
    myWorkItemListFormState: resetState(),
    saveMyWorkItemFormState: resetState(),
    updateMyWorkItemFormState: resetState(),
    deleteMyWorkItemFormState: resetState(),
    formState: resetState(),
};

export const MyWorkSlice = createSlice({
    name: "workItems",
    initialState: initialState,
    reducers: {
        clearMyWorkItem: (state) => {
            state.myWorkItem = []
            return state
        },
        clearMyWorkItemList: (state) => {
            state.myWorkItemList = []
            return state
        },

        clearMyWorkItemFormState: (state) => {
            state.myWorkItemFormState = resetState();
            return state;
        },
        clearMyWorkItemListFormState: (state) => {
            state.myWorkItemListFormState = resetState()
            return state
        },
        clearSaveMyWorkItemFormState: (state) => {
            state.saveMyWorkItemFormState = resetState()
            return state
        },
        clearUpdateMyWorkIteFormState: (state) => {
            state.updateMyWorkItemFormState = resetState()
            return state
        },
        clearDeleteMyWorkIteFormState: (state) => {
            state.deleteMyWorkItemFormState = resetState()
            return state
        }

    },
    extraReducers: {
        [fetchMyWorkItemById.fulfilled.toString()]: (state, { payload }) => {
            state.myWorkItem = payload;
            state.myWorkItemFormState = fulfilledState();
            return state;
        },
        [fetchMyWorkItemById.rejected.toString()]: (state, { payload }) => {
            state.myWorkItemFormState = rejectedState(payload?.message);
        },
        [fetchMyWorkItemById.pending.toString()]: (state) => {
            state.myWorkItemFormState = pendingState();
        },

        [fetchMyWorkItemList.fulfilled.toString()]: (state, { payload }) => {
            state.myWorkItemList = payload;
            state.myWorkItemListFormState = fulfilledState();
            return state;
        },
        [fetchMyWorkItemList.rejected.toString()]: (state, { payload }) => {
            state.myWorkItemListFormState = rejectedState(payload?.message);
        },
        [fetchMyWorkItemList.pending.toString()]: (state) => {
            state.myWorkItemListFormState = pendingState();
        },

        [storeMyWorkItem.fulfilled.toString()]: (state, { payload }) => {
            state.saveMyWorkItemFormState = fulfilledState();
            return state;
        },
        [storeMyWorkItem.rejected.toString()]: (state, { payload }) => {
            state.saveMyWorkItemFormState = rejectedState(payload?.message);
            return state;
        },
        [storeMyWorkItem.pending.toString()]: (state) => {
            state.saveMyWorkItemFormState = pendingState();
            return state;
        },

        [updateMyWorkItem.fulfilled.toString()]: (state, { payload }) => {
            state.updateMyWorkItemFormState = fulfilledState();
            return state;
        },
        [updateMyWorkItem.rejected.toString()]: (state, { payload }) => {
            state.updateMyWorkItemFormState = rejectedState(payload?.message);
            return state;
        },
        [updateMyWorkItem.pending.toString()]: (state) => {
            state.updateMyWorkItemFormState = pendingState();
            return state;
        },

        [deleteMyWorkItem.fulfilled.toString()]: (state, { payload }) => {
            state.deleteMyWorkItemFormState = fulfilledState();
            return state;
        },
        [deleteMyWorkItem.rejected.toString()]: (state, { payload }) => {
            state.deleteMyWorkItemFormState = rejectedState(payload?.message);
            return state;
        },
        [deleteMyWorkItem.pending.toString()]: (state) => {
            state.deleteMyWorkItemFormState = pendingState();
            return state;
        }

    }
});

export const { clearMyWorkItemFormState, clearMyWorkItemList, clearDeleteMyWorkIteFormState, clearSaveMyWorkItemFormState, clearUpdateMyWorkIteFormState} = MyWorkSlice.actions;
export const MyWorkSelector = (state: RootState) => state.myWorkItems;
export const MyWorkFormStateSelector = (state: RootState) => state.myWorkItems.formState;
export default MyWorkSlice.reducer;