import styled from 'styled-components'

export const Title = styled.span`
    padding-right: 15px;
`
export const SheduleModalDivSection = styled.div`
    margin-bottom: 40px;
    max-width: 272px;
    min-width: 232px;
    width:30vw
`;
export const Primarytext = styled.p`
    font-size: ${(props)=> props.theme.fontSizes.h4};
    color: ${(props) => props.theme.colors.text.primary};
    text-align: center;
    margin-top: 2%;
    font-weight: ${(props)=> props.theme.fontWeights.normal};
    line-height: 20px;
    margin-bottom: 30px;
    display: flex
    
`;
