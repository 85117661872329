/* eslint-disable @typescript-eslint/no-unused-vars */
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { WorkItemsUrl } from "../../constants/apiConstants";
import qs from 'qs'

export default class WorkService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.WORK_ITEMS));
  }

  async fetchMyWorkItemList(parameters: any = {}): Promise<any> {
    let endpoint = `${WorkItemsUrl.ScheduleWorkItems}`;
    let queryParams: any = {}

    if (parameters?.organisationId && parameters?.organisationId !== '') {
      queryParams.organisationId = parameters?.organisationId
    }

    if (parameters?.userId && parameters?.userId !== '') {
      queryParams.userId = parameters?.userId
    }
    if (parameters?.sortBy && parameters?.sortBy ! == '') {
      queryParams.sortBy = parameters?.sortBy
    }
    if (parameters?.sortOrder && parameters?.sortOrder ! == '') {
      queryParams.sortOrder = parameters?.sortOrder
    }

    const response: any = await this.get(endpoint, {
      params: queryParams,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    if (response && response.data && response.data.result) {
      return response.data.result.message;
    }
    return { totalWorkCount: 0, data: [] };
  }

  async fetchMyWorkItemById(parameters): Promise<any> {
    let endpoint = `${WorkItemsUrl.ScheduleWorkItems}/${parameters.id}`;
    const response: any = await this.get(endpoint)
    if (response?.data?.result)
      return response?.data?.result;
    else
      return null
  }



  async storeMyWorkItem(parameters: any): Promise<any> {
    let endpoint = `${WorkItemsUrl.ScheduleWorkItems}?organisationId=${parameters?.organisationId}&userId=${parameters?.userId}`;
    const response: any = await this.post(endpoint, parameters?.body)    
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async updateMyWorkItem(parameters: any): Promise<any> {
    let endpoint = `${WorkItemsUrl.ScheduleWorkItems}/${parameters?.id}`;
    const response: any = await this.put(endpoint,parameters?.body)
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async deleteMyWorkItem(parameter: any): Promise<any> {
    let endpoint = `${WorkItemsUrl.ScheduleWorkItems}/${parameter}`;
    const response: any = await this.delete(endpoint)
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

}
