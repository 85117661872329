/* eslint-disable array-callback-return */
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { Status_Type } from '../web/constants/constants';
import { BlueEllipse, GreenEllipse, GreyEllipse, PurpleEllipse, RedEllipse, YellowEllipse, Good,off,warningIcon,ErrorIcon,Uncertain } from '../web/images';
import moment from 'moment-timezone'
import secureLocalStorage from 'react-secure-storage';

export const getCurrentDate = async () => {
  const today = new Date();
  const date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
  return date;
}

export const getFormattedString = (content?: string, params?: any) => {
  const formattedString = content?.replace(
    /%(\w+)/g,
    function (a, b, c) {
      var r = params[b];
      return typeof r === "undefined" ? a : r;
    }
  );

  return formattedString;
}


//get sorted data by passing sort parameters and table data
export const getTableSortedData = async (sortValue?: any, filterData?: any) => {
  const keyValue = sortValue?.column?.key !== 'eventId' ? sortValue?.column?.key : 'eventIdSearch';
  const order = sortValue?.order;

  if (order === "ascend") {
    var assndData = filterData?.sort(function (a: any, b: any) {
      return a[keyValue]?.localeCompare(b[keyValue]);
    });
    return assndData;
  } else if (order === "descend") {
    var descendData = filterData?.sort(function (a: any, b: any) {
      return b[keyValue]?.localeCompare(a[keyValue]);
    });
    return descendData;
  } else if (keyValue === undefined || order === undefined) {
    var defaultData = filterData?.sort(function (a: any, b: any) {
      return b?.id?.localeCompare(a?.id);
    });
    return defaultData;
  }
};

//get sorted data by passing filter parameters and table data and default table data
export const getTableFilterData = async (
  filtersParam?: any,
  filterData?: any,
  defaultFilterData?: any
) => {
  //remove key having null for filter data accordingingly
  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }
  //filter data
  const newVal = clean(filtersParam);
  var result = filterData?.filter(function (o) {
    if (Object.keys(newVal)?.length && newVal !== undefined && newVal !== null) {
      return Object.keys(newVal).every(function (k) {
        return newVal[k]?.some(function (f) {
          return o[k]?.toString()?.toLowerCase() === f?.toString()?.toLowerCase();
        });
      });
    } else {

      return defaultFilterData;
    }
  });
  var data = Object.keys(newVal)?.length ? result : defaultFilterData;

  return data ?? []
};

//EXPORT CSV
export const getExportCsvFile = async (csvData?: any, columns?: any, titleDate?: any, csvFileName?: any) => { 
  let localeInfo:any = secureLocalStorage.getItem('locale')
  const workbook = new Excel.Workbook();
  const data = [...csvData]
  const workSheetName = 'Worksheet-1';
  const workBookName = csvFileName;
  const myInputId = 'myInput';

  try {
    const myInput = document.getElementById(myInputId);
    //@ts-ignore
    const fileName = myInput?.value || workBookName;

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName, { properties: { tabColor: { argb: 'FF00FF00' } } });

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;
    worksheet.duplicateRow(1, 2, true);
    worksheet.getRow(2).values = []
    worksheet.getRow(1).values = []
    //table styling

    /*TITLE*/
    worksheet.mergeCells('A1', 'E1');
    worksheet.mergeCells('A2', 'E2');
    worksheet.getCell('A1').value = titleDate
    worksheet.getCell('A2').value = 'Applied Filters'

    // worksheet.getRow(2).values = ['Start Date', 'End Date', 'Search', 'Type Filters', 'Location Filters', 'Category Filters', 'Event Filters'];
    //title color
    worksheet.autoFilter = 'A1:D1';
    [
      'A1',
      'B1',
      'C1',
      'D1',
      'E1',
        ].map(key => {
        worksheet.getCell(key).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '808080' },
          bgColor: { argb: '808080' }
        };
      });

    //heading color
    worksheet.autoFilter = 'A3:E3';
    [ 
      'A3',
      'B3',
      'C3',
      'D3',
      'E3',
    ].map(key => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
        bgColor: { argb: 'D3D3D3' }
      };
    });

    // //heading color
    // worksheet.autoFilter = 'A4:C4';
    // ['B4',
    // 'C4'
    // ].map(key => {
    //   worksheet.getCell(key).fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: '96C8FB' },
    //     bgColor: { argb: '96C8FB' }
    //   };
    // });


    // updated the font for first row.z
    worksheet.getRow(3).font = { bold: true };
    worksheet.getRow(2).font = { bold: true };
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns?.forEach(column => {
      column.width = column?.header?.length + 5;
      column.alignment = { horizontal: 'center' };
    });

    // loop through data and add each one to worksheet
    data.forEach(singleData => {
      let newSingleData = {...singleData , dateTimeStamp : singleData.key !== undefined ? moment.utc(singleData.dateTimeStamp).format(localeInfo.exportDateFormat) : null}
      worksheet.addRow(newSingleData);
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, row => {
      //@ts-ignore
      // store each cell to currentCell
      const currentCell = row?._cells;
      
      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach(singleCell => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error('<<<ERRROR>>>', error);
    console.error('Something Went Wrong', error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }

}

export const sortList = sortBy => (a, b) => {
  if (a[sortBy]?.toString().toLowerCase() > b[sortBy]?.toString().toLowerCase()) {
    return 1;
  } else if (a[sortBy]?.toString().toLowerCase() < b[sortBy]?.toString().toLowerCase()) {
    return -1;
  }
  return 0;
}

export const getStatusTypeEllipse = (type : string) => {
  
  switch(type?.toString().toLowerCase()) {
    case Status_Type.WARNING :
      return YellowEllipse

    case Status_Type.INFO :
      return BlueEllipse

    case Status_Type.ERROR:
      return RedEllipse

    case Status_Type.GOOD :
      return GreenEllipse

    case Status_Type.UNCERTAIN :
      return PurpleEllipse

    case Status_Type.UNMONITORED :
      return GreyEllipse

    default :
      return ''
  }

}

export const getStatusTypeEllipseDashboard = (type : string,t:any) => {
  
  switch(type?.toString()) {
    case (t(Status_Type.WARNING)) :
      return warningIcon

    case (t(Status_Type.INFO)) :
      return BlueEllipse

    case (t(Status_Type.ERROR)):
      return ErrorIcon

    case (t(Status_Type.GOOD)) :
      return Good

    case (t(Status_Type.UNCERTAIN)) :
      return Uncertain

    case (t(Status_Type.UNMONITORED)) :
      return off

    default :
      return ''
  }

}


export const onlyUnique = (value: any, index: any, self: any) => {
  let length = Array.isArray(value) ? value?.length : value?.toString().length
  return (self.indexOf(value) === index && value !== null && value !== undefined && length)
}

export const prepareFilterList = (list: any) => {
  const textValueList = list?.map((x: any) => {
    return {
      text: x,
      value: x,
    }
  })
  const sortedBylabel = textValueList.sort(sortList('text'));
  return sortedBylabel
}


export const prepareFilterListLocation = (list: any) => {
  const textValueList = list?.map((x: any) => {
    return {
      text: x.location,
      value: x.hierarchyId,
    }
  })
  const sortedBylabel = textValueList.sort(sortList('text'));
  return sortedBylabel
}

export const prepareFilterDeviceType = (list: any) => {
  const textValueList = list?.map((x: any) => {
    return {
      text: x.deviceType,
      value: x.deviceTypeId,
    }
  })
  const sortedBylabel = textValueList.sort(sortList('text'));
  return sortedBylabel
}

/**
 * Executes the provided action when the 'Enter' key is pressed.
 *@param {any} event - The event object, usually representing a keyboard event.
 *@param {Function} action - The action to be executed on 'Enter' key press.
 */
 export const handleEnterKeyPress = (event: any, action: Function) => {
  if (event.code === "Enter") {
    action();
  }
};

/**
 * Function to add data into cache of browser
 * @param cacheName - Name of the cache 
 * @param url - the key for the data
 * @param response - the data to be stored for that key
 */
export const addDataIntoCache = (cacheName : string, url: string, response: any) => {
  const data = new Response(JSON.stringify(response));
  if ("caches" in window) {
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
    });
  }
};

/**
 * Function to get data from cache of browser
 * @param cacheName - name of the cache 
 * @param url - key for which we want to get the data
 * @returns 
 */
export const getDataFromCache = async (cacheName : string, url: string) => {
  if ("caches" in window) {
    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(url);
    if (cachedResponse) {
      const data = await cachedResponse.json();
      return data;
    } else {
      return undefined;
    }
  }
};

/**
 * Function to clear cache 
 * @param cacheName name of the cache to be cleared
 */
export const clearCache = async (cacheName : string) => {
  if ("caches" in window) {
    await caches.delete(cacheName);
  }
};

/**
 * Creates a deep copy of the input object.
 * @param obj The object to be deeply copied.
 * @returns A new object that is a deep copy of the input object.
 */
export const deepCopy = (obj: any) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const copy = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (key in obj) {
      copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
};

/**
 * Removes empty objects and arrays recursively from the input object.
 * @param inputObj The input object to process.
 * @returns A new object with empty objects and arrays removed.
 */
export const removeEmptyValues = (inputObj: any) => {
  const isNullOrEmpty = (obj) =>
    obj == null || (Object(obj) === obj && Object.keys(obj).length == 0);

  const clearNullEmpties = (obj : any) =>
    Object(obj) === obj
      ? Object.fromEntries(
          Object.entries(obj).flatMap(([k, v]) => {
            const val = clearNullEmpties(v);
            return isNullOrEmpty(val) ? [] : [[k, val]];
          })
        )
      : obj;

  return clearNullEmpties(inputObj);
};

/**
 * Recursively sorts the keys of an object alphabetically while preserving the original structure.
 * 
 * @param object - The object whose keys are to be sorted.
 * @returns A new object with the keys sorted alphabetically, while preserving the original structure.
 */
export const comparable = (object: any) =>
        typeof object !== "object" || !object
          ? object
          : Object.keys(object)
              .sort((a, b) => a.localeCompare(b))
              .reduce((c, key) => ((c[key] = comparable(object[key])), c), {});

/**
 * Compares two comma-separated string sets for equality regardless of order.
 * 
 * @param str1 - The first comma-separated string set to compare.
 * @param str2 - The second comma-separated string set to compare.
 * @returns True if the two string sets are equal, regardless of the order of elements; otherwise, false.
 */
export const areUnorderedStringSetsEqual = (str1: string, str2: string): boolean => {
  const arr1 = str1?.split(",").sort();
  const arr2 = str2?.split(",").sort();
  
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

/**
 * Checks whether a given value is an object.
 * @param {*} object - The value to be checked.
 * @returns {boolean} - `true` if the value is an object (excluding `null`), `false` otherwise.
 */
export const isObject = (object) => {
  return object != null && typeof object === "object";
};

/**
 * Compares two objects deeply, checking if their properties and nested properties are equal.
 * 
 * @param {Object} object1 - The first object to be compared.
 * @param {Object} object2 - The second object to be compared.
 * @returns {boolean} - `true` if the two objects are deeply equal, `false` otherwise.
 */
export const isDeepEqual = (object1, object2) => {
  if (object1 === null || object2 === null || typeof object1 !== 'object' || typeof object2 !== 'object') {
    return object1 === object2; 
  }

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

/**
 * Removes the specified keys from an object recursively, or from all objects within an array.
 * @param {Object|Array} object - The object or array from which to remove the keys.
 * @param {string|string[]} keysToRemove - The key(s) to remove from the object(s).
 * @returns {Object|Array} - The object or array with the specified keys removed.
 */
export const removeKeys = (object, keysToRemove) => {
  if (object === null || typeof object !== 'object') {
    return object;
  }

  if (!Array.isArray(keysToRemove)) {
    keysToRemove = [keysToRemove];
  }

  if (Array.isArray(object)) {
    return object.map(item => removeKeys(item, keysToRemove));
  }

  const result = {};

  for (let key in object) {
    if (keysToRemove.includes(key)) {
      continue;
    }
    result[key] = removeKeys(object[key], keysToRemove);
  }

  return result;
}

