import { useEffect, useRef, useState } from "react";
import "./styles.less";
import { Checkbox, Col, Divider, Row, Tabs, Tooltip } from "antd";
import Loader from "../../../../stateless/common/spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  DeviceSelector,
  clearNodeContent,
  removeCopiedGroups,
  removeCopiedItems,
  setChangedGroups,
  setCopiedGroups,
  setCopiedItems,
  setNewlyAddedGroup,
  setNodeStack,
  setRemovedGroups,
  setReorderedGroups,
  removeChangedGroups,
  removeReorderedGroups,
  setStoredSettings,
  removeChangedItems,
  setNewlyAddedGroupIds,
  removeNewlyAddedGroupIds,
} from "../../../../../../redux/reducers/device/deviceReducer";
import { fetchNodeContent } from "../../../../../../redux/actions/device/deviceAction";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { EmptyDevice } from "../index.styles";
import DeviceProperties from "./DeviceProperties";
import {
  HomeOutlined,
  LockFilled,
  RightCircleOutlined,
  ToolFilled,
  WarningFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import {
  addDataIntoCache,
  deepCopy,
  handleEnterKeyPress,
  isDeepEqual,
  removeEmptyValues,
  removeKeys,
} from "../../../../../../utility/utils";
import { appSelector } from "../../../../../../redux/reducers/app/appReducers";
import { jsonData } from "../../../../../constants/constants";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../bit_components/common/button";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getElapsedTime } from "../../../../../../utility/appUtil";

interface CopyDataInterface {
  FullNodeId: string;
  Data: string | number;
  Description: string;
  isMasked: boolean
}
const DeviceConfigBody = (props: any) => {
  const { t } = useTranslation();
  const {
    setSelectedView,
    index,
    setIndex,
    selectedSetting,
    setSelectedSetting,
    loading,
    setLoading,
    options,
    setOptions,
    loadingContent,
    setLoadingContent,
    isForbiddenStatus,
    getNodeContent,
    originalOptions,
    setOriginalOptions,
    referenceNodeContent,
    currentGroupId,
  } = props;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  const referenceElement = useRef(null);

  const { appUser } = useSelector(userStateSelector);
  const {
    deviceConfigViews,
    deviceConfigNodes,
    deviceConfigNodesFormState,
    deviceConfigNodeContent,
    deviceConfigNodeContentFormState,
    nodeStack,
    storedSettings,
    changedItems,
    copiedItems,
    isLocked,
    configState,
    copiedGroups,
    changedGroups,
    removedGroups,
    newlyAddedGroups,
    newlyAddedGroupIds,
  } = useSelector(DeviceSelector);
  const { selectedDevice } = useSelector(appSelector);

  const [configNodeStack, setConfigNodeStack] = useState([]);
  const [nodeContent, setNodeContent] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [selectAll, setSelectAll] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [path, setPath] = useState([]);
  const [visible, setVisible] = useState(null);
  const [isAddGroupDisabled, setIsAddGroupDisabled] = useState(false);

  const isGroupEditDisabled =
    (copiedItems && Object.keys(copiedItems)?.length > 0) ||
    (copiedGroups && Object.keys(copiedGroups)?.length > 0) ||
    isLocked ||
    isForbiddenStatus ||
    configState?.HasPermissionToEditConfiguration === false;

  const isZeroGroup = (group: any) => {
    return group?.NodeIds[0]?.includes(".0.");
  };

  const hasOnlyZeroGroup = (options: any) => {
    return options?.length === 1 && isZeroGroup(options[0]);
  };

  const handleSelect = (event: any, selectedOption: string) => {
    event?.stopPropagation();
    referenceElement.current = null;
    referenceNodeContent.current = null;
    setNodeContent([]);
    setLoadingContent(false);
    setSelectedSetting(null);
    dispatch(setNodeStack({ action: "push", data: selectedOption }));
    setPath([...nodeStack, selectedOption]);
    const selectedObject = options.find(
      (option: any) => option?.Name === selectedOption
    );

    if (selectedObject) {
      const { FolderNodes } = selectedObject;

      if (FolderNodes.length > 0) {
        setConfigNodeStack((prevConfigNodeStack) => [
          ...prevConfigNodeStack,
          FolderNodes,
        ]);
        setOptions(FolderNodes);
        setOriginalOptions(FolderNodes);
      }
    }
  };

  const openSettings = (selectedOption: any) => {
    if (
      selectedOption?.FolderNodes?.length > 0 &&
      selectedOption?.NodeIds?.length === 0
    ) {
      return handleSelect(undefined, selectedOption?.Name);
    }
    setPath([...nodeStack, selectedOption?.Name]);
    setSelectedSetting(selectedOption);
    setSelectedMenuItem(selectedOption?.FullNodeId);
  };

  const changeTab = (activeKey: string) => {
    const view = deviceConfigViews[Number(activeKey) - 1];
    let viewName = view?.Name;
    referenceElement.current = null;
    referenceNodeContent.current = null;
    setSelectedView(view);
    setLoading(true);
    setOptions(null);
    setSelectAll(null);
    setNodeContent([]);
    setLoadingContent(false);
    dispatch(setNodeStack({ action: "initialize", data: viewName }));
    setSelectedSetting(null);
    dispatch(clearNodeContent());
    setConfigNodeStack([]);
    setIndex(activeKey);
  };

  const goToLevel = (level: number) => {
    if (level !== nodeStack?.length - 1) {
      setOriginalOptions(configNodeStack[level]);
      referenceElement.current = null;
      referenceNodeContent.current = null;
      setNodeContent([]);
      setLoadingContent(false);
      setSelectedSetting(null);
      setOptions(configNodeStack[level]);
      setConfigNodeStack((prev) => prev.slice(0, level + 1));
      dispatch(setNodeStack({ data: nodeStack.slice(0, level + 1) }));
    }
  };

  const showRedDotForTab = (tabName: string) => {
    return storedSettings?.hasOwnProperty(tabName);
  };

  const showRedDot = (optionName: string) => {
    let currentPath = [...nodeStack, optionName];

    let currentNode = { ...storedSettings };
    let ans = true;
    for (let element of currentPath) {
      if (currentNode.hasOwnProperty(element)) {
        currentNode = { ...currentNode[element] };
      } else {
        ans = false;
        break;
      }
    }
    return ans;
  };

  const handleCheckboxChange = (
    checked: boolean,
    key: any,
    value: CopyDataInterface
  ) => {
    if (checked) {
      dispatch(setCopiedItems({ [key]: value }));
    } else {
      dispatch(removeCopiedItems(key));
    }
  };

  const onSelect = (event: any, copyData: CopyDataInterface) => {
    if (event.key === "Enter") {
      return event.target.click();
    }
    handleCheckboxChange(event.target.checked, event.target.value, copyData);
    if (!event.target.checked) {
      setCheckedList(
        checkedList?.filter(
          (x: any) => x?.toString() !== event.target.value?.toString()
        )
      );
    } else {
      setCheckedList([
        ...checkedList,
        nodeContent
          ?.filter(
            (x: any) =>
              x.FullNodeId?.toString() === event.target.value?.toString()
          )
          .map((item) => {
            return item.FullNodeId;
          }),
      ]);
    }
  };

  const handleGroupCheckboxChange = (checked: boolean, key: string) => {
    if (checked) {
      dispatch(setCopiedGroups({ [key]: key }));
    } else {
      dispatch(removeCopiedGroups(key));
    }
  };

  const onGroupSelect = (event: any) => {
    if (!(options?.length > 0 && options[0]?.IsGroup)) {
      return;
    }
    if (event.key === "Enter") {
      return event.target.click();
    }
    if (currentGroupId.current) {
      handleGroupCheckboxChange(event.target.checked, currentGroupId.current);
    }
    return true;
  };

  const handleSelectAll = () => {
    if (selectAll !== null) {
      setSelectAll((prev) => !prev);
      if (!selectAll === true) {
        setCheckedList(nodeContent.map((x: any) => x?.FullNodeId));
      } else {
        setCheckedList([]);
      }
    } else {
      setSelectAll(true);
      setCheckedList(nodeContent.map((x: any) => x?.FullNodeId));
    }
  };

  const modifySetting = (
    state: any,
    currentPath: string[],
    changedSetting: any
  ) => {
    const newState = deepCopy(state);
    let currentNode = newState;

    for (let key of currentPath) {
      if (!currentNode[key] || typeof currentNode[key] !== "object") {
        currentNode[key] = {};
      }
      currentNode = currentNode[key];
    }

    Object.assign(currentNode, changedSetting);

    return newState;
  };

  const handleReorderPayload = (newOptions: any) => {
    let filteredOptions = newOptions?.filter((item: any) => !isZeroGroup(item));

    let nodes = filteredOptions?.map((item: any) => item?.NodeIds);
    let reorder = {
      Id: currentGroupId.current,
      Order: nodes,
    };

    dispatch(
      setReorderedGroups({
        [currentGroupId.current]: reorder,
      })
    );
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (destination.index == source.index) return;

    if (!currentGroupId.current) return;

    let newOptions = [...options];
    let sourceElement = newOptions?.splice(source?.index, 1)[0];
    newOptions.splice(destination?.index, 0, sourceElement);

    let isEqual: boolean = isDeepEqual(newOptions, originalOptions);

    if (isEqual) {
      let response = removeKeys(storedSettings, currentGroupId.current);
      let result = removeEmptyValues(response);
      dispatch(setStoredSettings({ ...result }));
      dispatch(removeChangedGroups(currentGroupId.current));
      dispatch(removeReorderedGroups(currentGroupId.current));
      return;
    }

    handleReorderPayload(newOptions);
    dispatch(
      setChangedGroups({
        [currentGroupId.current]: newOptions,
      })
    );
  };

  const handleRemoveGroup = (event: any, group: any) => {
    event.stopPropagation();

    let groupId = group.GroupId;
    if (selectedSetting.FullNodeId === group.FullNodeId) {
      setNodeContent([]);
    }
    dispatch(removeNewlyAddedGroupIds(group.FullNodeId));
    //remove simple node content changes
    let newStoredSettings: any = deepCopy(storedSettings);
    group?.NodeIds?.map((item: any) => {
      if (changedItems?.hasOwnProperty(item)) {
        dispatch(removeChangedItems(item));
        let response = removeKeys(newStoredSettings, item);
        newStoredSettings = removeEmptyValues(response);
      }
    });

    const isExistingGroup = originalOptions?.some(
      (group: any) => group.GroupId === groupId
    );
    const isAlreadyPresent = removedGroups.includes(groupId);

    if (isExistingGroup && !isAlreadyPresent) {
      let newGroup = [...removedGroups, groupId];
      dispatch(setRemovedGroups(newGroup));
    }

    if (!isExistingGroup) {
      const newGroupsCopy = deepCopy(newlyAddedGroups);
      const filteredGroups = newGroupsCopy.filter(
        (group: any) => !group?.FullNodeId?.includes(groupId)
      );
      dispatch(setNewlyAddedGroup(filteredGroups));
    }

    const filteredGroup = options.filter((option: any) => {
      return option.GroupId !== groupId;
    });
    setOptions(filteredGroup);

    let isEqual: boolean = isDeepEqual(filteredGroup, originalOptions);

    if (isEqual) {
      let response = removeKeys(newStoredSettings, [
        ...group.NodeIds,
        currentGroupId.current,
      ]);
      let result = removeEmptyValues(response);
      dispatch(setStoredSettings({ ...result }));
      dispatch(removeChangedGroups(currentGroupId.current));
      dispatch(removeReorderedGroups(currentGroupId.current));
      return;
    }

    dispatch(setStoredSettings(newStoredSettings));
    handleReorderPayload(filteredGroup);
    dispatch(setChangedGroups({ [currentGroupId.current]: filteredGroup }));
  };

  const addGroup = () => {
    const createSmallest = (string: any, groupNumber: number) =>
      string.replace(/(\d+)/, groupNumber);

    const getSmallestMissingGroup = () => {
      const numSet = new Set();

      for (const option of options) {
        const match = option.Name.match(/\d+/);
        if (match) {
          numSet.add(Number(match[0]));
        }
      }

      let smallestMissing = 1;
      while (numSet.has(smallestMissing)) {
        smallestMissing++;
      }

      return smallestMissing;
    };

    const prepareNewGroupsPayload = (referenceGroup: any) => {
      return referenceGroup?.map((item: any, index: number) => ({
        FullNodeId: newNodeIds[index],
        Type: item.Type,
        Data: item.Data,
        DeviceXmlVersions: {},
        Secured: item.Secured,
      }));
    };

    const smallestGroupNumber: number = getSmallestMissingGroup();

    let newName = createSmallest(
      referenceElement.current?.Name,
      smallestGroupNumber
    );
    let newFullNodeId = createSmallest(
      referenceElement.current?.FullNodeId,
      smallestGroupNumber
    );
    let newGroupId = createSmallest(
      referenceElement.current?.GroupId,
      smallestGroupNumber
    );
    let newNodeIds = referenceElement.current?.NodeIds.map((item: string) => {
      return createSmallest(item, smallestGroupNumber);
    });

    const newGroupElement = {
      ...referenceElement.current,
      FullNodeId: newFullNodeId,
      Name: newName,
      NodeIds: newNodeIds,
      GroupId: newGroupId,
      DeviceXmlVersions: {},
    };

    const isExistingGroup = originalOptions.some(
      (group: any) => group.GroupId === newGroupElement.GroupId
    );

    if (!isExistingGroup && referenceNodeContent.current) {
      const payload = [
        ...newlyAddedGroups,
        ...prepareNewGroupsPayload(referenceNodeContent.current),
      ];
      dispatch(setNewlyAddedGroup(payload));
      dispatch(setNewlyAddedGroupIds(newFullNodeId));
    } else {
      let newGroup = removedGroups.filter(
        (groupName: string) => groupName !== newGroupElement.GroupId
      );
      dispatch(setRemovedGroups(newGroup));
    }

    let newGroupOptions = [...options, newGroupElement];
    let isEqual: boolean = isDeepEqual(newGroupOptions, originalOptions);

    if (isEqual) {
      let response = removeKeys(storedSettings, currentGroupId.current);
      let result = removeEmptyValues(response);
      dispatch(setStoredSettings({ ...result }));
      dispatch(removeChangedGroups(currentGroupId.current));
      dispatch(removeReorderedGroups(currentGroupId.current));
      return;
    }

    handleReorderPayload(newGroupOptions);
    dispatch(setChangedGroups({ [currentGroupId.current]: newGroupOptions }));
  };

  const insertStringValue = (inputString: string, sub: string) => {
    let result = inputString?.replace(/\{0\}/g, sub);
    return result;
  };

  useEffect(() => {
    referenceElement.current = null;
    referenceNodeContent.current = null;
    dispatch(clearNodeContent());
    setNodeContent([]);
    setLoadingContent(false);
    dispatch(
      setNodeStack({ action: "initialize", data: deviceConfigViews[0]?.Name })
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const headerHeight = getHeight(".device-config-header") + 30;
      const alertBannerHeight = getHeight(".config-locked-message") + 14;
      const totalHeight = headerHeight + alertBannerHeight + 36 + 16;

      const elements = document.querySelectorAll(".config-property-container");
      if (elements) {
        elements.forEach((element: HTMLElement) => {
          element.style.height = `calc(80vh - ${totalHeight}px)`;
        });
      }
    };

    const getHeight = (selector: string) => {
      const element: HTMLElement | null = document.querySelector(selector);
      return element ? element.offsetHeight : 0;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [nodeContent]);

  useEffect(() => {
    const handleDocumentClick = (event: any) => {
      if (visible !== null) {
        const element = document.querySelector(".ant-popover-placement-right");
        if (element && !element.contains(event.target)) {
          setVisible(null);
        }
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [visible]);

  useEffect(() => {
    if (selectedSetting) {
      getNodeContent();
      setLoading(false);
      setSelectAll(null);
    }
  }, [selectedSetting]);

  useEffect(() => {
    if (deviceConfigViews) {
      addDataIntoCache("configViews", "deviceConfigViews", deviceConfigViews);
      setSelectedView(deviceConfigViews[Number(index) - 1]);
    }
  }, [deviceConfigViews]);

  useEffect(() => {
    if (
      deviceConfigViews[Number(index) - 1]?.Name ===
      deviceConfigNodes?.View?.Name
    ) {
      setOriginalOptions(deviceConfigNodes?.View?.FolderNodes);
      referenceElement.current = null;
      referenceNodeContent.current = null;
      setNodeContent([]);
      setLoadingContent(false);
      setSelectedMenuItem("");
      addDataIntoCache(
        "nodeMenus",
        deviceConfigNodes?.View?.Name,
        deviceConfigNodes
      );
      setLoading(false);
      setConfigNodeStack([deviceConfigNodes?.View?.FolderNodes]);
      setOptions(deviceConfigNodes?.View?.FolderNodes);
      dispatch(
        setNodeStack({
          action: "initialize",
          data: deviceConfigViews[Number(index) - 1]?.Name,
        })
      );
      setSelectedView(deviceConfigViews[Number(index) - 1]);
    }
  }, [deviceConfigNodes]);

  useEffect(() => {
    if (
      selectedSetting?.NodeIds?.includes(deviceConfigNodeContent[0]?.FullNodeId)
    ) {
      setLoadingContent(false);
      setNodeContent(deviceConfigNodeContent);
    }
  }, [deviceConfigNodeContent]);

  useEffect(() => {
    if (
      deviceConfigNodeContentFormState.loading &&
      !hasOnlyZeroGroup(options)
    ) {
      setLoadingContent(true);
    } else if (
      deviceConfigNodeContentFormState.isError ||
      deviceConfigNodeContentFormState.isSuccess
    ) {
      setLoadingContent(false);
    }
  }, [deviceConfigNodeContentFormState.loading]);

  useEffect(() => {
    if (nodeContent?.length && checkedList?.length === nodeContent?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(null);
    }
  }, [checkedList]);

  useEffect(() => {
    setCheckedList(
      nodeContent.filter((nodeItem) =>
        copiedItems?.hasOwnProperty(nodeItem?.FullNodeId)
      )
    );
  }, [nodeContent, copiedItems]);

  useEffect(() => {
    if (options === 0 || hasOnlyZeroGroup(options)) {
      setNodeContent([]);
    }
    if (options && options[0]?.NodeIds?.length > 0) {
      if (options[0]?.GroupId) {
        let group = options[0]?.GroupId;
        let isZeroGroup = false;
        if (
          options?.length === 2 &&
          group.slice(group.lastIndexOf(".") + 1, group.length) === "0"
        ) {
          isZeroGroup = true;
        }
        group = group.slice(0, group.lastIndexOf(".") + 1);
        currentGroupId.current = group;
        if (changedGroups.hasOwnProperty(currentGroupId.current)) {
          let result = modifySetting(
            storedSettings,
            path.slice(0, isZeroGroup ? path.length : path.length - 1),
            { [currentGroupId.current]: changedGroups[currentGroupId.current] }
          );
          dispatch(setStoredSettings({ ...result }));
        }
      }

      if (options[0]?.NodeIds[0]?.includes(".0.")) {
        options[1] && openSettings(options[1]);
      } else {
        openSettings(options[0]);
      }
    }
    if (options && options.length > 0 && options[0].IsGroup) {
      if (changedGroups.hasOwnProperty(currentGroupId.current)) {
        setOptions(changedGroups[currentGroupId.current]);
      } else {
        setOptions(configNodeStack[configNodeStack.length - 1]);
      }
    }
  }, [options]);

  useEffect(() => {
    if (changedGroups.hasOwnProperty(currentGroupId.current)) {
      setOptions(changedGroups[currentGroupId.current]);
    } else {
      setOptions(configNodeStack[configNodeStack.length - 1]);
    }
  }, [changedGroups]);

  useEffect(() => {
    if (
      deviceConfigNodeContent &&
      deviceConfigNodeContent[0]?.FullNodeId?.includes(
        referenceElement.current?.GroupId
      )
    ) {
      referenceNodeContent.current = deviceConfigNodeContent;
    }
  }, [deviceConfigNodeContent]);

  useEffect(() => {
    if (originalOptions?.length > 0 && originalOptions[0]?.IsGroup) {
      let firstElement = originalOptions[0];
      let lastElement = originalOptions[originalOptions.length - 1];

      if (isZeroGroup(firstElement)) {
        referenceElement.current = firstElement;
      } else {
        referenceElement.current = lastElement;
      }

      if (referenceElement.current) {
        dispatch(
          fetchNodeContent({
            userName: appUser?.userName,
            deviceId: selectedDevice?.lcDeviceId,
            viewName: deviceConfigViews[Number(index) - 1]?.Name,
            Id: deviceConfigViews[Number(index) - 1].Id,
            nodeIds: referenceElement.current.NodeIds.join(";"),
          })
        );
      }
    }
  }, [originalOptions]);

  useEffect(() => {
    if (
      isGroupEditDisabled ||
      (options?.length && options?.length >= options[0]?.Max) ||
      !referenceNodeContent.current
    ) {
      setIsAddGroupDisabled(true);
    } else {
      setIsAddGroupDisabled(false);
    }
  }, [
    isGroupEditDisabled,
    options,
    referenceNodeContent.current,
    deviceConfigNodeContent,
  ]);

  useEffect(() => {
    if (
      referenceNodeContent.current &&
      newlyAddedGroupIds?.hasOwnProperty(selectedSetting?.FullNodeId) &&
      nodeContent?.length === 0
    ) {
      getNodeContent();
    }
  }, [referenceNodeContent.current, selectedSetting, newlyAddedGroupIds]);

  const AlertBanner = () => {
    return (
      <>
        {configState?.LockState?.Locked && (
          <div className="config-locked-message">
            <span className="config-locked-icon">
              <LockFilled />
            </span>
            <span>
              {`${t(jsonData.DeviceLocked)} '${
                configState?.LockState?.LockedByUsername ??
                t(jsonData.AnotherUser)
              }'`}
            </span>
          </div>
        )}

        {configState?.ProcessingWorkItem && (
          <div className="config-locked-message">
            <span className="config-locked-icon">
              <WarningFilled />
            </span>
            <span>
              {insertStringValue(
                t(jsonData.ProcessingWorkItem),
                getElapsedTime(
                  configState?.latestdWorkItemTime ??
                    configState?.LastUpdatedTime,
                  { text: "long" }
                )
              )}
            </span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="device-config-body">
      <AlertBanner />
      <Tabs
        className="orgTabContainer config-views"
        activeKey={index}
        onChange={changeTab}
      >
        {deviceConfigViews?.map((view: any, index: number) => {
          return (
            <TabPane
              tab={
                <>
                  {showRedDotForTab(view.Name) && (
                    <div className="red-dot-for-tab" />
                  )}
                  {view.DisplayName}
                </>
              }
              key={`${index + 1}`}
              className="genBasicInfo"
            >
              <Loader loading={deviceConfigNodesFormState?.loading || loading}>
                {deviceConfigNodesFormState?.isError ? (
                  <EmptyDevice description="Error Occurred" />
                ) : (
                  <Row className="mt-20">
                    <Col sm={9} md={8} lg={6}>
                      <div className="mb-10">
                        <span key={index} className="config-bread-crumbs">
                          <span className="bread-crumb-item">
                            {nodeStack?.length > 0 && (
                              <>
                                <Tooltip
                                  mouseLeaveDelay={0}
                                  overlayClassName="dashBoardTooltip"
                                  placement="bottom"
                                  title={t(jsonData.Home)}
                                >
                                  <HomeOutlined
                                    tabIndex={0}
                                    className="home-icon"
                                    onClick={() => goToLevel(0)}
                                    onKeyDown={(e) =>
                                      handleEnterKeyPress(e, () => goToLevel(0))
                                    }
                                  />
                                </Tooltip>
                                <span>&gt; </span>
                              </>
                            )}
                            {nodeStack?.length > 3 && <span> ... &gt; </span>}
                          </span>
                          {nodeStack?.map((node, index) => {
                            return (
                              <>
                                {index > 0 && index >= nodeStack?.length - 2 && (
                                  <span
                                    tabIndex={0}
                                    role="button"
                                    className="bread-crumb-item"
                                    onClick={() => goToLevel(index)}
                                    onKeyDown={(e) =>
                                      handleEnterKeyPress(e, () =>
                                        goToLevel(index)
                                      )
                                    }
                                  >
                                    <Tooltip
                                      mouseLeaveDelay={0}
                                      overlayClassName="dashBoardTooltip"
                                      placement="bottom"
                                      title={node}
                                    >
                                      {node}
                                    </Tooltip>
                                    <span>
                                      {nodeStack?.length > index + 1 && " > "}
                                    </span>
                                  </span>
                                )}
                              </>
                            );
                          })}
                        </span>
                      </div>
                      {options?.length > 0 && options[0]?.IsGroup && (
                        <div className="mb-10 groups-header">
                          <Tooltip
                            mouseLeaveDelay={0}
                            overlayClassName="dashBoardTooltip"
                            placement="bottom"
                            title={t(jsonData.Select)}
                          >
                            <Checkbox
                              disabled={
                                (changedItems &&
                                  Object.keys(changedItems)?.length > 0) ||
                                (changedGroups &&
                                  Object.keys(changedGroups)?.length > 0) ||
                                hasOnlyZeroGroup(options) ||
                                isLocked
                              }
                              checked={copiedGroups?.hasOwnProperty(
                                currentGroupId.current
                              )}
                              className="title-checkbox"
                              value={"all"}
                              onChange={(e) => onGroupSelect(e)}
                              onKeyDown={(e) =>
                                handleEnterKeyPress(e, () => onGroupSelect(e))
                              }
                            />
                          </Tooltip>
                          <Tooltip
                            mouseLeaveDelay={0}
                            overlayClassName="dashBoardTooltip"
                            placement="bottom"
                            title={"Add"}
                          >
                            <Button
                              tabIndex={0}
                              type="actionButton add-group"
                              disabled={isAddGroupDisabled}
                              onClick={addGroup}
                            >
                              <span className="pr-5">{`+ ${t(
                                jsonData.Add
                              )}`}</span>
                              {!referenceNodeContent.current && (
                                <div className="loaderButton">
                                  <span className="spinner"></span>
                                </div>
                              )}
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                      <div className="config-menu-container scrollContent scrollStyle">
                        <DragDropContext onDragEnd={onDragEnd}>
                          {options !== null && (
                            <Droppable droppableId="droppable">
                              {(provided) => (
                                <ul
                                  className="slider-menu-list"
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {originalOptions?.length > 0 ? (
                                    options?.map(
                                      (option: any, index: number) => {
                                        return (
                                          <>
                                            {!option?.NodeIds[0]?.includes(
                                              ".0."
                                            ) && (
                                              <Draggable
                                                isDragDisabled={
                                                  !option?.IsGroup ||
                                                  isGroupEditDisabled
                                                }
                                                key={option?.FullNodeId.toString()}
                                                draggableId={option?.FullNodeId.toString()}
                                                index={index}
                                              >
                                                {(provided) => (
                                                  <li
                                                    key={option?.Name}
                                                    className={`slider-menu-items ${
                                                      selectedMenuItem ==
                                                      option?.FullNodeId
                                                        ? "selectedSidebarWhite"
                                                        : ""
                                                    }`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <div className="folder-items">
                                                      <div
                                                        className={`changed-settings ${
                                                          showRedDot(
                                                            option?.Name
                                                          )
                                                            ? "red-dot"
                                                            : ""
                                                        }`}
                                                      />

                                                      <div
                                                        className="node-item-container"
                                                        onClick={() =>
                                                          openSettings(option)
                                                        }
                                                        onKeyDown={(e) =>
                                                          handleEnterKeyPress(
                                                            e,
                                                            () =>
                                                              openSettings(
                                                                option
                                                              )
                                                          )
                                                        }
                                                      >
                                                        <div
                                                          tabIndex={0}
                                                          className="option-name"
                                                        >
                                                          <Tooltip
                                                            mouseLeaveDelay={0}
                                                            overlayClassName="dashBoardTooltip"
                                                            placement="bottom"
                                                            title={option?.Name}
                                                          >
                                                            {option?.Name}
                                                          </Tooltip>
                                                        </div>

                                                        <div className="config-icons">
                                                          {options?.length >
                                                            0 &&
                                                            options[0]
                                                              ?.IsGroup && (
                                                              <Tooltip
                                                                mouseLeaveDelay={
                                                                  0
                                                                }
                                                                overlayClassName="dashBoardTooltip"
                                                                placement="bottom"
                                                                title={t(
                                                                  jsonData.Delete
                                                                )}
                                                              >
                                                                {options.length <=
                                                                  option.Min ||
                                                                isGroupEditDisabled ? (
                                                                  <CloseCircleFilled
                                                                    tabIndex={0}
                                                                    className="disabled-delete-icon"
                                                                  />
                                                                ) : (
                                                                  <CloseCircleFilled
                                                                    tabIndex={0}
                                                                    className="tool-icon"
                                                                    onClick={(
                                                                      event: any
                                                                    ) =>
                                                                      handleRemoveGroup(
                                                                        event,
                                                                        option
                                                                      )
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) =>
                                                                      handleEnterKeyPress(
                                                                        e,
                                                                        () =>
                                                                          handleRemoveGroup(
                                                                            e,
                                                                            option
                                                                          )
                                                                      )
                                                                    }
                                                                  />
                                                                )}
                                                              </Tooltip>
                                                            )}

                                                          {option?.NodeIds
                                                            ?.length > 0 && (
                                                            <div>
                                                              <Tooltip
                                                                mouseLeaveDelay={
                                                                  0
                                                                }
                                                                overlayClassName="dashBoardTooltip"
                                                                placement="bottom"
                                                                title={t(
                                                                  jsonData.Settings
                                                                )}
                                                              >
                                                                <ToolFilled
                                                                  tabIndex={0}
                                                                  className="tool-icon"
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}

                                                          {option?.FolderNodes
                                                            ?.length > 0 && (
                                                            <div>
                                                              <Tooltip
                                                                mouseLeaveDelay={
                                                                  0
                                                                }
                                                                overlayClassName="dashBoardTooltip"
                                                                placement="bottom"
                                                                title={t(
                                                                  jsonData.Menu
                                                                )}
                                                              >
                                                                <RightCircleOutlined
                                                                  tabIndex={0}
                                                                  className="slider-icon"
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    handleSelect(
                                                                      e,
                                                                      option?.Name
                                                                    )
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    handleEnterKeyPress(
                                                                      e,
                                                                      () =>
                                                                        handleSelect(
                                                                          e,
                                                                          option?.Name
                                                                        )
                                                                    )
                                                                  }
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                )}
                                              </Draggable>
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="config-no-data">
                                      <span>{t(jsonData.NoDataFound)}</span>
                                    </div>
                                  )}
                                  {provided.placeholder}
                                </ul>
                              )}
                            </Droppable>
                          )}
                        </DragDropContext>
                      </div>
                    </Col>

                    <Col span={1} className="config-divider">
                      <Divider
                        type="vertical"
                        className="config-body-divider"
                      />
                    </Col>

                    <Col sm={14} md={15} lg={17}>
                      {options?.length > 0 && (
                        <>
                          <Loader loading={loadingContent} />
                          {!loadingContent && (
                            <>
                              {deviceConfigNodeContentFormState?.isError ? (
                                <EmptyDevice description="Error Occurred" />
                              ) : (
                                nodeContent?.length > 0 && (
                                  <div className="config-property-container scrollContent scrollStyle">
                                    <h2 className="config-property-title">
                                      {!selectedSetting?.IsGroup && (
                                        <Tooltip
                                          mouseLeaveDelay={0}
                                          overlayClassName="dashBoardTooltip"
                                          placement="bottom"
                                          title={t(jsonData.SelectAll)}
                                        >
                                          <Checkbox
                                            disabled={
                                              (changedItems &&
                                                Object.keys(changedItems)
                                                  ?.length > 0) ||
                                              (changedGroups &&
                                                Object.keys(changedGroups)
                                                  ?.length > 0) ||
                                              isLocked
                                            }
                                            className="title-checkbox"
                                            value={"all"}
                                            onChange={handleSelectAll}
                                            onKeyDown={(e) =>
                                              handleEnterKeyPress(
                                                e,
                                                handleSelectAll
                                              )
                                            }
                                            checked={selectAll}
                                          />
                                        </Tooltip>
                                      )}
                                      {selectedSetting?.Name}
                                    </h2>
                                    {nodeContent?.map((nodeItem: any) => {
                                      return (
                                        <>
                                          <DeviceProperties
                                            key={nodeItem?.FullNodeId}
                                            nodeItem={nodeItem}
                                            nodeStack={nodeStack}
                                            selectedSetting={selectedSetting}
                                            selectAll={selectAll}
                                            onSelect={onSelect}
                                            checkedList={checkedList}
                                            path={path}
                                            modifySetting={modifySetting}
                                            isForbiddenStatus={
                                              isForbiddenStatus
                                            }
                                          />
                                          <Divider className="config-property-divider" />
                                        </>
                                      );
                                    })}
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                )}
              </Loader>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default DeviceConfigBody;
