import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Switch } from "antd";
import { Custominput } from "../../../../../bit_components/common/custominput";
import { Button } from "../../../../../bit_components/common/button";
import { AlertSelector } from '../../../../../redux/reducers/alert/alertReducer';
import { useTranslation } from 'react-i18next';
import { jsonData } from '../../../../constants/constants';

export const AlertClearForm = (props) => {
    const { t } = useTranslation()
    const { onFinish, rowData, disable, form, clearAlertsOfSameType, setClearAlertsOfSameType } = props
    const {
        alertClearFormState
    } = useSelector(AlertSelector);
    const [clearAlertId, setClearAlertId] = useState(0)

    const handleSubmit = (data: any) => {
        setClearAlertId(rowData?.id)
        onFinish(rowData, data)
        if (clearAlertsOfSameType) {
            setClearAlertsOfSameType(false)
        }

    }

    const handleToggle = (e) => {
        setClearAlertsOfSameType(e)
    }

    return (
        <Form form={form} onFinish={handleSubmit}>
            <Custominput
                labelSubName={t(jsonData.ClearAlertReason)}
                labelName="reason"
                name="reason"
                onChange={() => { }}
                customLabelClass={`alertCentre-clearInput`}
                rules={[
                    {
                        required: true,
                        message: t(jsonData.PleaseMentionAReason)
                    },
                    {
                        max: 200,
                        message:t(jsonData.CharacterLimitExceedMsg)
                    }
                ]}
                disabled={disable}
                autoComplete="off"
            />
            <div className="clear-alert-toggle">
              {t(jsonData.ClearAlertsOfSameType)}:{
                <Switch
                  className={`${clearAlertsOfSameType ? "scheduleToggleCheck" : "scheduleToggleOff"} ScheduleToggleSwitch`}
                  checked={clearAlertsOfSameType}
                  onChange={ (e) => handleToggle(e)}
                />
              }
            </div>
            {alertClearFormState?.loading && clearAlertId === rowData?.id ?
                <Button disabled type="smallBlack alertCentre-clearBtn">
                    <div className="loaderButton"><span className="spinner"></span></div>{t(jsonData.Clearing)}
                </Button>
                :
                <Button type="smallBlack alertCentre-clearBtn" key="submit" htmlType="submit"
                    disabled={disable}
                >
                    {t(jsonData.ClearAlert)}
                </Button>
            }
        </Form>
    )
}